import * as ACTION from './actionTypes';
import Axios from "axios"

/* start BroadcastersList */

const getAuthActionDetailsRequest = () => {
    return {
        type: ACTION.GET_AUTHACTION_DETAILS_REQUEST
    }
}

const getAuthActionDetailsSuccess = (userToken) => {
    return {
        type: ACTION.GET_AUTHACTION_DETAILS_SUCCESS,
        payload: userToken,
    }
}

const getAuthActionDetailsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_AUTHACTION_DETAILS_FAILURE,
        payload: errorMsg,
    }
}

export const resetFailureMessage = () => {
    return {
        type: ACTION.RESET_FAILURE_MESSAGE
    }
}

export const getAuthActions = (userData) => {
    const apiPath = `${process.env.REACT_APP_BASE_URL}/auth`;
    return function(dispatch) {
        Axios.get(`${process.env.REACT_APP_BASE_URL}/domain-allowed/list`)
          .then((response) => {
            const validDomainList = []
            for (let i=0; i< response?.data?.data?.length; i++){
              validDomainList.push(response?.data?.data[i]?.domainAllowed)
            }
            const domain = userData?.username.split("@")[1]
            if(validDomainList?.includes(domain)){
                dispatch(getAuthActionDetailsRequest());
                    Axios.post(apiPath, userData).then(res => {
                    localStorage.setItem("jwt_token", res?.data.jwt);
                    const headers = { Authorization: `Bearer ${res?.data.jwt}` };
                    Axios.get(
                        `${process.env.REACT_APP_BASE_URL}/accedo-detail`, {headers} )
                        .then((response) => {
                            localStorage.setItem("accedo_url", response.data.accedoApiUrl);
                            localStorage.setItem("accedo_Token", response.data.token);
                        // alert(response.accedoApiUrl);
                        })
                        .catch((error) => {
                            console.log(error);
                        }).then(dispatch(getAuthActionDetailsSuccess(res)))
                    
                })
                .catch(err => {
                    dispatch(getAuthActionDetailsFailure(err?.response?.data?.errorMessage));
                    // console.log(err)
                });
            }
            else{
                dispatch(getAuthActionDetailsFailure("User Domain doesn't exist.Please contact with Administrator."))
            }
          })
          .catch((error) => {
            dispatch(getAuthActionDetailsFailure("User Domain doesn't exist.Please contact with Administrator."))
            console.log(error)
          });
    };
};
