import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';
import moment from 'moment';

const initialState = {
    loading: false,
    calendarData: {
        fromDate: null,
        toDate: null,
        frequency:'WEEKLY'
    },
    lastUpdated:moment().format("DD MMM YYYY, h:mm:ss A"),
    errorMsg: '',
    resetSidCdsnFlag:false
}

const CalenderReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.GET_CALENDAR_DATE_SUCCESS: return getCalendarDateSuccess(state, action);
        case ACTION.GET_CALENDAR_DATE_RESET: return getCalendarDateReset(state, action);
        default: return state;
    }
}

const getCalendarDateSuccess = (state, action) => {
    return UpdateObject(state, {
        lastUpdated:moment().format("DD MMM YYYY, h:mm:ss A"),
        loading: false,
        calendarData: action.payload,
    });
}

export const getCalendarDateReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        calendarData: {
            fromDate: null,
            toDate: null,
            frequency:'WEEKLY'
        },
        lastUpdated:moment().format("DD MMM YYYY, h:mm:ss A"),
        resetSidCdsnFlag:!state.resetSidCdsnFlag,
        errorMsg: '',
    });
}

export default CalenderReducer;