import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const NetworkAvailabiltyGraphCdsnReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_NETWORK_AVAILABILTY_GRAPH_CDSN_REQUEST: return getNetworkAvailabiltyGraphCdsnRequest(state, action);
        case ACTION.GET_NETWORK_AVAILABILTY_GRAPH_CDSN_SUCCESS: return getNetworkAvailabiltyGraphCdsnSuccess(state, action);
        case ACTION.GET_NETWORK_AVAILABILTY_GRAPH_CDSN_FAILURE: return getNetworkAvailabiltyGraphCdsnFailure(state, action);
        
        default: return state;
    }
}

const getNetworkAvailabiltyGraphCdsnRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getNetworkAvailabiltyGraphCdsnSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getNetworkAvailabiltyGraphCdsnFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default NetworkAvailabiltyGraphCdsnReducer;