import React, { Fragment } from "react";
// Layout
 
import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";
import { useHistory } from "react-router-dom";
 
const PageNotFound = () => {
  const history = useHistory();

  const onBackNavigationHandler = ()=>{
  history.goBack();
  }
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
          <div className='page-not-found'>
              <img src='/Images/pagenotfound.png'/>
              <h4>Oops! Page not found!</h4>
              <p>We can't seem to find the page you're looking for <br/>
                Try going back to the previous page.
              </p>
              <button className='btn create-btn cursor-pointer' onClick={onBackNavigationHandler}>Back</button>
            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  );
};
 
export default PageNotFound;