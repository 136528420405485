import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    blockedAppsData:[],
   // calendarData:[]
}

const BlockedAppsReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_BLOCKED_APPS_REQUEST: return getBlockedAppsRequest(state, action);
        case ACTION.GET_BLOCKED_APPS_SUCCESS: return getBlockedAppsSuccess(state, action);
        case ACTION.GET_BLOCKED_APPS_FAILURE: return getBlockedAppsFailure(state, action);
        
        default: return state;
    }
}

const getBlockedAppsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getBlockedAppsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        blockedAppsData: action.payload,
    });
}
const getBlockedAppsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default BlockedAppsReducer;