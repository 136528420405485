import * as ACTION from './actionTypes';
import {callAPI} from "../../axios"


/* start BroadcastersList */

const getRoleRequest = () => {
    return {
        type: ACTION.GET_ROLES_DETAILS_REQUEST
    }
}

const getRoleSuccess = (roleToken) => {
    return {
        type: ACTION.GET_ROLES_DETAILS_SUCCESS,
        payload: roleToken,
    }
}

const getRoleFailure = (errorMsg) => {
    return {
        type: ACTION.GET_ROLES_DETAILS_FAILURE,
        payload: errorMsg,
    }
}

export const getRoleList = (pageOptions) => {
    let payLoad = pageOptions.filter
    const apiPath = `/role/search?pageNo=${pageOptions.pageNo}&recordsPerPage=${pageOptions.recordsPerPage}`;
    return async function(dispatch) {
        dispatch(getRoleRequest());
        // api.post(apiPath, payLoad)
        callAPI(apiPath,"POST",payLoad).then(res => {
            // console.log("getRole",res.data)
            dispatch(getRoleSuccess(res.data));
        })
        .catch(err => {
            dispatch(getRoleFailure(err.message));
        });;
    };
};

