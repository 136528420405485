/**
 * Notification Component
 */
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import Badge from "@mui/material/Badge";
import "./UserBox.scss";
import { useHistory } from "react-router-dom";
import SockJsClient from "react-stomp";
import { callAPI } from "../../../axios";

function SyncFailed() {
  const history = useHistory();
  const userDetails = JSON.parse(localStorage.getItem("user_id"));
  const [newFlag, setNewFlag] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");

  useEffect(() => {
    callAPI(`/entitlement-sync-notification?userSid=${userDetails.sid}`, "GET")
      .then((response) => {
        if (response?.hasNewMessage === true) {
          setNewFlag(true);
          setTooltipMessage(response?.message);
        } else {
          setNewFlag(false);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const onSyncFailIconClick = () => {
    callAPI(
      `/acknowledge-entitlement-notification?userSid=${userDetails.sid}`,
      "PUT"
    )
      .then((response) => {})
      .catch((error) => console.log(error));
      setNewFlag(false)
    history.push("/entitlementMismatch");
  };

  const onNewSynFail = (data) => {
    if (data?.hasNewMessage === true) {
      setNewFlag(true);
      setTooltipMessage(data?.message);
    } else {
      setNewFlag(false);
    }
  };

  return (
    <>
      <Tooltip title={"Entitlements Mismatch"} placement="bottom">
        <div className="cursor-pointer" onClick={onSyncFailIconClick}>
          <Badge
            badgeContent={newFlag == true ? "New" : null}
            color="primary"
            className={`notifybadge ${newFlag ? "mr-3" : ""}`}
          >
            <SyncProblemIcon className="notification account-circulation " />
          </Badge>
        </div>
      </Tooltip>
      <SockJsClient
        url={`${process.env.REACT_APP_BASE_URL}/stb-ws`}
        topics={[`/topic/entitlement-sync-msg`]}
        headers={{
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        }}
        autoReconnect={true}
        onMessage={onNewSynFail}
      />
    </>
  );
}
export default SyncFailed;
