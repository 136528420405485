import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getBatteryStatusRequest = () => {
    return {
        type: ACTION.GET_BATTERY_STATUS_REQUEST
    }
}

const getBatteryStatusSuccess = (apiData) => {
    return {
        type: ACTION.GET_BATTERY_STATUS_SUCCESS,
        payload: apiData,
    }
}

const getBatteryStatusFailure = (errorMsg) => {
    return {
        type: ACTION.GET_BATTERY_STATUS_FAILURE,
        payload: errorMsg,
    }
}

export const batteryStatusFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const batteryStatusFetchNowControl = (payload)=>{
    return {
        type: ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const batteryStatusFetchNowStatus = (payload) => {
    return {
        type: ACTION.BATTERY_STATUS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const batteryStatusProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.BATTERY_STATUS_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getBatteryStatusData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/batteryStatus/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getBatteryStatusRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getBatteryStatusSuccess(res));
        })
        .catch(err => {
            dispatch(getBatteryStatusFailure(err.message));
        });;
    };
};

export const postFetchNowBatteryStatusControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowBatteryStatusSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(batteryStatusFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(batteryStatusFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getBatteryStatusFailure(err.message));
        });
    }; 
};