import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const RcuKeyCodesStbRecuder = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_RCU_KEY_CODES_STB_REQUEST: return getRcuKeyCodesStbRequest(state, action);
        case ACTION.GET_RCU_KEY_CODES_STB_SUCCESS: return getRcuKeyCodesStbSuccess(state, action);
        case ACTION.GET_RCU_KEY_CODES_STB_FAILURE: return getRcuKeyCodesStbFailure(state, action);
        case ACTION.RCU_KEY_CODES_STB_RESET: return rcuKeyCodesStbReset(state, action);
        
        default: return state;
    }
}

const getRcuKeyCodesStbRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getRcuKeyCodesStbSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const rcuKeyCodesStbReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: [],
    });
}
const getRcuKeyCodesStbFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default RcuKeyCodesStbRecuder;