import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"
const getTifMonitoringActionsRequest = () => {
    return {
        type: ACTION.GET_ANDROID_TIFMONITORING_REQUEST
    }
}

const getTifMonitoringActionsSuccess = (userToken) => {
    return {
        type: ACTION.GET_ANDROID_TIFMONITORING_SUCCESS,
        payload: userToken,
    }
}

const postTifMonitoringActionsControl = (userToken) => {
    return {
        type: ACTION.POST_ANDROID_TIFMONITORING_CONTROL,
        payload: userToken,
    }
}
const rebuildEPGCacheTimerFlag = (userToken) => {
    return {
        type: ACTION.REBUILD_EPG_CACHE_TIMER_FLAG,
        payload: userToken,
    }
}

export const postTifMonitoringActionsControlStatus = (userToken) => {
    return {
        type: ACTION.GET_ANDROID_TIFMONITORING_CONTROL_STATUS,
        payload: userToken,
    }
}

export const resetRebuildEPGCacheButton = (userToken) => {
    return {
        type: ACTION.RESET_REBUILD_EPG_CACHE_BUTTON,
    }
}

const getTifMonitoringActionsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_ANDROID_TIFMONITORING_FAILURE,
        payload: errorMsg,
    }
}

export const tifMonitoringFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.TIF_MONITORING_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const tifMonitoringFetchNowControl = (payload)=>{
    return {
        type: ACTION.TIF_MONITORING_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const tifMonitoringFetchNowStatus = (payload) => {
    return {
        type: ACTION.TIF_MONITORING_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const tifMonitoringFetchNowReset = (state, action) => {
    return {
        type: ACTION.TIF_MONITORING_FETCH_NOW_RESET
    }
}

const postRebuildEPGCacheFailure = (errorMsg) => {
    return {
        type: ACTION.TIFMONITORING_REBUILD_EPG_CACHE_FAILURE,
        payload: errorMsg,
    }
}

export const tifMonitoringProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.TIF_MONITORING_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getTifMonitoringActions = (sid,cdsn) => {
    const apiPath = `/KPI/STB/tifStatus/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getTifMonitoringActionsRequest());
        // callAPI("/accedo-detail","GET").then(res=>{
        //     return callAPI(`${res.accedoApiUrl}${apiPath}`,"POST",payload)
        //  }).then(response=>dispatch(getNetworkGraphCdsnSuccess(response.data)))
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getTifMonitoringActionsSuccess(res));
        })
        .catch(err => {
            dispatch(getTifMonitoringActionsFailure(err.message));
        });;
    };
};

export const postRebuildEPGCacheControl = (sid,cdsn,payload, controlAction) => {
    const apiPath = `/control/${cdsn}/${controlAction}`;
    let apiPathStatus = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST", payload).then(res => {
            dispatch(postTifMonitoringActionsControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            setTimeout(()=>{
                dispatch(rebuildEPGCacheTimerFlag())
              },120000)
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPathStatus}${res.body.controlRequestID}`,"GET").then(response => {
                dispatch(postTifMonitoringActionsControlStatus(response[0]));
            })
        })
        .catch(err => {
            console.log(err.message)
            dispatch(postRebuildEPGCacheFailure(new Date().getTime()));
        });
    }; 
};

export const postFetchNowTifMonitoringControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowTifMonitoringSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(tifMonitoringFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(tifMonitoringFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(tifMonitoringFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getTifMonitoringActionsFailure(err.message));
        });
    }; 
};

