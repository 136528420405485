import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
    entitlementFetchNowData:[],
    appFetchNowData:[],
    networkConditionFetchNowData:[],
    networkSpeedFetchNowData:[],
    batteryFetchNowData:[],
    satelliteSignalFetchNowData:[],
    bluetoothFetchNowData:[],
    andoridSettingsFetchNowData:[],
    systemDetailsFetchNowData:[],
    myRecordsFetchNowData:[],
    tifMonitoringFetchNowData:[],
    entitlementFetchNow:4000,
    entitlementFetchNowTimer:4000,
    appFetchNow:4000,
    appsFetchNowTimer:4000,
    networkConditionFetchNow:4000,
    networkConditionFetchNowTimer:4000,
    networkSpeedFetchNowTimer:4000,
    networkSpeedFetchNow:4000,
    batteryFetchNow:4000,
    batteryFetchNowTimer:4000,
    satelliteSignalFetchNowTimer:4000,
    bluetoothFetchNowTimer:4000,
    andoridSettingsFetchNowTimer:4000,
    systemDetailsFetchNowTimer:4000,
    myRecordsFetchNowTimer:4000,
    tifMonitoringFetchNowTimer:4000,
    satteliteFetchNow:4000,
    bluetoothFetchNow:4000,
    androidFetchNow:4000,
    systemDetailsFetchNow:4000,
    myRecordsFetchNow:4000,
    tifMonitoringFetchNow:4000
   // calendarData:[]
}

const FetchInitiateReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_FETCH_NOW_REQUEST: return getFetchNowRequest(state, action);
        case ACTION.GET_FETCH_NOW_SUCCESS: return getFetchNowSuccess(state, action);
        case ACTION.GET_FETCH_NOW_FAILURE: return getFetchNowFailure(state, action);
        case ACTION.GET_FETCH_NOW_ENTITLEMENTS: return entitlementFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_APP: return appFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_NETWORK_CONDITION: return networkConditionFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_BATTERY: return batteryFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_NETWORK_SPEED: return networkSpeedFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_SATTELITE: return satelliteFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_BLUETOOTH: return bluetoothFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_ANDROID: return androidFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_SYSTEM_DETAILS: return systemDetailsFetchNow(state, action);
             case ACTION.GET_FETCH_NOW_RECORDS: return myRecordsFetchNow(state, action); 
             case ACTION.GET_FETCH_NOW_TIF_MONITORING: return tifMonitoringFetchNow(state, action);
        case ACTION.GET_FETCH_NOW_ENTITLEMENTS_RESPONSE: return entitlementFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_APP_RESPONSE: return appFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_NETWORK_CONDITION_RESPONSE: return networkConditionFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_NETWORK_SPEED_RESPONSE: return networkSpeedFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_BATTERY_RESPONSE: return batteryFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_SATELLITE_SIGNAL_RESPONSE: return satelliteSignalFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_BLUETOOTH_RESPONSE: return bluetoothFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_ANDORID_SETTINGS_RESPONSE: return andoridsettingsFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_SYSTEM_DETAILS_RESPONSE: return systemDetailsFetchNowData(state, action);
            case ACTION.GET_FETCH_NOW_MY_RECORDS_RESPONSE: return myRecordsFetchNowData(state, action);
            case ACTION.GET_FETCH_NOW_MY_TIF_MONITORING_RESPONSE: return tifMonitoringFetchNowData(state, action);
        case ACTION.GET_FETCH_NOW_ENTITLEMENTS_TIMER: return entitlementFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_APPS_TIMER: return appsFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_NETWORK_CONDITION_TIMER: return networkConditionFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_NETWORK_SPEED_TIMER: return networkSpeedFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_BATTERY_TIMER: return batteryFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_SATELLITE_SIGNAL_TIMER: return satelliteSignalFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_BLUETOOTH_TIMER: return bluetoothFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_ANDORID_SETTINGS_TIMER: return andoridsettingsFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_SYSTEM_DETAILS_TIMER: return systemDetailsFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_MY_RECORDS_TIMER: return myRecordsFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_TIF_MONITORING_TIMER: return tifMonitoringFetchNowTimer(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_RESET: return getFetchNowReset(state, action);
        default: return state;
    }
}

const getFetchNowRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getFetchNowSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: '',
        data:[],
        entitlementFetchNowData:[],
        appFetchNowData:[],
        networkConditionFetchNowData:[],
        networkSpeedFetchNowData:[],
        batteryFetchNowData:[],
        satelliteSignalFetchNowData:[],
        bluetoothFetchNowData:[],
        andoridSettingsFetchNowData:[],
        systemDetailsFetchNowData:[],
        myRecordsFetchNowData:[],
        tifMonitoringFetchNowData:[],
        entitlementFetchNow:4000,
        entitlementFetchNowTimer:4000,
        appFetchNow:4000,
        appsFetchNowTimer:4000,
        networkConditionFetchNow:4000,
        networkConditionFetchNowTimer:4000,
        networkSpeedFetchNowTimer:4000,
        networkSpeedFetchNow:4000,
        batteryFetchNow:4000,
        batteryFetchNowTimer:4000,
        satelliteSignalFetchNowTimer:4000,
        bluetoothFetchNowTimer:4000,
        andoridSettingsFetchNowTimer:4000,
        systemDetailsFetchNowTimer:4000,
        myRecordsFetchNowTimer:4000,
        tifMonitoringFetchNowTimer:4000,
        satteliteFetchNow:4000,
        bluetoothFetchNow:4000,
        androidFetchNow:4000,
        systemDetailsFetchNow:4000,
        myRecordsFetchNow:4000,
        tifMonitoringFetchNow:4000
    });
}
const entitlementFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        entitlementFetchNow: action.payload,
    });
}
const entitlementFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        entitlementFetchNowTimer: action.payload,
    });
}
const entitlementFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        entitlementFetchNowData: action.payload,
    });
}
const appsFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appsFetchNowTimer: action.payload,
    });
}
const appFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appFetchNow: action.payload,
    });
}
const appFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appFetchNowData: action.payload,
    });
}
const networkConditionFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionFetchNowTimer: action.payload,
    });
}
const networkConditionFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionFetchNow: action.payload,
    });
}
const networkConditionFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionFetchNowData: action.payload,
    });
}
const batteryFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryFetchNow: action.payload,
    });
}
const batteryFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryFetchNowTimer: action.payload,
    });
}
const batteryFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryFetchNowData: action.payload,
    });
}
const networkSpeedFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNow: action.payload,
    });
}
const networkSpeedFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNowTimer: action.payload,
    });
}
const networkSpeedFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNowData: action.payload,
    });
}
const satelliteFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satteliteFetchNow: action.payload,
    });
}
const satelliteSignalFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satelliteSignalFetchNowTimer: action.payload,
    });
}
const satelliteSignalFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satelliteSignalFetchNowData: action.payload,
    });
}
const bluetoothFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothFetchNow: action.payload,
    });
}
const bluetoothFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothFetchNowTimer: action.payload,
    });
}
const bluetoothFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothFetchNowData: action.payload,
    });
}
const androidFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        androidFetchNow: action.payload,
    });
}
const andoridsettingsFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        andoridSettingsFetchNowTimer: action.payload,
    });
}
const andoridsettingsFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        andoridSettingsFetchNowData: action.payload,
    });
}
const systemDetailsFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNowTimer: action.payload,
    });
}
const systemDetailsFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNowData: action.payload,
    });
}
const systemDetailsFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNow: action.payload,
    });
}
//My Records
const myRecordsFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNow: action.payload,
    });
}
const myRecordsFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNowTimer: action.payload,
    });
}
const  myRecordsFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNowData: action.payload,
    });
}

//tif monitoring
const tifMonitoringFetchNow = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNow: action.payload,
    });
}
const tifMonitoringFetchNowTimer = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNowTimer: action.payload,
    });
}
const  tifMonitoringFetchNowData = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNowData: action.payload,
    });
}



const getFetchNowFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default FetchInitiateReducer;