import * as ACTION from '../actions/actionTypes';
 import { UpdateObject } from '../../utils/utils';
 
 const initialState = {
     loading: false,
     errorMsg: '',
     data:[],
 }
 
 const TotalAgentVersionReducer = (state = initialState, action) => {
     switch(action.type) {
        
         case ACTION.GET_TOTAL_AGENT_VERSION_REQUEST: return getTotalAgentVersionRequest(state, action);
         case ACTION.GET_TOTAL_AGENT_VERSION_SUCCESS: return getTotalAgentVersionSuccess(state, action);
         case ACTION.GET_TOTAL_AGENT_VERSION_FAILURE: return getTotalAgentVersionFailure(state, action);
         
         default: return state;
     }
 }
 
 const getTotalAgentVersionRequest = (state, action) => {
     return UpdateObject(state, {
         loading: true,
         errorMsg:"",
     });
 }
 const getTotalAgentVersionSuccess = (state, action) => {
     return UpdateObject(state, {
         loading: false,
         data: action.payload,
         errorMsg:"",
     });
 }
 const getTotalAgentVersionFailure = (state, action) => {
     return UpdateObject(state, {
         loading: false,
         data:[],
         errorMsg: action.payload,
     });
 }
 
	 export default TotalAgentVersionReducer