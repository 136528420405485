import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getStbInfoBySidRequest = () => {
    return {
        type: ACTION.GET_STB_INFO_BY_SID_REQUEST
    }
}

const getStbInfoBySidSuccess = (userToken) => {
    return {
        type: ACTION.GET_STB_INFO_BY_SID_SUCCESS,
        payload: userToken,
    }
}

const getStbInfoBySidFailure = (errorMsg) => {
    return {
        type: ACTION.GET_STB_INFO_BY_SID_FAILURE,
        payload: errorMsg,
    }
}

export const getStbInfoBySidFetchNowReset = (state, action) => {
    return {
        type: ACTION.STB_INFO_BY_SID_FETCH_NOW_RESET
    }
}

export const getStbInfoBySidReset = (state, action) => {
    return {
        type: ACTION.GET_STB_INFO_BY_SID_RESET
    }
}

export const setCdsn = (data) => {
    return {
        type: ACTION.SET_CDSN,
        payload: data
    }
}

export const appsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.APPS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const appsFetchNowControl = (payload)=>{
    return {
        type: ACTION.APPS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const appsFetchNowStatus = (payload) => {
    return {
        type: ACTION.APPS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const entitlementsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const entitlementsFetchNowControl = (payload)=>{
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const entitlementsFetchNowStatus = (payload) => {
    return {
        type: ACTION.ENTITLEMENTS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const batteryStatusFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const batteryStatusFetchNowControl = (payload)=>{
    return {
        type: ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const batteryStatusFetchNowStatus = (payload) => {
    return {
        type: ACTION.BATTERY_STATUS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const networkSpeedFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const networkSpeedFetchNowControl = (payload)=>{
    return {
        type: ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const networkSpeedFetchNowStatus = (payload) => {
    return {
        type: ACTION.NETWORK_SPEED_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const networkConditionsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const networkConditionsFetchNowControl = (payload)=>{
    return {
        type: ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const networkConditionsFetchNowStatus = (payload) => {
    return {
        type: ACTION.NETWORK_CONDITIONS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const bluetoothDevicesFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.BLUETOOTH_DEVICES_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const bluetoothDevicesFetchNowControl = (payload)=>{
    return {
        type: ACTION.BLUETOOTH_DEVICES_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const bluetoothDevicesFetchNowStatus = (payload) => {
    return {
        type: ACTION.BLUETOOTH_DEVICES_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const satelliteSignalFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.SATELLITE_SIGNAL_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const satelliteSignalFetchNowControl = (payload)=>{
    return {
        type: ACTION.SATELLITE_SIGNAL_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const satelliteSignalFetchNowStatus = (payload) => {
    return {
        type: ACTION.SATELLITE_SIGNAL_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const systemDetailsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const systemDetailsFetchNowControl = (payload)=>{
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const systemDetailsFetchNowStatus = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const globalControlStatus = (payload) => {
    return {
        type: ACTION.GLOBAL_CONTROL_STATUS,
        payload: payload,
    }
}

export const getStbInfoBySid = (payload) => {
    if (payload != undefined){
        const apiPath = `/KPI/STBsForSID/${payload}`;
        return async function(dispatch) {
            dispatch(getStbInfoBySidRequest());
            // callAPI("/accedo-detail","GET").then(res=>{
            //     return callAPI(`${res.accedoApiUrl}${apiPath}`,"POST",payload)
            //  }).then(response=>dispatch(getStbInfoBySidSuccess(response)))
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST",payload).then(res => {
                dispatch(getStbInfoBySidSuccess(res));
            })
            .catch(err => {
                dispatch(getStbInfoBySidFailure(err.message));
            });
        };
    }
};

export const postFetchNowEntitlementControl = (sid,cdsn,payload,controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    // const apiTtnRefresh = `/entitlement-refresh/${sid}?requestType=${platform}&vcNumber=${cdsn}`;
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowEntitlementsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(entitlementsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            // setTimeout(()=>{
            //     dispatch(entitlementsFetchNowControlTimerFlag())
            //   },120000)
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                dispatch(entitlementsFetchNowStatus(response[0]));
            })
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowAppsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowAppsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(appsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            // setTimeout(()=>{
            //     dispatch(appsFetchNowControlTimerFlag())
            //   },120000)
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                dispatch(appsFetchNowStatus(response[0]));
            })
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowBatteryStatusControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowBatterySid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(batteryStatusFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            // setTimeout(()=>{
            //     dispatch(batteryStatusFetchNowControlTimerFlag())
            //   },120000)
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                dispatch(batteryStatusFetchNowStatus(response[0]));
            })
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowNetworkSpeedControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowNetworkSpeedSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(networkSpeedFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            // setTimeout(()=>{
            //     dispatch(networkSpeedFetchNowControlTimerFlag())
            //   },120000)
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                dispatch(networkSpeedFetchNowStatus(response[0]));
            })
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowNetworkConditionsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowNetworkConditionsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(networkConditionsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(networkConditionsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(networkConditionsFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowBluetoothDevicesControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowBluetoothDevicesSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(bluetoothDevicesFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(bluetoothDevicesFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowSatelliteSignalControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowSatelliteSignalSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(satelliteSignalFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(satelliteSignalFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(satelliteSignalFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

export const postFetchNowSystemDetailsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowSystemDetailsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(systemDetailsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(systemDetailsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(systemDetailsFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getStbInfoBySidFailure(err.message));
        });
    }; 
};

