import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';
const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    myRecordsFetchNowControl:[],
    myRecordsFetchNowControlStatus:[],
    myRecordsFetchNowTime:null,
    myRecordsFetchNowTimerFlag:false,

    webhookFlag:false,
}
const MyRecordReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_MY_RECORDS_REQUEST: return getMyRecordsRequest(state, action);
        case ACTION.GET_MY_RECORDS_SUCCESS: return getMyRecordsSuccess(state, action);
        case ACTION.GET_MY_RECORDS_FAILURE: return getMyRecordsFailure(state, action);

        case ACTION.MY_RECORDS_FETCH_NOW_CONTROL: return myRecordsFetchNowControl(state, action);
        case ACTION.MY_RECORDS_FETCH_NOW_STATUS: return myRecordsFetchNowCotrolStatus(state, action);
        case ACTION.MY_RECORDS_FETCH_NOW_CONTROL_TIMER_FLAG: return myRecordsFetchNowTimerFlag(state, action);
        
        case ACTION.MY_RECORDS_FETCH_NOW_RESET: return myRecordsFetchNowReset(state, action);

        case ACTION.MY_RECORDS_PROCESSED_WEBHOOK_TRIGGERED: return myRecordsProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const myRecordsProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getMyRecordsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getMyRecordsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getMyRecordsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}
const myRecordsFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNowControl:[],
        myRecordsFetchNowControlStatus:[],
        myRecordsFetchNowTime:null,
        myRecordsFetchNowTimerFlag:false,
    });
}
const myRecordsFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNowControl: action.payload,
        // myRecordsFetchNowTime:new Date().getTime()
    });
}
const myRecordsFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowMyRecordsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNowControlStatus: action.payload,
    });
}
const myRecordsFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsFetchNowTimerFlag: !state.myRecordsFetchNowTimerFlag,
    });
}
export default MyRecordReducer;