import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    networkConditionsFetchNowControl:[],
    networkConditionsFetchNowControlStatus:[],
    networkConditionsFetchNowTime:null,
    networkConditionsFetchNowTimerFlag:false,
   // calendarData:[]
    webhookFlag:false,
}

const NetworkConditionsReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_NETWORK_CONDITIONS_REQUEST: return getNetworkConditionsRequest(state, action);
        case ACTION.GET_NETWORK_CONDITIONS_SUCCESS: return getNetworkConditionsSuccess(state, action);
        case ACTION.GET_NETWORK_CONDITIONS_FAILURE: return getNetworkConditionsFailure(state, action);
        
        case ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL: return networkConditionsFetchNowControl(state, action);
        case ACTION.NETWORK_CONDITIONS_FETCH_NOW_STATUS: return networkConditionsFetchNowCotrolStatus(state, action);
        case ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL_TIMER_FLAG: return networkConditionsFetchNowTimerFlag(state, action);

        case ACTION.NETWORK_CONDITIONS_FETCH_NOW_RESET: return networkConditionsFetchNowReset(state, action);

        case ACTION.NETWORK_CONDITIONS_PROCESSED_WEBHOOK_TRIGGERED: return networkConditionsProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const networkConditionsProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getNetworkConditionsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getNetworkConditionsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getNetworkConditionsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const networkConditionsFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionsFetchNowControl:[],
        networkConditionsFetchNowControlStatus:[],
        networkConditionsFetchNowTime:null,
        networkConditionsFetchNowTimerFlag:false,
    });
}

const networkConditionsFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionsFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const networkConditionsFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowNetworkConditionsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        networkConditionsFetchNowControlStatus: action.payload,
    });
}
const networkConditionsFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionsFetchNowTimerFlag: !state.networkConditionsFetchNowTimerFlag,
    });
}

export default NetworkConditionsReducer;