import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';
import  Axios from 'axios';

const initialState = {
    loading: false,
    error: '',
    jwt:'',
    authuserlist:[]
}

const AuthReducer = (state = initialState, action) => {
   
    switch(action.type) {
       
        case ACTION.GET_AUTHACTION_DETAILS_REQUEST: return getAuthActionDetailsRequest(state, action);
        case ACTION.GET_AUTHACTION_DETAILS_SUCCESS: return getAuthActionDetailsSuccess(state, action);
        case ACTION.GET_AUTHACTION_DETAILS_FAILURE: return getAuthActionDetailsFailure(state, action);
        
        case ACTION.RESET_FAILURE_MESSAGE: return resetFailureMessage(state, action);
        default: return state;
    }
}

const getAuthActionDetailsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}

const getAuthActionDetailsSuccess = (state, action) => {
    // const { response, error } = action;
    const jwt_token = action?.payload?.data.jwt;
    if (jwt_token) {
        localStorage.setItem("user_id", JSON.stringify(action?.payload?.data.userDetails))
        userLoginSuccess(action?.payload?.data);
    }
    return UpdateObject(state, {
        ...state,
        loading: false,
        jwt: action?.payload?.data.jwt,
        authuserlist: action?.payload?.data.userDetails,
        error: null
    });
}
const getAuthActionDetailsFailure = (state, action) => {
    return UpdateObject(state, {
        ...state,
        loading: false,
        error: action.payload,
    });
}

const resetFailureMessage = (state, action) => {
    return UpdateObject(state, {
        ...state,
        loading: false,
        error: "",
    });
}

const userLoginSuccess = (data) => {
    const userDetails = data?.userDetails;
    const { permissions } = userDetails;
    const { roles } = userDetails;
    userDetails.availablePermissions = permissions.reduce((acc, item) => {
        if(item.active) {
            acc.push(item.permissionCode);
        }
        return acc;
    }, []);
    userDetails.availableRoles = roles.reduce((acc, item) => {
        if(item.active) {
            acc.push(item.roleCode);
        }
        return acc;
    }, []);

    localStorage.setItem("userDetails", JSON.stringify(userDetails))
}
export default AuthReducer;