import React, {Fragment} from 'react';
import { useEffect, useState } from "react";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input
} from 'reactstrap';  
import { MdOutlineRefresh } from "react-icons/md";
import Axios from "axios"
import './Signup.scss'
import RctModel from '../Components/Modal/Examples/Modal';
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logotext from "../../../src/assets/utils/images/tata-play-middle.png";
import logotv from "../../../src/assets/utils/images/tataplay-tv-image.png";
import logobottom from "../../../src/assets/utils/images/tataplay-bottom.png";

    function Signup() {
        const [firstNameValidationError,setFirstNameValidationError] = useState("")
        const [lastNameValidationError,setLastNameValidationError] = useState("")
        const [firstNameError, setFirstNameError] = useState('');
        const [lastNameError, setLastNameError] = useState('');
        const [userNameError, setUserNameError] = useState('');
        const [phoneNumberError, setPhoneNumberError] = useState('');
        const [captchaTextError, setCaptchaTextError] = useState('');
        const [validateFlag, setValidateFlag] = useState(false);
        const [errorEmail, setErrorEmail] = useState("");
        const [errorPhone, setErrorPhone] = useState("");
        const [captchaImage, setCaptchaImage] = useState();
        const [isLinkDisabled, setIsLinkDisabled] = useState(true);
        const [flag, setFlag] = useState(false);
        const [counter, setCounter] = useState(0);
        const [mfaResponse, setMfaResponse] = useState('');
        const [mfaErrorResponse, setMfaErrorResponse] = useState('');
        const [mfaCodeTouched, setMfaCodeTouched] = useState(false);
        const [apiError,setApiError]=useState("")
        const [showPopup, setShowPopup] = useState(false);
        const [response, setResponse] = useState('');
        // const [errorResponse, setErrorResponse] = useState('');
        const [formValue, setformValue] = useState({   
            firstName: "",
            lastName: "",
            userName: "",
            phoneNo: "",
            captchaText:"",
            mfaCode:""
          });
          const history = useHistory();
    //Validation Error
    const validateError = () =>{
        formValue.firstName !==""
        ? setFirstNameError("")
        : setFirstNameError("Please Enter Your First Name");
      formValue.lastName !==""
        ? setLastNameError("")
        : setLastNameError("Please Enter Your Last Name");
        formValue.userName !==""
        ? setUserNameError("")
        : setUserNameError("Please Enter Your Email");
        formValue.phoneNo !==""
        ? setPhoneNumberError("")
        : setPhoneNumberError("Please Enter Phone Number");
        formValue.captchaText !==""
        ? setCaptchaTextError("")
        : setCaptchaTextError("Please Enter Captcha Code");
    }
    useEffect(()=>{
        if(validateFlag){
          validateError()
        }
      },[validateFlag,formValue])
      //Email Validation
      const validEmail = () => {
        const validRegex = "^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$";
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValue.userName)) {
          setErrorEmail("");
        } else if (formValue.userName == "") {
          setErrorEmail("");
        } else {
          setErrorEmail("Enter Email in Correct format");
        }
      };
      useEffect(() => {
        validEmail();
      }, [formValue.userName]); 
      
      useEffect(()=>{
        if (/^[a-zA-z]+([\.][a-zA-Z]+)+([\s][a-zA-Z]+)*$/.test(formValue.firstName)) {
          setFirstNameValidationError("");
        }
        else if(/^[a-zA-z]+([\s][a-zA-Z]+)+([\.][a-zA-Z]+)*$/.test(formValue.firstName)){
          setFirstNameValidationError("");
          }
        else if(/^[a-zA-Z]*$/.test(formValue.firstName)){
          setFirstNameValidationError("");
          }
        else{
          setFirstNameValidationError(`Only Alphabets, dot & blank space allowed`);
          }
      },[formValue.firstName])
      
      useEffect(()=>{
        if (/^[a-zA-z]+([\.][a-zA-Z]+)+([\s][a-zA-Z]+)*$/.test(formValue.lastName)) {
          setLastNameValidationError("");
        }
        else if(/^[a-zA-z]+([\s][a-zA-Z]+)+([\.][a-zA-Z]+)*$/.test(formValue.lastName)){
          setLastNameValidationError("");
          }
        else if(/^[a-zA-Z]*$/.test(formValue.lastName)){
          setLastNameValidationError("");
          }
        else{
          setLastNameValidationError(`Only Alphabets, dot & blank space allowed`);
          }
      },[formValue.lastName])

      //Submitting form
      const submit = (e) => {
        e.preventDefault();
        setValidateFlag(true);
        if (
            formValue.userName != "" &&
            formValue.firstName != "" &&
            formValue.lastName != "" &&
            formValue.phoneNo != "" &&
            formValue.captchaText !="" &&
            errorPhone == "" &&
            errorEmail == ""
        ) {
          Axios.get(`${process.env.REACT_APP_BASE_URL}/domain-allowed/list`, {withCredentials: true})
          .then((response) => {
            const validDomainList = []
            for (let i=0; i< response?.data?.data?.length; i++){
              validDomainList.push(response?.data?.data[i].domainAllowed)
            }
            const domain = formValue.userName.split("@")[1]
            if(validDomainList?.includes(domain)){
              Axios.post(`${process.env.REACT_APP_BASE_URL}/user/signup`, formValue , {withCredentials: true})
              .then((response) => {
                setApiError("")
                setShowPopup(true);
                setResponse(response.data?.message);
                setMfaErrorResponse("");
              })
              .catch((error) => {
                setApiError(error.response?.data?.errorMessage)
                setMfaErrorResponse("");
                setMfaResponse("");
              });
            }
            else{
              setApiError("User Domain doesn't exist.Please contact with Administrator.")
              setMfaErrorResponse("");
            }
          })
          .catch((error) => {
            setApiError(error.response?.data?.errorMessage)
            setMfaErrorResponse("");
            setMfaResponse("");
          });
        }      
      };
      //OnChange
      const handleChange = (e) => {
        setformValue({
          ...formValue,
          [e.target.id]: e.target.value,
        });
      };
    //For Handling Phone Number
    const PhoneHandler = (e) => {
        if (!isNaN(e.target.value)) {
          setformValue({
            ...formValue,
            [e.target.id]: e.target.value,
          });
          setErrorPhone("");
          if (e.target.value.length < 10) {
            setErrorPhone("Please enter 10 or more digits");
          }
        } else {
          setErrorPhone("Enter correct number only");
        }
      };
      const phoneBlurHandler = (e) => {
        if (formValue.phoneNo.length < 10 && formValue.phoneNo.length > 0) {
          setErrorPhone("Please enter more than 10 digits");
        } else {
          setErrorPhone("");
        }
      };

      //Get Captcha

      const getCapcha = () => {
        
        Axios.get(`${process.env.REACT_APP_BASE_URL}/captcha`, {
          responseType: "blob", withCredentials: true
        })
          .then((response) => {
            const urlData = window.URL.createObjectURL(response.data);
            setCaptchaImage(urlData);
          })
          .then((error) => {
            console.log(error);
          });
      };
     
      useEffect(() => {
        getCapcha();
      }, []);

//Get MFA

const onGetMFACode = () => {
  setIsLinkDisabled(false);
  setFlag(true);
  setCounter(30);
  const timeout = setTimeout(() => {
    setIsLinkDisabled(true);
  }, 30000);

  Axios.post(
     `${process.env.REACT_APP_BASE_URL}/user/signup-mfa`, {username:formValue.userName,firstName:formValue.firstName, captchaText: formValue.captchaText})
     .then((response) => {
       setMfaResponse(response.data?.message);
       setApiError("")
     })
     .catch((error) => {
      setIsLinkDisabled(true)
      setMfaErrorResponse(error?.response?.data?.errorMessage);
      setApiError("")
     });

  return () => {
    clearTimeout(timeout);
  };
};

useEffect(() => {
  if (flag) {
    if (counter > 0) {
      const timeout = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }
}, [counter]);

const navigatetoLogin = () => {
  history.replace('/login');
  setShowPopup(false);
}


    return (
    <Fragment>
    <div className="login-container row mx-0">  
        <div className='col-5 left-panel crateUser-leftpanel text-center'> 
        <div >
          <img className="left-panel-tv-logo-signup" src={logotv} alt="Logo" />
          </div>
          <div className="left-panel-tata mt-4">
          <img   src={logotext} alt="Logo" />
          </div>
          <div className="left-panel-shape" >
          <img  src={logobottom} alt="Logo" />
          </div>
        {/* <img className="login-logo" src={logo} alt="Logo" />   */}
        </div>
        <div className='col-7 right-panel d-flex align-items-center crateUser-rightpanel'> 
        <ReactCSSTransitionGroup
                component="div"
                className='form-wraper signup'
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}> 
                        <CardTitle className='rr'>Create Account</CardTitle>
                        <p className="text-center mb-5">Enter details to register your account to have an <br/> access for STB Diagnostic.</p>
                        {apiError && <p className="validationerror text-center font-weight-bold">{apiError}</p>}
                        {((mfaResponse && mfaResponse!=='') ||(mfaErrorResponse && mfaErrorResponse !==''))  && <p className={`${(mfaResponse && mfaResponse!=="") ? 'text-success':'text-danger validationerror'} text-center`}>{mfaResponse || mfaErrorResponse}</p>}
                        <Form>
                            <Row form className="mt-2">
                                <Col md={6} className='pr-2'>
                                    <FormGroup>
                                        <Label for="exampleEmail11">First Name</Label>
                                        <Input
                                         id="firstName"
                                         onChange={handleChange}
                                         value={formValue.firstName}
                                         maxLength={30}                                       
                                         placeholder="Enter First Name"/>
                                         <p className="validationerror float-left">{firstNameError}</p>
                                         <p className="validationerror float-left">{firstNameValidationError}</p>
                                    </FormGroup>
                                </Col>
                                 <Col md={6} className='pl-2'>
                                    <FormGroup>
                                        <Label for="examplePassword11">Last Name</Label>
                                        <Input 
                                           id="lastName"
                                           onChange={handleChange}
                                           maxLength={30}
                                           value={formValue.lastName}
                                         placeholder="Enter Last Name"/>
                                         <p className="validationerror float-left">{lastNameError}</p>
                                         <p className="validationerror float-left">{lastNameValidationError}</p>
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='pr-2'>
                                    <FormGroup>
                                        <Label for="exampleEmail11">Email</Label>
                                        <Input
                                        id="userName"
                                        type="email"
                                         onChange={handleChange}
                                         value={formValue.userName}
                                         maxLength={40}
                                         placeholder="Enter Email"/>
                                          {userNameError?<p className="validationerror float-left">{userNameError}</p>:<p className="validationerror float-left">{errorEmail}</p>}
                                    </FormGroup>
                                </Col>
                                 <Col md={6} className='pl-2'>
                                    <FormGroup>
                                        <Label for="examplePassword11">Phone Number</Label>
                                        <Input 
                                         id="phoneNo"
                                         onChange={PhoneHandler}
                                         onBlur={phoneBlurHandler}
                                         // className="form-control"
                                         maxLength={12}
                                         minLength={10}
                                         value={formValue.phoneNo}
                                         placeholder="Enter Phone Number"/>
                                          {phoneNumberError?<p className="validationerror float-left">{phoneNumberError}</p>:<p className="validationerror float-left">{errorPhone}</p>}
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='pr-2'>
                                    <FormGroup>
                                        <Label for="examplePassword11">Captcha Code</Label>
                                        <Input 
                                         id="captchaText"
                                         onChange={handleChange}
                                         value={formValue.captchaText}
                                         maxLength={8}
                                         placeholder="Enter Captcha Code"/>
                                         <p className="validationerror float-left">{captchaTextError}</p>
                                    </FormGroup>
                                </Col>
                                <Col md={6} className='pl-2'>
                  <FormGroup className="form-group has-search position-relative">
                  <FontAwesomeIcon icon={faShieldAlt} className="form-control-feedback" /> 
                    <Label for="mfaCode">MFA Code</Label>
                    <Input
                      type="text"
                      name="mfaCode"
                      id="mfaCode"
                      maxLength={8}
                      value={formValue.mfaCode}
                      onChange={handleChange}
                      placeholder="Enter MFA Code"
                      onBlur={() => setMfaCodeTouched(true) }
                    />
                    <p className="validationerror">
                      {((validateFlag &&
                        !formValue.mfaCode )|| (!(formValue.mfaCode.trim() !== '') && mfaCodeTouched)) &&
                        "Please Enter MFA Code"}
                    </p>
                  </FormGroup>
                </Col>
               
                <Col md={6} className="text-left mt-2 pr-2">
                <FormGroup >
                                         <div className="align-items-center d-flex">
                                         <div><img src={captchaImage} className='captcha-image' /></div>
                                         <div onClick={getCapcha} className="ml-3 text-white cursor-pointer"><MdOutlineRefresh style={{fontSize:25}} title='Refresh Captcha'/>Refresh</div>
                                         </div>
                                    </FormGroup>
                </Col>  
                                <Col md={6} className='text-right pl-2 mt-3'>
                                <span
                    role="button"
                    className={`${
                      (formValue.userName && formValue.captchaText && formValue.firstName && firstNameValidationError=="" && isLinkDisabled && errorEmail=="") ? "cursor-pointer active-mfa font-weight-bold" : "actionDisable inactive"
                    } py-2 px-3`}
                    onClick={onGetMFACode}
                  >
                    {isLinkDisabled
                      ? "Send MFA Code"
                      : `Resend MFA Code In ${counter} sec`}
                  </span>
                                </Col>  
                            </Row>
                            <div className='text-center'> 
                            <Link to="/dashboards/home"> 
                            <Button className="Sign-in col-8 mt-4" onClick={submit} >Create Account</Button>
                            </Link>
                            </div>
                            {/* <Link to="/login">
                            <p className='text-center mt-2'>Already have an Account?Login</p>
                            </Link> */}
                             <div className="text-center my-3">
                              <Link to="/login" className="create-account">Already have an account? Login</Link>
                             </div> 
                        </Form>  
            </ReactCSSTransitionGroup> 
            <RctModel
            isOpen={showPopup}
            footer={{
              secondaryButton: {
                action: navigatetoLogin,
                name: "OK",
                addClass:'btn btn-success px-4'
              },
            }}
          >            
              <div>
                <label className="enabelicon mb-30">
                  <img src='/Images/approve-qc.png' style={{width:60}} />
                </label>
                {response !=='' && <p>{response}</p>}
              </div>
          
          </RctModel>
                </div>
    </div>
</Fragment>
);
}
export default Signup;