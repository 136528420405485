import React from "react";

export const RouteLoader = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <h6 className="mt-3">
          Please wait while we load all the Components on page
          {/* <small>
            Because this is a demonstration, we load at once all the Dashboards
            examples. This wouldn't happen in a real live app!
          </small> */}
        </h6>
      </div>
    </div>
  );
};
