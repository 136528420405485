// User  Details
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

//Broadcaster List
export const GET_BROADCASTERS_DETAILS_REQUEST =
  "GET_BROADCASTERS_DETAILS_REQUEST";
export const GET_BROADCASTERS_DETAILS_SUCCESS =
  "GET_BROADCASTERS_DETAILS_SUCCESS";
export const GET_BROADCASTERS_DETAILS_FAILURE =
  "GET_BROADCASTERS_DETAILS_FAILURE";

//Location Info List
export const GET_LOCATION_INFO_REQUEST = "GET_LOCATION_INFO_REQUEST";
export const GET_LOCATION_INFO_SUCCESS = "GET_LOCATION_INFO_SUCCESS";
export const GET_LOCATION_INFO_FAILURE = "GET_LOCATION_INFO_FAILURE";

//AuthActions
export const GET_AUTHACTION_DETAILS_REQUEST = "GET_AUTHACTION_DETAILS_REQUEST";
export const GET_AUTHACTION_DETAILS_SUCCESS = "GET_AUTHACTION_DETAILS_SUCCESS";
export const GET_AUTHACTION_DETAILS_FAILURE = "GET_AUTHACTION_DETAILS_FAILURE";
export const RESET_FAILURE_MESSAGE = "RESET_FAILURE_MESSAGE";

//UserListActions
export const GET_USERLIST_DETAILS_REQUEST = "GET_USERLIST_DETAILS_REQUEST";
export const GET_USERLIST_DETAILS_SUCCESS = "GET_USERLIST_DETAILS_SUCCESS";
export const GET_USERLIST_DETAILS_FAILURE = "GET_USERLIST_DETAILS_FAILURE";

//STB Count
export const GET_STB_COUNT_REQUEST = "GET_GET_STB_COUNT_REQUEST";
export const GET_STB_COUNT_SUCCESS = "GET_GET_STB_COUNT_SUCCESS";
export const GET_STB_COUNT_FAILURE = "GET_GET_STB_COUNT_FAILURE";

//STB Count
export const GET_TOTAL_SYNC_DEVICE_REQUEST = "GET_TOTAL_SYNC_DEVICE_REQUEST";
export const GET_TOTAL_SYNC_DEVICE_SUCCESS = "GET_TOTAL_SYNC_DEVICE_SUCCESS";
export const GET_TOTAL_SYNC_DEVICE_FAILURE = "GET_TOTAL_SYNC_DEVICE_FAILURE";

// Calendar Date Range Picker
export const GET_CALENDAR_DATE_SUCCESS = "GET_CALENDAR_DATE_SUCCESS";
export const GET_CALENDAR_DATE_RESET = "GET_CALENDAR_DATE_RESET";

//TotalLauncherCrashes
export const GET_TOTAL_LAUNCHER_CRASHES_REQUEST =
  "GET_TOTAL_LAUNCHER_CRASHES_REQUEST";
export const GET_TOTAL_LAUNCHER_CRASHES_SUCCESS =
  "GET_TOTAL_LAUNCHER_CRASHES_SUCCESS";
export const GET_TOTAL_LAUNCHER_CRASHES_FAILURE =
  "GET_TOTAL_LAUNCHER_CRASHES_FAILURE";

//TotalFirmwareVersion
export const GET_TOTAL_FIRMWARE_VERSION_REQUEST =
  "GET_TOTAL_FIRMWARE_VERSION_REQUEST";
export const GET_TOTAL_FIRMWARE_VERSION_SUCCESS =
  "GET_TOTAL_FIRMWARE_VERSION_SUCCESS";
export const GET_TOTAL_FIRMWARE_VERSION_FAILURE =
  "GET_TOTAL_FIRMWARE_VERSION_FAILURE";

//TotalAppVersion
export const GET_TOTAL_APP_VERSION_REQUEST = "GET_TOTAL_APP_VERSION_REQUEST";
export const GET_TOTAL_APP_VERSION_SUCCESS = "GET_TOTAL_APP_VERSION_SUCCESS";
export const GET_TOTAL_APP_VERSION_FAILURE = "GET_TOTAL_APP_VERSION_FAILURE";

//NoSignalCountGraph
export const GET_NO_SIGNAL_COUNT_REQUEST = "GET_NO_SIGNAL_COUNT_REQUEST";
export const GET_NO_SIGNAL_COUNT_SUCCESS = "GET_NO_SIGNAL_COUNT_SUCCESS";
export const GET_NO_SIGNAL_COUNT_FAILURE = "GET_NO_SIGNAL_COUNT_FAILURE";

//TotalAgentVersionGraph
export const GET_TOTAL_AGENT_VERSION_REQUEST = "GET_TOTAL_AGENT_VERSION_REQUEST";
export const GET_TOTAL_AGENT_VERSION_SUCCESS = "GET_TOTAL_AGENT_VERSION_SUCCESS";
export const GET_TOTAL_AGENT_VERSION_FAILURE = "GET_TOTAL_AGENT_VERSION_FAILURE";

//Roles List

export const GET_ROLES_DETAILS_REQUEST = "GET_ROLES_DETAILS_REQUEST";
export const GET_ROLES_DETAILS_SUCCESS = "GET_ROLES_DETAILS_SUCCESS";
export const GET_ROLES_DETAILS_FAILURE = "GET_ROLES_DETAILS_FAILURE";

//User Permissions
export const GET_MASTER_PERMISSIONS_REQUEST = "GET_MASTER_PERMISSIONS_REQUEST";
export const GET_MASTER_PERMISSIONS_SUCCESS = "GET_MASTER_PERMISSIONS_SUCCESS";
export const GET_MASTER_PERMISSIONS_FAILURE = "GET_MASTER_PERMISSIONS_FAILURE";

//Average Network Spped
export const GET_AVERAGE_NETWORK_SPEED_REQUEST =
  "GET_AVERAGE_NETWORK_SPEED_REQUEST";
export const GET_AVERAGE_NETWORK_SPEED_SUCCESS =
  "GET_AVERAGE_NETWORK_SPEED_SUCCESS";
export const GET_AVERAGE_NETWORK_SPEED_FAILURE =
  "GET_AVERAGE_NETWORK_SPEED_FAILURE";

//Average Battery Status
export const GET_AVERAGE_BATTERY_STATUS_REQUEST =
  "GET_AVERAGE_BATTERY_STATUS_REQUEST";
export const GET_AVERAGE_BATTERY_STATUS_SUCCESS =
  "GET_AVERAGE_BATTERY_STATUS_SUCCESS";
export const GET_AVERAGE_BATTERY_STATUS_FAILURE =
  "GET_AVERAGE_BATTERY_STATUS_FAILURE";

//RCU key Codes India Level
export const GET_RCU_KEY_CODES_REQUEST = "GET_RCU_KEY_CODES_REQUEST";
export const GET_RCU_KEY_CODES_SUCCESS = "GET_RCU_KEY_CODES_SUCCESS";
export const GET_RCU_KEY_CODES_FAILURE = "GET_RCU_KEY_CODES_FAILURE";

// Get STB Info By SID
export const GET_STB_INFO_BY_SID_REQUEST = "GET_STB_INFO_BY_SID_REQUEST";
export const GET_STB_INFO_BY_SID_SUCCESS = "GET_STB_INFO_BY_SID_SUCCESS";
export const GET_STB_INFO_BY_SID_FAILURE = "GET_STB_INFO_BY_SID_FAILURE";

export const GET_STB_INFO_BY_SID_RESET = "GET_STB_INFO_BY_SID_RESET";
export const STB_INFO_BY_SID_FETCH_NOW_RESET =
  "STB_INFO_BY_SID_FETCH_NOW_RESET";
export const SET_CDSN = "SET_CDSN";

export const ENTITLEMENTS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "ENTITLEMENTS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const ENTITLEMENTS_FETCH_NOW_CONTROL = "ENTITLEMENTS_FETCH_NOW_CONTROL";
export const ENTITLEMENTS_FETCH_NOW_STATUS = "ENTITLEMENTS_FETCH_NOW_STATUS";
export const ENTITLEMENTS_FETCH_NOW_RESET = "ENTITLEMENTS_FETCH_NOW_RESET";

export const ENTITLEMENTS_WEBHOOK_TRIGGERED = "ENTITLEMENTS_WEBHOOK_TRIGGERED";

export const APPS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "APPS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const APPS_FETCH_NOW_CONTROL = "APPS_FETCH_NOW_CONTROL";
export const APPS_FETCH_NOW_STATUS = "APPS_FETCH_NOW_STATUS";
export const APPS_FETCH_NOW_RESET = "APPS_FETCH_NOW_RESET";

export const APPS_PROCESSED_WEBHOOK_TRIGGERED = "APPS_PROCESSED_WEBHOOK_TRIGGERED";

export const BATTERY_STATUS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "BATTERY_STATUS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const BATTERY_STATUS_FETCH_NOW_CONTROL =
  "BATTERY_STATUS_FETCH_NOW_CONTROL";
export const BATTERY_STATUS_FETCH_NOW_STATUS =
  "BATTERY_STATUS_FETCH_NOW_STATUS";
export const BATTERY_STATUS_FETCH_NOW_RESET = "BATTERY_STATUS_FETCH_NOW_RESET";

export const BATTERY_STATUS_PROCESSED_WEBHOOK_TRIGGERED = "BATTERY_STATUS_PROCESSED_WEBHOOK_TRIGGERED";

export const NETWORK_SPEED_FETCH_NOW_CONTROL_TIMER_FLAG =
  "NETWORK_SPEED_FETCH_NOW_CONTROL_TIMER_FLAG";
export const NETWORK_SPEED_FETCH_NOW_CONTROL =
  "NETWORK_SPEED_FETCH_NOW_CONTROL";
export const NETWORK_SPEED_FETCH_NOW_STATUS = "NETWORK_SPEED_FETCH_NOW_STATUS";
export const NETWORK_SPEED_FETCH_NOW_RESET = "NETWORK_SPEED_FETCH_NOW_RESET";

export const NETWORK_SPEED_PROCESSED_WEBHOOK_TRIGGERED = "NETWORK_SPEED_PROCESSED_WEBHOOK_TRIGGERED";

export const NETWORK_CONDITIONS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "NETWORK_CONDITIONS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const NETWORK_CONDITIONS_FETCH_NOW_CONTROL =
  "NETWORK_CONDITIONS_FETCH_NOW_CONTROL";
export const NETWORK_CONDITIONS_FETCH_NOW_STATUS =
  "NETWORK_CONDITIONS_FETCH_NOW_STATUS";
export const NETWORK_CONDITIONS_FETCH_NOW_RESET =
  "NETWORK_CONDITIONS_FETCH_NOW_RESET";

export const NETWORK_CONDITIONS_PROCESSED_WEBHOOK_TRIGGERED = "NETWORK_CONDITIONS_PROCESSED_WEBHOOK_TRIGGERED";

export const BLUETOOTH_DEVICES_FETCH_NOW_CONTROL_TIMER_FLAG =
  "BLUETOOTH_DEVICES_FETCH_NOW_CONTROL_TIMER_FLAG";
export const BLUETOOTH_DEVICES_FETCH_NOW_CONTROL =
  "BLUETOOTH_DEVICES_FETCH_NOW_CONTROL";
export const BLUETOOTH_DEVICES_FETCH_NOW_STATUS =
  "BLUETOOTH_DEVICES_FETCH_NOW_STATUS";
export const BLUETOOTH_DEVICES_FETCH_NOW_RESET =
  "BLUETOOTH_DEVICES_FETCH_NOW_RESET";

export const BLUETOOTH_DEVICES_PROCESSED_WEBHOOK_TRIGGERED = "BLUETOOTH_DEVICES_PROCESSED_WEBHOOK_TRIGGERED";

export const SATELLITE_SIGNAL_FETCH_NOW_CONTROL_TIMER_FLAG =
  "SATELLITE_SIGNAL_FETCH_NOW_CONTROL_TIMER_FLAG";
export const SATELLITE_SIGNAL_FETCH_NOW_CONTROL =
  "SATELLITE_SIGNAL_FETCH_NOW_CONTROL";
export const SATELLITE_SIGNAL_FETCH_NOW_STATUS =
  "SATELLITE_SIGNAL_FETCH_NOW_STATUS";
export const SATELLITE_SIGNAL_FETCH_NOW_RESET =
  "SATELLITE_SIGNAL_FETCH_NOW_RESET";

export const SATELLITE_SIGNAL_PROCESSED_WEBHOOK_TRIGGERED = "SATELLITE_SIGNAL_PROCESSED_WEBHOOK_TRIGGERED";

export const SYSTEM_DETAILS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "SYSTEM_DETAILS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const SYSTEM_DETAILS_FETCH_NOW_CONTROL =
  "SYSTEM_DETAILS_FETCH_NOW_CONTROL";
export const SYSTEM_DETAILS_FETCH_NOW_STATUS =
  "SYSTEM_DETAILS_FETCH_NOW_STATUS";
export const SYSTEM_DETAILS_FETCH_NOW_RESET = "SYSTEM_DETAILS_FETCH_NOW_RESET";

export const SYSTEM_DETAILS_PROCESSED_WEBHOOK_TRIGGERED = "SYSTEM_DETAILS_PROCESSED_WEBHOOK_TRIGGERED";

export const SYSTEM_DETAILS_ADB_CONTROL_TIMER_FLAG = "SYSTEM_DETAILS_ADB_CONTROL_TIMER_FLAG";
export const SYSTEM_DETAILS_ADB_CONTROL = "SYSTEM_DETAILS_ADB_CONTROL";
export const SYSTEM_DETAILS_ADB_STATUS = "SYSTEM_DETAILS_ADB_STATUS";
export const SYSTEM_DETAILS_ADB_RESET = "SYSTEM_DETAILS_ADB_RESET";

export const SYSTEM_DETAILS_ADB_PROCESSED_WEBHOOK_TRIGGERED = "SYSTEM_DETAILS_ADB_PROCESSED_WEBHOOK_TRIGGERED";

export const GLOBAL_CONTROL_STATUS = "GLOBAL_CONTROL_STATUS";

//Battery Graph CDSN
export const GET_BATTERY_GRAPH_CDSN_REQUEST = "GET_BATTERY_GRAPH_CDSN_REQUEST";
export const GET_BATTERY_GRAPH_CDSN_SUCCESS = "GET_BATTERY_GRAPH_CDSN_SUCCESS";
export const GET_BATTERY_GRAPH_CDSN_FAILURE = "GET_BATTERY_GRAPH_CDSN_FAILURE";

//Network Graph CDSN
export const GET_NETWORK_GRAPH_CDSN_REQUEST = "GET_NETWORK_GRAPH_CDSN_REQUEST";
export const GET_NETWORK_GRAPH_CDSN_SUCCESS = "GET_NETWORK_GRAPH_CDSN_SUCCESS";
export const GET_NETWORK_GRAPH_CDSN_FAILURE = "GET_NETWORK_GRAPH_CDSN_FAILURE";

// Durations Graph CDSN
export const GET_APP_BOOT_TIME_REQUEST = "GET_APP_BOOT_TIME_REQUEST";
export const GET_APP_BOOT_TIME_SUCCESS = "GET_APP_BOOT_TIME_SUCCESS";
export const GET_APP_BOOT_TIME_FAILURE = "GET_APP_BOOT_TIME_FAILURE";

export const APP_BOOT_TIME_FETCH_NOW_CONTROL_TIMER_FLAG =
  "APP_BOOT_TIME_FETCH_NOW_CONTROL_TIMER_FLAG";
export const APP_BOOT_TIME_FETCH_NOW_CONTROL =
  "APP_BOOT_TIME_FETCH_NOW_CONTROL";
export const APP_BOOT_TIME_FETCH_NOW_STATUS = "APP_BOOT_TIME_FETCH_NOW_STATUS";
export const APP_BOOT_TIME_FETCH_NOW_RESET = "APP_BOOT_TIME_FETCH_NOW_RESET";

export const APP_BOOT_PROCESSED_WEBHOOK_TRIGGERED = "APP_BOOT_PROCESSED_WEBHOOK_TRIGGERED";

export const GET_VIEWING_TIME_REQUEST = "GET_VIEWING_TIME_REQUEST";
export const GET_VIEWING_TIME_SUCCESS = "GET_VIEWING_TIME_SUCCESS";
export const GET_VIEWING_TIME_FAILURE = "GET_VIEWING_TIME_FAILURE";

export const VIEWING_TIME_FETCH_NOW_CONTROL_TIMER_FLAG =
  "VIEWING_TIME_FETCH_NOW_CONTROL_TIMER_FLAG";
export const VIEWING_TIME_FETCH_NOW_CONTROL = "VIEWING_TIME_FETCH_NOW_CONTROL";
export const VIEWING_TIME_FETCH_NOW_STATUS = "VIEWING_TIME_FETCH_NOW_STATUS";
export const VIEWING_TIME_FETCH_NOW_RESET = "VIEWING_TIME_FETCH_NOW_RESET";

export const VIEWING_TIME_PROCESSED_WEBHOOK_TRIGGERED = "VIEWING_TIME_PROCESSED_WEBHOOK_TRIGGERED";

export const GET_ZAP_TIME_REQUEST = "GET_ZAP_TIME_REQUEST";
export const GET_ZAP_TIME_SUCCESS = "GET_ZAP_TIME_SUCCESS";
export const GET_ZAP_TIME_FAILURE = "GET_ZAP_TIME_FAILURE";

export const ZAP_TIME_FETCH_NOW_CONTROL_TIMER_FLAG =
  "ZAP_TIME_FETCH_NOW_CONTROL_TIMER_FLAG";
export const ZAP_TIME_FETCH_NOW_CONTROL = "ZAP_TIME_FETCH_NOW_CONTROL";
export const ZAP_TIME_FETCH_NOW_STATUS = "ZAP_TIME_FETCH_NOW_STATUS";
export const ZAP_TIME_FETCH_NOW_RESET = "ZAP_TIME_FETCH_NOW_RESET";

export const ZAP_TIME_PROCESSED_WEBHOOK_TRIGGERED = "ZAP_TIME_PROCESSED_WEBHOOK_TRIGGERED";

// System Usage Graph CDSN

export const GET_MEMORY_USAGE_REQUEST = "GET_MEMORY_USAGE_REQUEST";
export const GET_MEMORY_USAGE_SUCCESS = "GET_MEMORY_USAGE_SUCCESS";
export const GET_MEMORY_USAGE_FAILURE = "GET_MEMORY_USAGE_FAILURE";

export const MEMORY_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG =
  "MEMORY_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG";
export const MEMORY_USAGE_FETCH_NOW_CONTROL = "MEMORY_USAGE_FETCH_NOW_CONTROL";
export const MEMORY_USAGE_FETCH_NOW_STATUS = "MEMORY_USAGE_FETCH_NOW_STATUS";
export const MEMORY_USAGE_FETCH_NOW_RESET = "MEMORY_USAGE_FETCH_NOW_RESET";

export const MEMORY_USAGE_PROCESSED_WEBHOOK_TRIGGERED = "MEMORY_USAGE_PROCESSED_WEBHOOK_TRIGGERED";

export const GET_CPU_USAGE_REQUEST = "GET_CPU_USAGE_REQUEST";
export const GET_CPU_USAGE_SUCCESS = "GET_CPU_USAGE_SUCCESS";
export const GET_CPU_USAGE_FAILURE = "GET_CPU_USAGE_FAILURE";

export const CPU_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG =
  "CPU_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG";
export const CPU_USAGE_FETCH_NOW_CONTROL = "CPU_USAGE_FETCH_NOW_CONTROL";
export const CPU_USAGE_FETCH_NOW_STATUS = "CPU_USAGE_FETCH_NOW_STATUS";
export const CPU_USAGE_FETCH_NOW_RESET = "CPU_USAGE_FETCH_NOW_RESET";

export const CPU_USAGE_PROCESSED_WEBHOOK_TRIGGERED = "CPU_USAGE_PROCESSED_WEBHOOK_TRIGGERED";

// Durations Graph CDSN
export const GET_APP_BOOT_COUNT_REQUEST = "GET_APP_BOOT_COUNT_REQUEST";
export const GET_APP_BOOT_COUNT_SUCCESS = "GET_APP_BOOT_COUNT_SUCCESS";
export const GET_APP_BOOT_COUNT_FAILURE = "GET_APP_BOOT_COUNT_FAILURE";

// Exception tab CDSN
export const GET_EXCEPTION_REQUEST = "GET_EXCEPTION_REQUEST";
export const GET_EXCEPTION_SUCCESS = "GET_EXCEPTION_SUCCESS";
export const GET_EXCEPTION_FAILURE = "GET_EXCEPTION_FAILURE";

// Device Storage tab CDSN
export const GET_DEVICE_STORAGE_REQUEST = "GET_DEVICE_STORAGE_REQUEST";
export const GET_DEVICE_STORAGE_SUCCESS = "GET_DEVICE_STORAGE_SUCCESS";
export const GET_DEVICE_STORAGE_FAILURE = "GET_DEVICE_STORAGE_FAILURE";

export const DEVICE_STORAGE_FETCH_NOW_CONTROL_TIMER_FLAG =
  "DEVICE_STORAGE_FETCH_NOW_CONTROL_TIMER_FLAG";
export const DEVICE_STORAGE_FETCH_NOW_CONTROL = "DEVICE_STORAGE_FETCH_NOW_CONTROL";
export const DEVICE_STORAGE_FETCH_NOW_STATUS = "DEVICE_STORAGE_FETCH_NOW_STATUS";
export const DEVICE_STORAGE_FETCH_NOW_RESET = "DEVICE_STORAGE_FETCH_NOW_RESET";

export const DEVICE_STORAGE_PROCESSED_WEBHOOK_TRIGGERED = "DEVICE_STORAGE_PROCESSED_WEBHOOK_TRIGGERED";

//RCU key codes at STB Level
export const GET_RCU_KEY_CODES_STB_REQUEST = "GET_RCU_KEY_CODES_STB_REQUEST";
export const GET_RCU_KEY_CODES_STB_SUCCESS = "GET_RCU_KEY_CODES_STB_SUCCESS";
export const GET_RCU_KEY_CODES_STB_FAILURE = "GET_RCU_KEY_CODES_STB_FAILURE";
export const RCU_KEY_CODES_STB_RESET = "RCU_KEY_CODES_STB_RESET";

//TOTAL_DEVICES_ACROSS_INDIA
export const GET_TOTAL_DEVICES_ACROSS_INDIA_REQUEST =
  "GET_TOTAL_DEVICES_ACROSS_INDIA_REQUEST";
export const GET_TOTAL_DEVICES_ACROSS_INDIA_SUCCESS =
  "GET_TOTAL_DEVICES_ACROSS_INDIA_SUCCESS";
export const GET_TOTAL_DEVICES_ACROSS_INDIA_FAILURE =
  "GET_BTOTAL_DEVICES_ACROSS_INDIA_FAILURE";

//Network Availabilty Graph
export const GET_NETWORK_AVAILABILTY_GRAPH_CDSN_REQUEST =
  "GET_NETWORK_AVAILABILTY_GRAPH_CDSN_REQUEST";
export const GET_NETWORK_AVAILABILTY_GRAPH_CDSN_SUCCESS =
  "GET_NETWORK_AVAILABILTY_GRAPH_CDSN_SUCCESS";
export const GET_NETWORK_AVAILABILTY_GRAPH_CDSN_FAILURE =
  "GET_NETWORK_AVAILABILTY_GRAPH_CDSN_FAILURE";

//Entitalement
export const GET_ENTITLEMENT_REQUEST = "GET_ENTITLEMENT_REQUEST";
export const GET_ENTITLEMENT_SUCCESS_DTH = "GET_ENTITLEMENT_SUCCESS_DTH";
export const GET_ENTITLEMENT_SUCCESS_BINGE = "GET_ENTITLEMENT_SUCCESS_BINGE";
export const GET_ENTITLEMENT_SUCCESS_OTT = "GET_ENTITLEMENT_SUCCESS_OTT";
export const GET_ENTITLEMENT_FAILURE = "GET_ENTITLEMENT_FAILURE";

//Entitlement For Accedo
export const GET_ENTITLEMENTS_ACCEDO_REQUEST = "GET_ENTITLEMENT_ACCEDO_REQUEST";
export const GET_ENTITLEMENTS_ACCEDO_SUCCESS = "GET_ENTITLEMENT_ACCEDO_SUCCESS";
export const GET_ENTITLEMENTS_ACCEDO_FAILURE = "GET_ENTITLEMENT_ACCEDO_FAILURE";

//Installed Apps
export const GET_APPS_REQUEST = "GET_APPS_REQUEST";
export const GET_APPS_SUCCESS = "GET_APPS_SUCCESS";
export const GET_APPS_FAILURE = "GET_APPS_FAILURE";

//Network Speed
export const GET_NETWORK_SPEED_REQUEST = "GET_NETWORK_SPEED_REQUEST";
export const GET_NETWORK_SPEED_SUCCESS = "GET_NETWORK_SPEED_SUCCESS";
export const GET_NETWORK_SPEED_FAILURE = "GET_NETWORK_SPEED_FAILURE";

//Network Conditions
export const GET_NETWORK_CONDITIONS_REQUEST = "GET_NETWORK_CONDITIONS_REQUEST";
export const GET_NETWORK_CONDITIONS_SUCCESS = "GET_NETWORK_CONDITIONS_SUCCESS";
export const GET_NETWORK_CONDITIONS_FAILURE = "GET_NETWORK_CONDITIONS_FAILURE";

//Satellite Signal
export const GET_SATELLITE_SIGNAL_REQUEST = "GET_SATELLITE_SIGNAL_REQUEST";
export const GET_SATELLITE_SIGNAL_SUCCESS = "GET_SATELLITE_SIGNAL_SUCCESS";
export const GET_SATELLITE_SIGNAL_FAILURE = "GET_SATELLITE_SIGNAL_FAILURE";

//System details
export const GET_SYSTEM_DETAILS_REQUEST = "GET_SYSTEM_DETAILS_REQUEST";
export const GET_SYSTEM_DETAILS_SUCCESS = "GET_SYSTEM_DETAILS_SUCCESS";
export const GET_SYSTEM_DETAILS_FAILURE = "GET_SYSTEM_DETAILS_FAILURE";

//Battery Status
export const GET_BATTERY_STATUS_REQUEST = "GET_BATTERY_STATUS_REQUEST";
export const GET_BATTERY_STATUS_SUCCESS = "GET_BATTERY_STATUS_SUCCESS";
export const GET_BATTERY_STATUS_FAILURE = "GET_BATTERY_STATUS_FAILURE";

//Bluetooth Devices
export const GET_BLUETOOTH_DEVICES_REQUEST = "GET_BLUETOOTH_DEVICES_REQUEST";
export const GET_BLUETOOTH_DEVICES_SUCCESS = "GET_BLUETOOTH_DEVICES_SUCCESS";
export const GET_BLUETOOTH_DEVICES_FAILURE = "GET_BLUETOOTH_DEVICES_FAILURE";

//Android settings
export const GET_ANDROID_SETTINGS_REQUEST = "GET_ANDROID_SETTINGS_REQUEST";
export const GET_ANDROID_SETTINGS_SUCCESS = "GET_ANDROID_SETTINGS_SUCCESS";
export const GET_ANDROID_SETTINGS_FAILURE = "GET_ANDROID_SETTINGS_FAILURE";

export const ANDROID_SETTINGS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "ANDROID_SETTINGS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const ANDROID_SETTINGS_FETCH_NOW_CONTROL =
  "ANDROID_SETTINGS_FETCH_NOW_CONTROL";
export const ANDROID_SETTINGS_FETCH_NOW_STATUS =
  "ANDROID_SETTINGS_FETCH_NOW_STATUS";

export const ANDROID_SETTINGS_FETCH_NOW_RESET =
  "ANDROID_SETTINGS_FETCH_NOW_RESET";

export const ANDROID_SETTINGS_PROCESSED_WEBHOOK_TRIGGERED = "ANDROID_SETTINGS_PROCESSED_WEBHOOK_TRIGGERED";

//TifMonitoring
export const GET_ANDROID_TIFMONITORING_REQUEST =
  "GET_ANDROID_TIFMONITORING_REQUEST";
export const GET_ANDROID_TIFMONITORING_SUCCESS =
  "GET_ANDROID_TIFMONITORING_SUCCESS";
export const GET_ANDROID_TIFMONITORING_FAILURE =
  "GET_ANDROID_TIFMONITORING_FAILURE";

export const POST_ANDROID_TIFMONITORING_CONTROL =
  "POST_ANDROID_TIFMONITORING_CONTROL";
export const GET_ANDROID_TIFMONITORING_CONTROL_STATUS =
  "GET_ANDROID_TIFMONITORING_CONTROL_STATUS";

export const REBUILD_EPG_CACHE_TIMER_FLAG = "REBUILD_EPG_CACHE_TIMER_FLAG";
export const RESET_REBUILD_EPG_CACHE_BUTTON = "RESET_REBUILD_EPG_CACHE_BUTTON";
export const TIFMONITORING_REBUILD_EPG_CACHE_FAILURE =
  "TIFMONITORING_REBUILD_EPG_CACHE_FAILURE";

export const TIF_MONITORING_FETCH_NOW_CONTROL_TIMER_FLAG =
  "TIF_MONITORING_FETCH_NOW_CONTROL_TIMER_FLAG";
export const TIF_MONITORING_FETCH_NOW_CONTROL =
  "TIF_MONITORING_FETCH_NOW_CONTROL";
export const TIF_MONITORING_FETCH_NOW_STATUS =
  "TIF_MONITORING_FETCH_NOW_STATUS";
export const TIF_MONITORING_FETCH_NOW_RESET = "TIF_MONITORING_FETCH_NOW_RESET";

export const TIF_MONITORING_PROCESSED_WEBHOOK_TRIGGERED = "TIF_MONITORING_PROCESSED_WEBHOOK_TRIGGERED";

//Satellite scan
export const GET_SATELLITE_SCAN_REQUEST = "GET_SATELLITE_SCAN_REQUEST";
export const GET_SATELLITE_SCAN_SUCCESS = "GET_SATELLITE_SCAN_SUCCESS";
export const GET_SATELLITE_SCAN_FAILURE = "GET_SATELLITE_SCAN_FAILURE";
export const POST_SATELLITE_SCAN_TIMER_FLAG = "POST_SATELLITE_SCAN_TIMER_FLAG";

export const SATELLITE_SCAN_FETCH_NOW_CONTROL_TIMER_FLAG =
  "SATELLITE_SCAN_FETCH_NOW_CONTROL_TIMER_FLAG";
export const SATELLITE_SCAN_FETCH_NOW_CONTROL =
  "SATELLITE_SCAN_FETCH_NOW_CONTROL";
export const SATELLITE_SCAN_FETCH_NOW_STATUS =
  "SATELLITE_SCAN_FETCH_NOW_STATUS";

export const POST_SATELLITE_SCAN_SAVE_FAILURE =
  "POST_SATELLITE_SCAN_SAVE_FAILURE";

//Satellite setting post
export const POST_SATELLITE_SCAN_REQUEST = "POST_SATELLITE_SCAN_REQUEST";
export const POST_SATELLITE_SCAN_SUCCESS = "POST_SATELLITE_SCAN_SUCCESS";
export const POST_SATELLITE_SCAN_FAILURE = "POST_SATELLITE_SCAN_FAILURE";
export const POST_SATELLITE_SCAN_SUCCESS_CONTROL_STATUS =
  "POST_SATELLITE_SCAN_SUCCESS_CONTROL_STATUS";
export const SATELLITE_SCAN_SAVE_SETTINGS_RESET =
  "SATELLITE_SCAN_SAVE_SETTINGS_RESET";

//FEtch now reset
export const GET_FETCH_NOW_STATUS_RESET = "GET_FETCH_NOW_STATUS_RESET";

//My Records
export const GET_MY_RECORDS_REQUEST = "GET_MY_RECORDS_REQUEST";
export const GET_MY_RECORDS_SUCCESS = "GET_MY_RECORDS_SUCCESS";
export const GET_MY_RECORDS_FAILURE = "GET_MY_RECORDS_FAILURE";

export const MY_RECORDS_FETCH_NOW_CONTROL_TIMER_FLAG =
  "MY_RECORDS_FETCH_NOW_CONTROL_TIMER_FLAG";
export const MY_RECORDS_FETCH_NOW_CONTROL = "MY_RECORDS_FETCH_NOW_CONTROL";
export const MY_RECORDS_FETCH_NOW_STATUS = "MY_RECORDS_FETCH_NOW_STATUS";

export const MY_RECORDS_FETCH_NOW_RESET = "MY_RECORDS_FETCH_NOW_RESET";

export const MY_RECORDS_PROCESSED_WEBHOOK_TRIGGERED = "MY_RECORDS_PROCESSED_WEBHOOK_TRIGGERED";

//Fetch now
export const GET_FETCH_NOW_REQUEST = "GET_FETCH_NOW_REQUEST";
export const GET_FETCH_NOW_SUCCESS = "GET_FETCH_NOW_SUCCESS";
export const GET_FETCH_NOW_FAILURE = "GET_FETCH_NOW_FAILURE";
export const GET_FETCH_NOW_ENTITLEMENTS_RESPONSE =
  "GET_FETCH_NOW_ENTITLEMENTS_RESPONSE";
export const GET_FETCH_NOW_APP_RESPONSE = "GET_FETCH_NOW_APP_RESPONSE";
export const GET_FETCH_NOW_NETWORK_CONDITION_RESPONSE =
  "GET_FETCH_NOW_NETWORK_CONDITION_RESPONSE";
export const GET_FETCH_NOW_NETWORK_SPEED_RESPONSE =
  "GET_FETCH_NOW_NETWORK_SPEED_RESPONSE";
export const GET_FETCH_NOW_BATTERY_RESPONSE = "GET_FETCH_NOW_BATTERY_RESPONSE";
export const GET_FETCH_NOW_SATELLITE_SIGNAL_RESPONSE =
  "GET_FETCH_NOW_SATELLITE_SIGNAL_RESPONSE";
export const GET_FETCH_NOW_BLUETOOTH_RESPONSE =
  "GET_FETCH_NOW_BLUETOOTH_RESPONSE";
export const GET_FETCH_NOW_ANDORID_SETTINGS_RESPONSE =
  "GET_FETCH_NOW_ANDORID_SETTINGS_RESPONSE";
export const GET_FETCH_NOW_SYSTEM_DETAILS_RESPONSE =
  "GET_FETCH_NOW_SYSTEM_DETAILS_RESPONSE";
export const GET_FETCH_NOW_MY_RECORDS_RESPONSE =
  "GET_FETCH_NOW_MY_RECORDS_RESPONSE";
export const GET_FETCH_NOW_MY_TIF_MONITORING_RESPONSE =
  "GET_FETCH_NOW_MY_TIF_MONITORING_RESPONSE";

//Fetch now Status
export const GET_FETCH_NOW_STATUS_REQUEST = "GET_FETCH_NOW_STATUS_REQUEST";
export const GET_FETCH_NOW_STATUS_SUCCESS = "GET_FETCH_NOW_STATUS_SUCCESS";
export const GET_FETCH_NOW_STATUS_FAILURE = "GET_FETCH_NOW_STATUS_FAILURE";
export const GET_FETCH_NOW_STATUS_ENTITLEMENTS =
  "GET_FETCH_NOW_STATUS_ENTITLEMENTS";
export const GET_FETCH_NOW_STATUS_APPS = "GET_FETCH_NOW_STATUS_APPS";
export const GET_FETCH_NOW_STATUS_NETWORK_CONDITION =
  "GET_FETCH_NOW_STATUS_NETWORK_CONDITION";
export const GET_FETCH_NOW_STATUS_NETWORK_SPEED =
  "GET_FETCH_NOW_STATUS_NETWORK_SPEED";
export const GET_FETCH_NOW_STATUS_BATTERY = "GET_FETCH_NOW_STATUS_BATTERY";
export const GET_FETCH_NOW_STATUS_SATELLITE_SIGNAL =
  "GET_FETCH_NOW_STATUS_SATELLITE_SIGNAL";
export const GET_FETCH_NOW_STATUS_BLUETOOTH = "GET_FETCH_NOW_STATUS_BLUETOOTH";
export const GET_FETCH_NOW_STATUS_ANDORID_SETTINGS =
  "GET_FETCH_NOW_STATUS_ANDORID_SETTINGS";
export const GET_FETCH_NOW_STATUS_SYSTEM_DETAILS =
  "GET_FETCH_NOW_STATUS_SYSTEM_DETAILS";
export const GET_FETCH_NOW_STATUS_MY_RECORDS =
  "GET_FETCH_NOW_STATUS_MY_RECORDS";
export const GET_FETCH_NOW_STATUS_TIF_MONITORING =
  "GET_FETCH_NOW_STATUS_TIF_MONITORING";

//Fetch now Tabwise

export const GET_FETCH_NOW_ENTITLEMENTS = "GET_FETCH_NOW_ENTITLEMENTS";
export const GET_FETCH_NOW_APP = "GET_FETCH_NOW_APP";
export const GET_FETCH_NOW_BATTERY = "GET_FETCH_NOW_BATTERY";
export const GET_FETCH_NOW_NETWORK_CONDITION =
  "GET_FETCH_NOW_NETWORK_CONDITION";
export const GET_FETCH_NOW_NETWORK_SPEED = "GET_FETCH_NOW_NETWORK_SPEED";
export const GET_FETCH_NOW_SATTELITE = "GET_FETCH_NOW_SATTELITE";
export const GET_FETCH_NOW_BLUETOOTH = "GET_FETCH_NOW_BLUETOOTH";
export const GET_FETCH_NOW_ANDROID = "GET_FETCH_NOW_ANDROID";
export const GET_FETCH_NOW_SYSTEM_DETAILS = "GET_FETCH_NOW_SYSTEM_DETAILS";
export const GET_FETCH_NOW_RECORDS = "GET_FETCH_NOW_RECORDS";
export const GET_FETCH_NOW_TIF_MONITORING = "GET_FETCH_NOW_TIF_MONITORING";
export const GET_FETCH_NOW_ENTITLEMENTS_TIMER =
  "GET_FETCH_NOW_ENTITLEMENTS_TIMER";
export const GET_FETCH_NOW_APPS_TIMER = "GET_FETCH_NOW_APPS_TIMER";
export const GET_FETCH_NOW_NETWORK_CONDITION_TIMER =
  "GET_FETCH_NOW_NETWORK_CONDITION_TIMER";
export const GET_FETCH_NOW_NETWORK_SPEED_TIMER =
  "GET_FETCH_NOW_NETWORK_SPEED_TIMER";
export const GET_FETCH_NOW_BATTERY_TIMER = "GET_FETCH_NOW_BATTERY_TIMER";
export const GET_FETCH_NOW_SATELLITE_SIGNAL_TIMER =
  "GET_FETCH_NOW_SATELLITE_SIGNAL_TIMER";
export const GET_FETCH_NOW_BLUETOOTH_TIMER = "GET_FETCH_NOW_BLUETOOTH_TIMER";
export const GET_FETCH_NOW_ANDORID_SETTINGS_TIMER =
  "GET_FETCH_NOW_ANDORID_SETTINGS_TIMER";
export const GET_FETCH_NOW_SYSTEM_DETAILS_TIMER =
  "GET_FETCH_NOW_SYSTEM_DETAILS_TIMER";
export const GET_FETCH_NOW_MY_RECORDS_TIMER = "GET_FETCH_NOW_MY_RECORDS_TIMER";
export const GET_FETCH_NOW_TIF_MONITORING_TIMER =
  "GET_FETCH_NOW_TIF_MONITORING_TIMER";

//Control Events

//Reboot Stb
export const POST_CONTROL_EVENT_CONTROL = "POST_CONTROL_EVENT_CONTROL";
export const GET_POST_CONTROL_EVENT_CONTROL_STATUS =
  "GET_POST_CONTROL_EVENT_CONTROL_STATUS";

export const CONTROL_EVENT_TIMER_FLAG = "CONTROL_EVENT_TIMER_FLAG";
export const RESET_CONTROL_EVENT_BUTTON = "RESET_CONTROL_EVENT_BUTTON";

export const POST_REBOOT_STB_FAILURE = "POST_REBOOT_STB_FAILURE";

//Factory Reset
export const POST_FACTORY_RESET_CONTROL = "POST_FACTORY_RESET_CONTROL";
export const GET_POST_FACTORY_RESET_CONTROL_STATUS =
  "GET_POST_FACTORY_RESET_CONTROL_STATUS";

export const FACTORY_RESET_TIMER_FLAG = "FACTORY_RESET_TIMER_FLAG";
export const RESET_FACTORY_RESET_BUTTON = "RESET_FACTORY_RESET_BUTTON";

export const POST_FACTORY_RESET_STB_FAILURE = "POST_FACTORY_RESET_STB_FAILURE";

//Laucher App Kill
export const POST_APP_KILL_CONTROL = "POST_APP_KILL_CONTROL";
export const GET_POST_APP_KILL_CONTROL_STATUS =
  "GET_POST_APP_KILL_CONTROL_STATUS";

export const APP_KILL_TIMER_FLAG = "APP_KILL_TIMER_FLAG";
export const RESET_APP_KILL_BUTTON = "RESET_APP_KILL_BUTTON";

export const POST_APP_KILL_STB_FAILURE = "POST_APP_KILL_STB_FAILURE";

//Laucher App Data
export const POST_APP_DATA_CONTROL = "POST_APP_DATA_CONTROL";
export const GET_POST_APP_DATA_CONTROL_STATUS =
  "GET_POST_APP_DATA_CONTROL_STATUS";

export const APP_DATA_TIMER_FLAG = "APP_DATA_TIMER_FLAG";
export const RESET_APP_DATA_BUTTON = "RESET_APP_DATA_BUTTON";

export const POST_APP_CLEAR_DATA_FAILURE = "POST_APP_CLEAR_DATA_FAILURE";

//Laucher App Cache
export const POST_APP_CACHE_CONTROL = "POST_APP_CACHE_CONTROL";
export const GET_POST_APP_CACHE_CONTROL_STATUS =
  "GET_POST_APP_CACHE_CONTROL_STATUS";

export const APP_CACHE_TIMER_FLAG = "APP_CACHE_TIMER_FLAG";
export const RESET_APP_CACHE_BUTTON = "RESET_APP_CACHE_BUTTON";

export const POST_APP_CLEAR_CACHE_FAILURE = "POST_APP_CLEAR_CACHE_FAILURE";

// Screenshot Capture
export const POST_CAPTURE_SCREEN_SHOT_CONTROL =
  "POST_CAPTURE_SCREEN_SHOT_CONTROL";
export const POST_CAPTURE_SCREEN_SHOT_CONTROL_STATUS =
  "POST_CAPTURE_SCREEN_SHOT_CONTROL_STATUS";
export const CAPTURE_SCREEN_SHOT_TIMER_FLAG = "CAPTURE_SCREEN_SHOT_TIMER_FLAG";
// export const RESET_CAPTURE_SCREEN_SHOT_BUTTON = "RESET_CAPTURE_SCREEN_SHOT_BUTTON";
export const POST_CAPTURE_SCREEN_SHOT_FAILURE =
  "POST_CAPTURE_SCREEN_SHOT_FAILURE";

// Set Parental Pin
export const POST_RESET_PARENTAL_PIN_CONTROL =
  "POST_RESET_PARENTAL_PIN_CONTROL";
export const POST_RESET_PARENTAL_PIN_CONTROL_STATUS =
  "POST_RESET_PARENTAL_PIN_CONTROL_STATUS";
export const RESET_PARENTAL_PIN_TIMER_FLAG = "RESET_PARENTAL_PIN_TIMER_FLAG";
// export const RESET_RESET_PARENTAL_PIN_BUTTON = "RESET_RESET_PARENTAL_PIN_BUTTON";
export const POST_RESET_PARENTAL_PIN_FAILURE =
  "POST_RESET_PARENTAL_PIN_FAILURE";

//Generate logs
export const POST_GENERATE_LOGS_CONTROL = "POST_GENERATE_LOGS_CONTROL";
export const GET_POST_APP_GENERATE_LOGS_STATUS =
  "GET_POST_APP_GENERATE_LOGS_STATUS";

export const GENERATE_LOGS_TIMER_FLAG = "GENERATE_LOGS_TIMER_FLAG";
export const RESET_GENERATE_LOGS_BUTTON = "RESET_GENERATE_LOGS_BUTTON";

export const POST_APP_GENERATE_LOGS_FAILURE = "POST_APP_GENERATE_LOGS_FAILURE";

//Signal strength & quality
export const POST_SIGNAL_STRENGTH_QUALITY_CONTROL =
  "POST_SIGNAL_STRENGTH_QUALITY_CONTROL";
export const GET_SIGNAL_STRENGTH_QUALITY_STATUS =
  "GET_SIGNAL_STRENGTH_QUALITY_STATUS";

export const SIGNAL_STRENGTH_QUALITY_TIMER_FLAG =
  "SIGNAL_STRENGTH_QUALITY_TIMER_FLAG";
export const RESET_SIGNAL_STRENGTH_QUALITY_BUTTON =
  "RESET_SIGNAL_STRENGTH_QUALITY_BUTTON";

export const POST_SIGNAL_STRENGTH_QUALITY_FAILURE =
  "POST_SIGNAL_STRENGTH_QUALITY_FAILURE";

//Signal strength & quality
export const POST_PLAY_CONTENT_CONTROL = "POST_PLAY_CONTENT_CONTROL";
export const GET_PLAY_CONTENT_STATUS = "GET_PLAY_CONTENT_STATUS";

export const PLAY_CONTENT_TIMER_FLAG = "PLAY_CONTENT_TIMER_FLAG";
export const RESET_PLAY_CONTENT_BUTTON = "RESET_PLAY_CONTENT_BUTTON";

export const POST_PLAY_CONTENT_FAILURE = "POST_PLAY_CONTENT_FAILURE";

//Uninstall Apps
export const POST_UNINSTALL_APPS_CONTROL = "POST_UNINSTALL_APPS_CONTROL";
export const GET_UNINSTALL_APPS_STATUS = "GET_UNINSTALL_APPS_STATUS";

export const UNINSTALL_APPS_TIMER_FLAG = "UNINSTALL_APPS_TIMER_FLAG";
export const RESET_UNINSTALL_APPS_BUTTON = "RESET_UNINSTALL_APPS_BUTTON";

export const POST_UNINSTALL_APPS_FAILURE = "POST_UNINSTALL_APPS_FAILURE";

//kILL Apps
export const POST_KILL_APPS_CONTROL = "POST_KILL_APPS_CONTROL";
export const GET_KILL_APPS_STATUS = "GET_KILL_APPS_STATUS";

export const KILL_APPS_TIMER_FLAG = "KILL_APPS_TIMER_FLAG";
export const RESET_KILL_APPS_BUTTON = "RESET_KILL_APPS_BUTTON";

export const POST_KILL_APPS_FAILURE = "POST_KILL_APPS_FAILURE";

//Control History Action Types
export const GET_CONTROL_ACTION_REQUEST = "GET_CONTROL_ACTION_REQUEST";
export const GET_CONTROL_ACTION_SUCCESS = "GET_CONTROL_ACTION_SUCCESS";
export const GET_CONTROL_ACTION_FAILURE = "GET_CONTROL_ACTION_FAILURE";

//India level system errors
export const GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_REQUEST =
  "GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_REQUEST";
export const GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_SUCCESS =
  "GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_SUCCESS";
export const GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_FAILURE =
  "GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_FAILURE";

export const GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_REQUEST =
  "GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_REQUEST";
export const GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_SUCCESS =
  "GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_SUCCESS";
export const GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_FAILURE =
  "GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_FAILURE";

export const GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_REQUEST =
  "GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_REQUEST";
export const GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_SUCCESS =
  "GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_SUCCESS";
export const GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_FAILURE =
  "GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_FAILURE";

//STB level system errors
export const GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_REQUEST =
  "GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_REQUEST";
export const GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_SUCCESS =
  "GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_SUCCESS";
export const GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_FAILURE =
  "GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_FAILURE";

//TV Resolutions
export const GET_TV_RESOULTIONS_REQUEST = "GET_TV_RESOULTIONS_REQUEST";
export const GET_TV_RESOULTIONS_SUCCESS = "GET_TV_RESOULTIONS_SUCCESS";
export const GET_TV_RESOULTIONS_FAILURE = "GET_TV_RESOULTIONS_FAILURE";

//Blocked apps
export const GET_BLOCKED_APPS_REQUEST = "GET_BLOCKED_APPS_REQUEST";
export const GET_BLOCKED_APPS_SUCCESS = "GET_BLOCKED_APPS_SUCCESS";
export const GET_BLOCKED_APPS_FAILURE = "GET_BLOCKED_APPS_FAILURE";

//Remote Wizard List Global Level
export const GET_REMOTE_WIZARD_GLOBAL_LEVEL_REQUEST =
  "GET_REMOTE_WIZARD_GLOBAL_LEVEL_REQUEST";
export const GET_REMOTE_WIZARD_GLOBAL_LEVEL_SUCCESS =
  "GET_REMOTE_WIZARD_GLOBAL_LEVEL_SUCCESS";
export const GET_REMOTE_WIZARD_GLOBAL_LEVEL_FAILURE =
  "GET_REMOTE_WIZARD_GLOBAL_LEVEL_FAILURE";

//Wizard At SID level
export const WIZARD_CONTROL_REQUEST_RECEIVED =
  "WIZARD_CONTROL_REQUEST_RECEIVED";
export const WIZARD_CONTROL_REQUEST_PROCESSED =
  "WIZARD_CONTROL_REQUEST_PROCESSED";
