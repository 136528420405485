import * as ACTION from "../actions/actionTypes";
import { UpdateObject } from "../../utils/utils";

const initialState = {
  loading: false,
  errorMsg: "",
  data: [],
  selectedCDSNData: [],

  entitlementFetchNowControl: [],
  entitlementFetchNowControlStatus: [],
  entitlementFetchNowTime: null,
  entitlementFetchNowTimerFlag: false,

  appsFetchNowControl: [],
  appsFetchNowControlStatus: [],
  appsFetchNowTime: null,
  appsFetchNowTimerFlag: false,

  batteryStatusFetchNowControl: [],
  batteryStatusFetchNowControlStatus: [],
  batteryStatusFetchNowTime: null,
  batteryStatusFetchNowTimerFlag: false,

  networkSpeedFetchNowControl: [],
  networkSpeedFetchNowControlStatus: [],
  networkSpeedFetchNowTime: null,
  networkSpeedFetchNowTimerFlag: false,

  networkConditionsFetchNowControl: [],
  networkConditionsFetchNowControlStatus: [],
  networkConditionsFetchNowTime: null,
  networkConditionsFetchNowTimerFlag: false,

  bluetoothDevicesFetchNowControl: [],
  bluetoothDevicesFetchNowControlStatus: [],
  bluetoothDevicesFetchNowTime: null,
  bluetoothDevicesFetchNowTimerFlag: false,

  satelliteSignalFetchNowControl: [],
  satelliteSignalFetchNowControlStatus: [],
  satelliteSignalFetchNowTime: null,
  satelliteSignalFetchNowTimerFlag: false,

  systemDetailsFetchNowControl: [],
  systemDetailsFetchNowControlStatus: [],
  systemDetailsFetchNowTime: null,
  systemDetailsFetchNowTimerFlag: false,

  globalControlStatus: [],

  // calendarData:[]
};

const StbInfoBySidReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_STB_INFO_BY_SID_REQUEST:
      return getStbInfoBySidRequest(state, action);
    case ACTION.GET_STB_INFO_BY_SID_SUCCESS:
      return getStbInfoBySidSuccess(state, action);
    case ACTION.GET_STB_INFO_BY_SID_FAILURE:
      return getStbInfoBySidFailure(state, action);

    case ACTION.GET_STB_INFO_BY_SID_RESET:
      return getStbInfoBySidReset(state, action);
    case ACTION.STB_INFO_BY_SID_FETCH_NOW_RESET:
      return getStbInfoBySidFetchNowReset(state, action);
    case ACTION.SET_CDSN:
      return setCdsn(state, action);

    case ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL:
      return entitlementFetchNowControl(state, action);
    case ACTION.ENTITLEMENTS_FETCH_NOW_STATUS:
      return entitlementFetchNowCotrolStatus(state, action);
    case ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL_TIMER_FLAG:
      return entitlementFetchNowTimerFlag(state, action);

    case ACTION.APPS_FETCH_NOW_CONTROL:
      return appsFetchNowControl(state, action);
    case ACTION.APPS_FETCH_NOW_STATUS:
      return appsFetchNowCotrolStatus(state, action);
    case ACTION.APPS_FETCH_NOW_CONTROL_TIMER_FLAG:
      return appsFetchNowTimerFlag(state, action);

    case ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL:
      return batteryStatusFetchNowControl(state, action);
    case ACTION.BATTERY_STATUS_FETCH_NOW_STATUS:
      return batteryStatusFetchNowCotrolStatus(state, action);
    case ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL_TIMER_FLAG:
      return batteryStatusFetchNowTimerFlag(state, action);

    case ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL:
      return networkSpeedFetchNowControl(state, action);
    case ACTION.NETWORK_SPEED_FETCH_NOW_STATUS:
      return networkSpeedFetchNowCotrolStatus(state, action);
    case ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL_TIMER_FLAG:
      return networkSpeedFetchNowTimerFlag(state, action);

    case ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL:
      return networkConditionsFetchNowControl(state, action);
    case ACTION.NETWORK_CONDITIONS_FETCH_NOW_STATUS:
      return networkConditionsFetchNowCotrolStatus(state, action);
    case ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL_TIMER_FLAG:
      return networkConditionsFetchNowTimerFlag(state, action);

    case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_CONTROL:
      return bluetoothDevicesFetchNowControl(state, action);
    case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_STATUS:
      return bluetoothDevicesFetchNowCotrolStatus(state, action);
    case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_CONTROL_TIMER_FLAG:
      return bluetoothDevicesFetchNowTimerFlag(state, action);

    case ACTION.SATELLITE_SIGNAL_FETCH_NOW_CONTROL:
      return satelliteSignalFetchNowControl(state, action);
    case ACTION.SATELLITE_SIGNAL_FETCH_NOW_STATUS:
      return satelliteSignalFetchNowCotrolStatus(state, action);
    case ACTION.SATELLITE_SIGNAL_FETCH_NOW_CONTROL_TIMER_FLAG:
      return satelliteSignalFetchNowTimerFlag(state, action);

    case ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL:
      return systemDetailsFetchNowControl(state, action);
    case ACTION.SYSTEM_DETAILS_FETCH_NOW_STATUS:
      return systemDetailsFetchNowCotrolStatus(state, action);
    case ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL_TIMER_FLAG:
      return systemDetailsFetchNowTimerFlag(state, action);

    case ACTION.GLOBAL_CONTROL_STATUS:
      return globalControlStatus(state, action);
    default:
      return state;
  }
};

const globalControlStatus = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    globalControlStatus: action.payload,
  });
};

const getStbInfoBySidRequest = (state, action) => {
  return UpdateObject(state, {
    loading: true,
  });
};

const getStbInfoBySidReset = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    errorMsg: "",
    data: [],
    selectedCDSNData: [],

    entitlementFetchNowControl: [],
    entitlementFetchNowControlStatus: [],
    entitlementFetchNowTime: null,
    entitlementFetchNowTimerFlag: false,

    appsFetchNowControl: [],
    appsFetchNowControlStatus: [],
    appsFetchNowTime: null,
    appsFetchNowTimerFlag: false,

    batteryStatusFetchNowControl: [],
    batteryStatusFetchNowControlStatus: [],
    batteryStatusFetchNowTime: null,
    batteryStatusFetchNowTimerFlag: false,

    networkSpeedFetchNowControl: [],
    networkSpeedFetchNowControlStatus: [],
    networkSpeedFetchNowTime: null,
    networkSpeedFetchNowTimerFlag: false,

    networkConditionsFetchNowControl: [],
    networkConditionsFetchNowControlStatus: [],
    networkConditionsFetchNowTime: null,
    networkConditionsFetchNowTimerFlag: false,

    bluetoothDevicesFetchNowControl: [],
    bluetoothDevicesFetchNowControlStatus: [],
    bluetoothDevicesFetchNowTime: null,
    bluetoothDevicesFetchNowTimerFlag: false,

    satelliteSignalFetchNowControl: [],
    satelliteSignalFetchNowControlStatus: [],
    satelliteSignalFetchNowTime: null,
    satelliteSignalFetchNowTimerFlag: false,

    systemDetailsFetchNowControl: [],
    systemDetailsFetchNowControlStatus: [],
    systemDetailsFetchNowTime: null,
    systemDetailsFetchNowTimerFlag: false,
  });
};

const getStbInfoBySidFetchNowReset = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    errorMsg: "",

    appsFetchNowControl: [],
    appsFetchNowControlStatus: [],
    appsFetchNowTime: null,
    appsFetchNowTimerFlag: false,

    entitlementFetchNowControl: [],
    entitlementFetchNowControlStatus: [],
    entitlementFetchNowTime: null,
    entitlementFetchNowTimerFlag: false,

    batteryStatusFetchNowControl: [],
    batteryStatusFetchNowControlStatus: [],
    batteryStatusFetchNowTime: null,
    batteryStatusFetchNowTimerFlag: false,

    networkSpeedFetchNowControl: [],
    networkSpeedFetchNowControlStatus: [],
    networkSpeedFetchNowTime: null,
    networkSpeedFetchNowTimerFlag: false,

    networkConditionsFetchNowControl: [],
    networkConditionsFetchNowControlStatus: [],
    networkConditionsFetchNowTime: null,
    networkConditionsFetchNowTimerFlag: false,

    bluetoothDevicesFetchNowControl: [],
    bluetoothDevicesFetchNowControlStatus: [],
    bluetoothDevicesFetchNowTime: null,
    bluetoothDevicesFetchNowTimerFlag: false,

    satelliteSignalFetchNowControl: [],
    satelliteSignalFetchNowControlStatus: [],
    satelliteSignalFetchNowTime: null,
    satelliteSignalFetchNowTimerFlag: false,

    systemDetailsFetchNowControl: [],
    systemDetailsFetchNowControlStatus: [],
    systemDetailsFetchNowTime: null,
    systemDetailsFetchNowTimerFlag: false,
  });
};

const getStbInfoBySidSuccess = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    data: action.payload.sort((a, b) =>
      a.CDSN !== b.CDSN ? (a.CDSN < b.CDSN ? -1 : 1) : 0
    ),
  });
};

const setCdsn = (state, action) => {
  return UpdateObject(state, {
    selectedCDSNData: action.payload,
  });
};
const getStbInfoBySidFailure = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    errorMsg: action.payload,
  });
};

const entitlementFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    entitlementFetchNowControl: action.payload,
    // entitlementFetchNowTime:new Date().getTime()
  });
};
const entitlementFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowEntitlementsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    entitlementFetchNowControlStatus: action.payload,
  });
};
const entitlementFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    entitlementFetchNowTimerFlag: !state.entitlementFetchNowTimerFlag,
  });
};

const appsFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    appsFetchNowControl: action.payload,
    // appsFetchNowTime:new Date().getTime()
  });
};
const appsFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowAppsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    appsFetchNowControlStatus: action.payload,
  });
};
const appsFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    appsFetchNowTimerFlag: !state.appsFetchNowTimerFlag,
  });
};
const batteryStatusFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    batteryStatusFetchNowControl: action.payload,
    // batteryStatusFetchNowTime:new Date().getTime()
  });
};
const batteryStatusFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowBatteryStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    batteryStatusFetchNowControlStatus: action.payload,
  });
};
const batteryStatusFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    batteryStatusFetchNowTimerFlag: !state.batteryStatusFetchNowTimerFlag,
  });
};
const networkSpeedFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    networkSpeedFetchNowControl: action.payload,
    // networkSpeedFetchNowTime:new Date().getTime()
  });
};
const networkSpeedFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowNetworkSpeedStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    networkSpeedFetchNowControlStatus: action.payload,
  });
};
const networkSpeedFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    networkSpeedFetchNowTimerFlag: !state.networkSpeedFetchNowTimerFlag,
  });
};

const networkConditionsFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    networkConditionsFetchNowControl: action.payload,
    // networkConditionsFetchNowTime:new Date().getTime()
  });
};
const networkConditionsFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowNetworkConditionsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    networkConditionsFetchNowControlStatus: action.payload,
  });
};
const networkConditionsFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    networkConditionsFetchNowTimerFlag:
      !state.networkConditionsFetchNowTimerFlag,
  });
};

const bluetoothDevicesFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    bluetoothDevicesFetchNowControl: action.payload,
    // bluetoothDevicesFetchNowTime:new Date().getTime()
  });
};
const bluetoothDevicesFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowBluetoothDevicesStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    bluetoothDevicesFetchNowControlStatus: action.payload,
  });
};
const bluetoothDevicesFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    bluetoothDevicesFetchNowTimerFlag: !state.bluetoothDevicesFetchNowTimerFlag,
  });
};

const satelliteSignalFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    satelliteSignalFetchNowControl: action.payload,
    // satelliteSignalFetchNowTime:new Date().getTime()
  });
};
const satelliteSignalFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowSatelliteSignalStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    satelliteSignalFetchNowControlStatus: action.payload,
  });
};
const satelliteSignalFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    satelliteSignalFetchNowTimerFlag: !state.satelliteSignalFetchNowTimerFlag,
  });
};

const systemDetailsFetchNowControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    systemDetailsFetchNowControl: action.payload,
    // systemDetailsFetchNowTime:new Date().getTime()
  });
};
const systemDetailsFetchNowCotrolStatus = (state, action) => {
  localStorage.setItem(
    `FetchNowSystemDetailsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,
    action.payload.status
  );
  return UpdateObject(state, {
    loading: false,
    systemDetailsFetchNowControlStatus: action.payload,
  });
};
const systemDetailsFetchNowTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    systemDetailsFetchNowTimerFlag: !state.systemDetailsFetchNowTimerFlag,
  });
};

export default StbInfoBySidReducer;
