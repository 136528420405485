// import * as ACTION from '../Actions/actionTypes';
import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false, 
    errorMsg: '', 
    permissions: [],

}

const RoleAndPermissionReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.GET_MASTER_PERMISSIONS_REQUEST: return getMasterPermissionsRequest(state, action);

        case ACTION.GET_MASTER_PERMISSIONS_SUCCESS: return getMasterPermissionsSuccess(state, action);

        case ACTION.GET_MASTER_PERMISSIONS_FAILURE: return getMasterPermissionsFailure(state, action);
        
        default: return state;
    }
}
 

/* Start: Get Permission */

const getMasterPermissionsRequest = (state, action) => {

    return UpdateObject(state, {

        loading: true,

    });

}



const getMasterPermissionsSuccess = (state, action) => {

    return UpdateObject(state, {

        loading: false,

        permissions: action.payload,

    });

}



const getMasterPermissionsFailure = (state, action) => {

    return UpdateObject(state, {

        loading: false,

        errorMsg: action.payload,

    });

}

 

export default RoleAndPermissionReducer;