import * as ACTION from './actionTypes';
import {callAPI} from "../../axios"

const getUserListDetailsRequest = () => {
    return {
        type: ACTION.GET_USERLIST_DETAILS_REQUEST
    }
}

const getUserListDetailsSuccess = (userToken) => {
    return {
        type: ACTION.GET_USERLIST_DETAILS_SUCCESS,
        payload: userToken,
    }
}

const getUserListDetailsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_USERLIST_DETAILS_FAILURE,
        payload: errorMsg,
    }
}

export const UserListActions = (pageOptions, type) => {
    let payLoad = pageOptions.filter
    if(type==="isApprovalPending"){
        payLoad.approvalPending=true
    }else {
        payLoad.approvalPending=false
    }
    const apiPath = `user/search?pageNo=${pageOptions.pageNo}&recordsPerPage=${pageOptions.recordsPerPage}`;
    return async function(dispatch) {
        dispatch(getUserListDetailsRequest());
        callAPI(apiPath,"POST", payLoad).then(res => {
            dispatch(getUserListDetailsSuccess(res));
            // console.log(res.data)
        })
        .catch(err => {
            dispatch(getUserListDetailsFailure(err.message));
        });;
    };
};

