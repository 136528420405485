import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
// import {callAPI} from "../../axios/index"

const getFetchNowStatusRequest = () => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_REQUEST
    }
}

const getFetchNowStatusSuccess = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_SUCCESS,
        payload: userToken,
    }
}

const getFetchNowStatusFailure = (errorMsg) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_FAILURE,
        payload: errorMsg,
    }
}

const getFetchNowStatusEntitlements = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_ENTITLEMENTS,
        payload: userToken,
    }
}
const getFetchNowStatusApps = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_APPS,
        payload: userToken,
    }
}
const getFetchNowStatusNetworkCondition = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_NETWORK_CONDITION,
        payload: userToken,
    }
}
const getFetchNowStatusNetworkSpeed = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_NETWORK_SPEED,
        payload: userToken,
    }
}
const getFetchNowStatusBattery = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_BATTERY,
        payload: userToken,
    }
}
const getFetchNowStatusSatelliteSignal = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_SATELLITE_SIGNAL,
        payload: userToken,
    }
}

const getFetchNowStatusBluetooth = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_BLUETOOTH,
        payload: userToken,
    }
}
const getFetchNowStatusAndoridSettings = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_ANDORID_SETTINGS,
        payload: userToken,
    }
}
const getFetchNowStatusSystemDetails = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_SYSTEM_DETAILS,
        payload: userToken,
    }
}
//My Records
const getFetchNowStatusMyRecords = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_MY_RECORDS,
        payload: userToken,
    }
}

//My Tif Monitoring
const getFetchNowStatusTifMonitoring = (userToken) => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_TIF_MONITORING,
        payload: userToken,
    }
}


export const FetchControlStatusResetActions = () => {
    return {
        type: ACTION.GET_FETCH_NOW_STATUS_RESET
    }
}

export const FetchControlStatusActions = (sid,requestID, controlAction) => {
    const apiPath = `/control/status/${sid}?by=CRID&id=${requestID}`; 
 
    return async function(dispatch) {
        dispatch(getFetchNowStatusRequest());
        // callAPI("/accedo-detail","GET").then(res=>{
        //     return callAPI(`${res.accedoApiUrl}${apiPath}`,"POST",payload)
        //  }).then(response=>dispatch(getAverageNetworkSpeedSuccess(response.data)))
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getFetchNowStatusSuccess(res));
            return res
        })
        .then((res)=>{
            if(controlAction==='beacon'){
                dispatch(getFetchNowStatusEntitlements(res))
            }
            else if(controlAction==="appsInstalled"){
                dispatch(getFetchNowStatusApps(res))
            }
            else if(controlAction==="networkConditions"){
                dispatch(getFetchNowStatusNetworkCondition(res))
            }
            else if(controlAction==="networkSpeed"){
                dispatch(getFetchNowStatusNetworkSpeed(res))
            }
            else if(controlAction==="batteryStatus"){
                dispatch(getFetchNowStatusBattery(res))
            }
            else if(controlAction==="satelliteSignal"){
                dispatch(getFetchNowStatusSatelliteSignal(res))
            }
            else if(controlAction==="bluetoothDevices"){
                dispatch(getFetchNowStatusBluetooth(res))
            }
            else if(controlAction==="androidSettings"){
                dispatch(getFetchNowStatusAndoridSettings(res))
            }
            else if(controlAction==="systemDetails"){
                dispatch(getFetchNowStatusSystemDetails(res))
            }
            else if(controlAction==="cdvrRecording"){
                dispatch(getFetchNowStatusMyRecords(res))
            }
            else if(controlAction==="tifStatus"){
                dispatch(getFetchNowStatusTifMonitoring(res))
            }
        })
        .catch(err => {
            dispatch(getFetchNowStatusFailure(err.message));
        });;
    };
};

