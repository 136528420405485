import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const TotalSyncDevicesReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_TOTAL_SYNC_DEVICE_REQUEST: return getTotalSyncDevicesRequest(state, action);
        case ACTION.GET_TOTAL_SYNC_DEVICE_SUCCESS: return getTotalSyncDevicesSuccess(state, action);
        case ACTION.GET_TOTAL_SYNC_DEVICE_FAILURE: return getTotalSyncDevicesFailure(state, action);
        
        default: return state;
    }
}

const getTotalSyncDevicesRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getTotalSyncDevicesSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getTotalSyncDevicesFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default TotalSyncDevicesReducer;