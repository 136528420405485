import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    appBootCountloading: false,
    errorMsg: '',
    appBootCountdata:[],
   // calendarData:[]
}

const AppBootCountReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_APP_BOOT_COUNT_REQUEST: return getAppBootCountRequest(state, action);
        case ACTION.GET_APP_BOOT_COUNT_SUCCESS: return getAppBootCountSuccess(state, action);
        case ACTION.GET_APP_BOOT_COUNT_FAILURE: return getAppBootCountFailure(state, action);
        
        default: return state;
    }
}

const getAppBootCountRequest = (state, action) => {
    return UpdateObject(state, {
        appBootCountloading: true,
    });
}
const getAppBootCountSuccess = (state, action) => {
    return UpdateObject(state, {
        appBootCountloading: false,
        appBootCountdata: action.payload,
    });
}
const getAppBootCountFailure = (state, action) => {
    return UpdateObject(state, {
        appBootCountloading: false,
        errorMsg: action.payload,
    });
}

export default AppBootCountReducer;