import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    bluetoothDevicesFetchNowControl:[],
    bluetoothDevicesFetchNowControlStatus:[],
    bluetoothDevicesFetchNowTime:null,
    bluetoothDevicesFetchNowTimerFlag:false,
   // calendarData:[]
   webhookFlag:false,
}

const BluetoothDevicesReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_BLUETOOTH_DEVICES_REQUEST: return getBluetoothDevicesRequest(state, action);
        case ACTION.GET_BLUETOOTH_DEVICES_SUCCESS: return getBluetoothDevicesSuccess(state, action);
        case ACTION.GET_BLUETOOTH_DEVICES_FAILURE: return getBluetoothDevicesFailure(state, action);
        
        case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_CONTROL: return bluetoothDevicesFetchNowControl(state, action);
        case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_STATUS: return bluetoothDevicesFetchNowCotrolStatus(state, action);
        case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_CONTROL_TIMER_FLAG: return bluetoothDevicesFetchNowTimerFlag(state, action);

        case ACTION.BLUETOOTH_DEVICES_FETCH_NOW_RESET: return bluetoothDevicesFetchNowReset(state, action);

        case ACTION.BLUETOOTH_DEVICES_PROCESSED_WEBHOOK_TRIGGERED: return bluetoothDevicesProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const bluetoothDevicesProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getBluetoothDevicesRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getBluetoothDevicesSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getBluetoothDevicesFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const bluetoothDevicesFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothDevicesFetchNowControl:[],
        bluetoothDevicesFetchNowControlStatus:[],
        bluetoothDevicesFetchNowTime:null,
        bluetoothDevicesFetchNowTimerFlag:false,
    });
}

const bluetoothDevicesFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothDevicesFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const bluetoothDevicesFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowBluetoothDevicesStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        bluetoothDevicesFetchNowControlStatus: action.payload,
    });
}
const bluetoothDevicesFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothDevicesFetchNowTimerFlag: !state.bluetoothDevicesFetchNowTimerFlag,
    });
}

export default BluetoothDevicesReducer;