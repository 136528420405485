import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    // appBootTimeloading: false,
    memoryUsageloading: false,
    cpuUsageloading: false,

    errorMsg: '',
    
    // appBootTimeData:[],
    memoryUsageData:[],
    cpuUsageData:[],
   // calendarData:[]

    memoryUsageFetchNowControl:[],
    memoryUsageFetchNowControlStatus:[],
    memoryUsageFetchNowTime:null,
    memoryUsageFetchNowTimerFlag:false,

    memoryUsageWebhookFlag:false,

    cpuUsageFetchNowControl:[],
    cpuUsageFetchNowControlStatus:[],
    cpuUsageFetchNowTime:null,
    cpuUsageFetchNowTimerFlag:false,

    cpuUsageWebhookFlag:false,
}

const SystemUsageReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case ACTION.GET_MEMORY_USAGE_REQUEST: return getMemoryUsageRequest(state, action);
        case ACTION.GET_MEMORY_USAGE_SUCCESS: return getMemoryUsageSuccess(state, action);
        case ACTION.GET_MEMORY_USAGE_FAILURE: return getMemoryUsageFailure(state, action);

        case ACTION.GET_CPU_USAGE_REQUEST: return getCpuUsageRequest(state, action);
        case ACTION.GET_CPU_USAGE_SUCCESS: return getCpuUsageSuccess(state, action);
        case ACTION.GET_CPU_USAGE_FAILURE: return getCpuUsageFailure(state, action);


        case ACTION.MEMORY_USAGE_FETCH_NOW_CONTROL: return memoryUsageFetchNowControl(state, action);
        case ACTION.MEMORY_USAGE_FETCH_NOW_STATUS: return memoryUsageFetchNowCotrolStatus(state, action);
        case ACTION.MEMORY_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG: return memoryUsageFetchNowTimerFlag(state, action);
        case ACTION.MEMORY_USAGE_FETCH_NOW_RESET: return memoryUsageFetchNowReset(state, action);

        case ACTION.MEMORY_USAGE_PROCESSED_WEBHOOK_TRIGGERED: return memoryUsageProcessedWebhookTriggeredReducer(state, action);

        case ACTION.CPU_USAGE_FETCH_NOW_CONTROL: return cpuUsageFetchNowControl(state, action);
        case ACTION.CPU_USAGE_FETCH_NOW_STATUS: return cpuUsageFetchNowCotrolStatus(state, action);
        case ACTION.CPU_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG: return cpuUsageFetchNowTimerFlag(state, action);
        case ACTION.CPU_USAGE_FETCH_NOW_RESET: return cpuUsageFetchNowReset(state, action);

        case ACTION.CPU_USAGE_PROCESSED_WEBHOOK_TRIGGERED: return cpuUsageProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const memoryUsageProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        memoryUsageWebhookFlag:!state.memoryUsageWebhookFlag
    });
}

const cpuUsageProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        cpuUsageWebhookFlag:!state.cpuUsageWebhookFlag
    });
}

const getMemoryUsageRequest = (state, action) => {
    return UpdateObject(state, {
        memoryUsageloading: true,
    });
}
const getMemoryUsageSuccess = (state, action) => {
    return UpdateObject(state, {
        memoryUsageloading: false,
        memoryUsageData: action.payload,
    });
}
const getMemoryUsageFailure = (state, action) => {
    return UpdateObject(state, {
        memoryUsageloading: false,
        errorMsg: action.payload,
    });
}

const getCpuUsageRequest = (state, action) => {
    return UpdateObject(state, {
        cpuUsageloading: true,
    });
}
const getCpuUsageSuccess = (state, action) => {
    return UpdateObject(state, {
        cpuUsageloading: false,
        cpuUsageData: action.payload,
    });
}
const getCpuUsageFailure = (state, action) => {
    return UpdateObject(state, {
        cpuUsageloading: false,
        errorMsg: action.payload,
    });
}

const memoryUsageFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        memoryUsageloading: false,
        memoryUsageFetchNowControl:[],
        memoryUsageFetchNowControlStatus:[],
        memoryUsageFetchNowTime:null,
        memoryUsageFetchNowTimerFlag:false,
    });
}

const memoryUsageFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        memoryUsageloading: false,
        memoryUsageFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const memoryUsageFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowMemoryUsageStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        memoryUsageloading: false,
        memoryUsageFetchNowControlStatus: action.payload,
    });
}
const memoryUsageFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        memoryUsageloading: false,
        memoryUsageFetchNowTimerFlag: !state.memoryUsageFetchNowTimerFlag,
    });
}

const cpuUsageFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        cpuUsageloading: false,
        cpuUsageFetchNowControl:[],
        cpuUsageFetchNowControlStatus:[],
        cpuUsageFetchNowTime:null,
        cpuUsageFetchNowTimerFlag:false,
    });
}

const cpuUsageFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        cpuUsageloading: false,
        cpuUsageFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const cpuUsageFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowCpuUsageStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        cpuUsageloading: false,
        cpuUsageFetchNowControlStatus: action.payload,
    });
}
const cpuUsageFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        cpuUsageloading: false,
        cpuUsageFetchNowTimerFlag: !state.cpuUsageFetchNowTimerFlag,
    });
}

export default SystemUsageReducer;