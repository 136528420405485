import axios from "axios";

// const accedo_Token = localStorage.getItem("accedo_Token");
// console.log(accedo_Token);
const axiosInstance = axios.create({
    // baseURL: localStorage.getItem("accedo_Token"),
    // timeout: 300000,
});

// console.log(process.env.REACT_APP_BASE_URL_ACCEDO,"base URL")

axiosInstance.interceptors.request.use((request) => {
  // const url= request.url
  // request.url = url+ `?time=${new Date().getTime()}`
  request.headers.ReferenceId = JSON.parse(localStorage.getItem("user_id"))?.userUUID;
  request.headers.Authorization = `Basic ${localStorage.getItem("accedo_Token")}`;
  return request;
});

export const callAccedoAPI = (url, method, payload, headers= {}, responseType = "") => {
  return axiosInstance({
    url,
    method,
    data: payload,
    headers,
    responseType
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if(error?.response?.data?.code===401){
        const selectionFired = new CustomEvent("401_Error", {
          "detail": error.response.data.message});
        window.dispatchEvent(selectionFired);
      }
      else if( (error?.response?.data?.code===500) && (error?.response?.data?.message).includes(".Apps_Installed.value[0] should have required property 'isSystemApp'")){
        const selectionFired = new CustomEvent("429_error", {
          "detail": "Please install the new latest Agent App on STB"});
        window.dispatchEvent(selectionFired);
      }
      // console.log(error.response.data.message)
      else if(error?.response?.data?.code){
        const selectionFired = new CustomEvent("429_error", {
          "detail": error.response.data.message?error.response.data.message:"Not Found"});
        window.dispatchEvent(selectionFired);
      }
      // else if(error?.response?.data==undefined){
      //   const selectionFired = new CustomEvent("429_error", {
      //     "detail": "Something went wrong, Please refresh page again !"});
      //   window.dispatchEvent(selectionFired);
      // }
      throw error;
    });
};
