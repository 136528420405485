import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getDeviceStorageRequest = () => {
    return {
        type: ACTION.GET_DEVICE_STORAGE_REQUEST
    }
}

const getDeviceStorageSuccess = (payload) => {
    return {
        type: ACTION.GET_DEVICE_STORAGE_SUCCESS,
        payload: payload,
    }
}

const getDeviceStorageFailure = (errorMsg) => {
    return {
        type: ACTION.GET_DEVICE_STORAGE_FAILURE,
        payload: errorMsg,
    }
}
export const deviceStorageFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.DEVICE_STORAGE_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const deviceStorageFetchNowControl = (payload)=>{
    return {
        type: ACTION.DEVICE_STORAGE_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const deviceStorageFetchNowStatus = (payload) => {
    return {
        type: ACTION.DEVICE_STORAGE_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const deviceStorageProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.DEVICE_STORAGE_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getDeviceStorageData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/deviceStorage/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getDeviceStorageRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getDeviceStorageSuccess(res));
        })
        .catch(err => {
            dispatch(getDeviceStorageFailure(err.message));
        });;
    };
};

export const postFetchNowDeviceStorageControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowDeviceStorageSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(deviceStorageFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(deviceStorageFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getDeviceStorageFailure(err.message));
        });
    }; 
};