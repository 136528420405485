import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // timeout: 12000,
});

// console.log(process.env.REACT_APP_BASE_URL,"base URL")
// const JWTToken = localStorage.getItem("jwt_token");

axiosInstance.interceptors.request.use((request) => {
  const isLoggedIn = localStorage.getItem("jwt_token");
  
  if(isLoggedIn){
  request.headers.Authorization = `Bearer ${isLoggedIn}`;
}
  return request;
});

export const callAPI = (url, method, payload, headers= {}, responseType = "") => {
  return axiosInstance({
    url,
    method,
    data: payload,
    headers,
    responseType
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if(error?.response?.data?.status===401){
        const selectionFired = new CustomEvent("401_Error", {
          "detail": error.response.data.errorMessage});
        window.dispatchEvent(selectionFired);
      }
      // console.log(error.response.data.message)
      else if(error?.response?.data?.status===404){
        const selectionFired = new CustomEvent("429_error", {
          "detail": error.response.data.error});
        window.dispatchEvent(selectionFired);
      }
      else if(error?.response?.data?.status){
        const selectionFired = new CustomEvent("429_error", {
          "detail": error.response.data.errorMessage});
        window.dispatchEvent(selectionFired);
      }
      throw error;
    });
};
