import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    // errorMsg: '',
    wizardWebhookReceivedStatus:[],
    wizardWebhookProcessedStatus:[]
}

const WizardSIDLevelReducers = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.WIZARD_CONTROL_REQUEST_RECEIVED: return wizardControlRequestReceivedStatus(state, action);
        case ACTION.WIZARD_CONTROL_REQUEST_PROCESSED: return wizardControlRequestProcessedStatus(state, action);
        
        default: return state;
    }
}

const wizardControlRequestReceivedStatus = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        wizardWebhookReceivedStatus: action.payload,
    });
}

const wizardControlRequestProcessedStatus = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        wizardWebhookProcessedStatus: action.payload,
    });
}

export default WizardSIDLevelReducers;