import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    androidSettingsFetchNowControl:[],
    androidSettingsFetchNowControlStatus:[],
    androidSettingsFetchNowTime:null,
    androidSettingsFetchNowTimerFlag:false,
   // calendarData:[]
   webhookFlag:false,
}

const AndroidSettingsReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_ANDROID_SETTINGS_REQUEST: return getAndroidSettingsRequest(state, action);
        case ACTION.GET_ANDROID_SETTINGS_SUCCESS: return getAndroidSettingsSuccess(state, action);
        case ACTION.GET_ANDROID_SETTINGS_FAILURE: return getAndroidSettingsFailure(state, action);
        
        case ACTION.ANDROID_SETTINGS_FETCH_NOW_CONTROL: return androidSettingsFetchNowControl(state, action);
        case ACTION.ANDROID_SETTINGS_FETCH_NOW_STATUS: return androidSettingsFetchNowCotrolStatus(state, action);
        case ACTION.ANDROID_SETTINGS_FETCH_NOW_CONTROL_TIMER_FLAG: return androidSettingsFetchNowTimerFlag(state, action);

        case ACTION.ANDROID_SETTINGS_FETCH_NOW_RESET: return androidSettingsFetchNowReset(state, action);

        case ACTION.ANDROID_SETTINGS_PROCESSED_WEBHOOK_TRIGGERED: return androidSettingsProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const androidSettingsProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getAndroidSettingsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getAndroidSettingsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getAndroidSettingsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const androidSettingsFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        androidSettingsFetchNowControl:[],
        androidSettingsFetchNowControlStatus:[],
        androidSettingsFetchNowTime:null,
        androidSettingsFetchNowTimerFlag:false,
    });
}

const androidSettingsFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        androidSettingsFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const androidSettingsFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowAndroidSettingsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        androidSettingsFetchNowControlStatus: action.payload,
    });
}
const androidSettingsFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        androidSettingsFetchNowTimerFlag: !state.androidSettingsFetchNowTimerFlag,
    });
}

export default AndroidSettingsReducer;