import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    remoteWizardGlobalList:[]
}

const RemoteWizardGlobalLevelReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_REMOTE_WIZARD_GLOBAL_LEVEL_REQUEST: return getRemoteWizardGlobalRequest(state, action);
        case ACTION.GET_REMOTE_WIZARD_GLOBAL_LEVEL_SUCCESS: return getRemoteWizardGlobalSuccess(state, action);
        case ACTION.GET_REMOTE_WIZARD_GLOBAL_LEVEL_FAILURE: return getRemoteWizardGlobalFailure(state, action);
        
        default: return state;
    }
}

const getRemoteWizardGlobalRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getRemoteWizardGlobalSuccess = (state, action) => {
    const soretedList = action.payload?.results?.sort((a, b) => b.createdAt !== a.createdAt ? b.createdAt < a.createdAt ? -1 : 1 : 0)
    return UpdateObject(state, {
        loading: false,
        remoteWizardGlobalList: {...action.payload,results:soretedList},
    });
}
const getRemoteWizardGlobalFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default RemoteWizardGlobalLevelReducer;