import React, {Fragment, useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import MetisMenu from 'react-metismenu';

import {MainNav, SIDCDSNLevels,
EventsCapturedNav,TotalDevicesNav,BlockedAppsNav, RemoteWizard,GlobalEvents, 
AppVersionNav, EntitlementMismatch, TotalLauncherCrash, NoSignalCount, ISPDetails} from './NavItems';
import { isValidRole } from '../../utils/utils';

export const Nav = () => {
  const [activeLinkTo, setActiveLinkTo] = useState("");
  const stateList = {
    permissions: {
        userList: isValidRole("MENU_USERS"),
        createUser: isValidRole("MENU_USER_CREATE"),
        roles: isValidRole("MENU_ROLES"),
        rolesAndPermissions: isValidRole("MENU_ROLES_AND_PERMISSIONS"),
        userPrivileges: isValidRole("MENU_USER_PRIVILEGES"),
        stbFailureEvents: isValidRole("MENU_STB_FAILURE_EVENTS"),
        systemDefinedThreshold: isValidRole("MENU_DEVIATION_PERCENTAGE")
      },
       UserManagementNav : [
        {
        icon: "pe-7s-users",
        label: 'User Management',
          content: [
            {label: 'Users', 
            to: '/UserManagement/userlist',
            permissionKey: "userList",
            },
            {label: 'Create User', 
            to: '/UserManagement/create-user',  
            permissionKey: "createUser"},
            {label: 'Roles', 
            to: '/UserManagement/roles',
            permissionKey: "roles"
            },
            {label: 'Roles And Permissions', 
            to: '/UserManagement/roles-permissions',
            permissionKey: "rolesAndPermissions"},
            {label: 'User Privileges', 
            to: '/UserManagement/user-privileges',
            permissionKey: "userPrivileges"
        }
          ],
        },
      ],
      SystemFailuresNav:[
        {
            icon: 'pe-7s-info',
            label: 'System Failures',
            content: [
                {label: 'STB Failure Events', 
                to: '/Dashboards/SystemErrorsIndiaLevel',
                permissionKey: "stbFailureEvents"
                },
                {label: 'Deviation Percentage',
                 to: '/Dashboards/SystemDefinedThreshold',
                 permissionKey: "systemDefinedThreshold"
                }
            ],
        }
      ]
};
const[filteredMenuUserManagementNav, setFilteredMenuUserManagementNav] = useState([])
const[filteredMenuSystemFailure, setFilteredMenuSystemFailure] = useState([])

    useEffect(() => {
      setActiveLinkTo(window.location.pathname);
      const { permissions, UserManagementNav, SystemFailuresNav } = stateList;
      const filteredMenuUserManagementNav = UserManagementNav.map(item => ({
          ...item,
          content: item.content.filter(submenu => permissions[submenu.permissionKey]),
        }));
        const filteredMenuSystemFailure = SystemFailuresNav.map(item => ({
          ...item,
          content: item.content.filter(submenu => permissions[submenu.permissionKey]),
        }));
        setFilteredMenuUserManagementNav(filteredMenuUserManagementNav)
        setFilteredMenuSystemFailure(filteredMenuSystemFailure)
    }, [window.location.pathname]);
        
            return (
            <Fragment>
            
            {isValidRole('MENU_DASHBOARD') && <MetisMenu content={MainNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> }

            {isValidRole('TOTAL_DEVICES_ACROSS_INDIA') && <MetisMenu content={TotalDevicesNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/> }

            {isValidRole('MENU_SID_CDSN_LEVELS_DETAILS') &&<MetisMenu content={SIDCDSNLevels} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>}

            {isValidRole('MENU_EVENT_CAPTURED') &&<MetisMenu content={EventsCapturedNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>}
               
            {isValidRole('MENU_USER_MANAGEMENT') && <MetisMenu content={filteredMenuUserManagementNav}  activeLinkTo={activeLinkTo} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>  }              
            {isValidRole('MENU_SYSTEM_FAILURES') && <MetisMenu content={filteredMenuSystemFailure}  activeLinkTo={activeLinkTo} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>  }
            {isValidRole('MENU_APP_VERSION') && <MetisMenu content={AppVersionNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  } 
                {/* <MetisMenu content={ComponentsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>                  */}
            
                {/* <MetisMenu content={ChartsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}
            {isValidRole('MENU_BLOCKED_APPLICATIONS') && <MetisMenu content={BlockedAppsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            {isValidRole('MENU_REMOTE_WIZARD') && <MetisMenu content={RemoteWizard} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            {isValidRole('MENU_GLOBAL_CONTROL_EVENT') && <MetisMenu content={GlobalEvents} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            {isValidRole('MENU_ENTITLEMENT_MISMATCH') && <MetisMenu content={EntitlementMismatch} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            {isValidRole('MENU_TOTAL_LAUNCHER_CRASH') && <MetisMenu content={TotalLauncherCrash} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            {isValidRole('MENU_NO_SIGNAL_COUNT') && <MetisMenu content={NoSignalCount} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            {isValidRole('MENU_ISP_DETAILS') && <MetisMenu content={ISPDetails} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon=""/>  }
            </Fragment>
        );
    }

export default withRouter(Nav);