import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const StbCountReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_STB_COUNT_REQUEST: return getStbCountRequest(state, action);
        case ACTION.GET_STB_COUNT_SUCCESS: return getStbCountSuccess(state, action);
        case ACTION.GET_STB_COUNT_FAILURE: return getStbCountFailure(state, action);
        
        default: return state;
    }
}

const getStbCountRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getStbCountSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getStbCountFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data:[],
        errorMsg: action.payload,
    });
}

export default StbCountReducer;