import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getNetworkSpeedRequest = () => {
    return {
        type: ACTION.GET_NETWORK_SPEED_REQUEST
    }
}

const getNetworkSpeedSuccess = (apiData) => {
    return {
        type: ACTION.GET_NETWORK_SPEED_SUCCESS,
        payload: apiData,
    }
}

const getNetworkSpeedFailure = (errorMsg) => {
    return {
        type: ACTION.GET_NETWORK_SPEED_FAILURE,
        payload: errorMsg,
    }
}

export const networkSpeedFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const networkSpeedFetchNowControl = (payload)=>{
    return {
        type: ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const networkSpeedFetchNowStatus = (payload) => {
    return {
        type: ACTION.NETWORK_SPEED_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const networkSpeedProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.NETWORK_SPEED_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getNetworkSpeedData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/networkSpeed/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getNetworkSpeedRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getNetworkSpeedSuccess(res));
        })
        .catch(err => {
            dispatch(getNetworkSpeedFailure(err.message));
        });;
    };
};

export const postFetchNowNetworkSpeedControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowNetworkSpeedSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(networkSpeedFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(networkSpeedFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getNetworkSpeedFailure(err.message));
        });
    }; 
};