import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const BatteryGraphCdsnReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_BATTERY_GRAPH_CDSN_REQUEST: return getBatteryGraphCdsnRequest(state, action);
        case ACTION.GET_BATTERY_GRAPH_CDSN_SUCCESS: return getBatteryGraphCdsnSuccess(state, action);
        case ACTION.GET_BATTERY_GRAPH_CDSN_FAILURE: return getBatteryGraphCdsnFailure(state, action);
        
        default: return state;
    }
}

const getBatteryGraphCdsnRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getBatteryGraphCdsnSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getBatteryGraphCdsnFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default BatteryGraphCdsnReducer;