import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    systemDetailsFetchNowControl:[],
    systemDetailsFetchNowControlStatus:[],
    systemDetailsFetchNowTime:null,
    systemDetailsFetchNowTimerFlag:false,
    // calendarData:[]
    webhookFlag:false,

    systemDetailsAdbControl:[],
    systemDetailsAdbControlStatus:[],
    systemDetailsAdbTime:null,
    systemDetailsAdbTimerFlag:false,
    adbWebhook:false
}

const SystemDetailsReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_SYSTEM_DETAILS_REQUEST: return getSystemDetailsRequest(state, action);
        case ACTION.GET_SYSTEM_DETAILS_SUCCESS: return getSystemDetailsSuccess(state, action);
        case ACTION.GET_SYSTEM_DETAILS_FAILURE: return getSystemDetailsFailure(state, action);
        
        case ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL: return systemDetailsFetchNowControl(state, action);
        case ACTION.SYSTEM_DETAILS_FETCH_NOW_STATUS: return systemDetailsFetchNowCotrolStatus(state, action);
        case ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL_TIMER_FLAG: return systemDetailsFetchNowTimerFlag(state, action);

        case ACTION.SYSTEM_DETAILS_FETCH_NOW_RESET: return systemDetailsFetchNowReset(state, action);

        case ACTION.SYSTEM_DETAILS_PROCESSED_WEBHOOK_TRIGGERED: return systemDetailsProcessedWebhookTriggeredReducer(state, action);

        case ACTION.SYSTEM_DETAILS_ADB_CONTROL: return systemDetailsAdbControl(state, action);
        case ACTION.SYSTEM_DETAILS_ADB_STATUS: return systemDetailsAdbCotrolStatus(state, action);
        case ACTION.SYSTEM_DETAILS_ADB_CONTROL_TIMER_FLAG: return systemDetailsAdbTimerFlag(state, action);

        case ACTION.SYSTEM_DETAILS_ADB_RESET: return systemDetailsAdbReset(state, action);

        case ACTION.SYSTEM_DETAILS_ADB_PROCESSED_WEBHOOK_TRIGGERED: return systemDetailsProcessedAdbWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const systemDetailsProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const systemDetailsProcessedAdbWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        adbWebhook:!state.adbWebhook
    });
}

const getSystemDetailsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getSystemDetailsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getSystemDetailsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const systemDetailsFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNowControl:[],
        systemDetailsFetchNowControlStatus:[],
        systemDetailsFetchNowTime:null,
        systemDetailsFetchNowTimerFlag:false,
    });
}

const systemDetailsFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const systemDetailsFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowSystemDetailsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNowControlStatus: action.payload,
    });
}
const systemDetailsFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsFetchNowTimerFlag: !state.systemDetailsFetchNowTimerFlag,
    });
}

const systemDetailsAdbReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsAdbControl:[],
        systemDetailsAdbControlStatus:[],
        systemDetailsAdbTime:null,
        systemDetailsAdbTimerFlag:false,
    });
}

const systemDetailsAdbControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsAdbControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const systemDetailsAdbCotrolStatus = (state, action) => {
    localStorage.setItem(`AdbSystemDetailsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        systemDetailsAdbControlStatus: action.payload,
    });
}
const systemDetailsAdbTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsAdbTimerFlag: !state.systemDetailsAdbTimerFlag,
    });
}

export default SystemDetailsReducer;