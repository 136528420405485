import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getSystemDetailsRequest = () => {
    return {
        type: ACTION.GET_SYSTEM_DETAILS_REQUEST
    }
}

const getSystemDetailsSuccess = (apiData) => {
    return {
        type: ACTION.GET_SYSTEM_DETAILS_SUCCESS,
        payload: apiData,
    }
}

const getSystemDetailsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_SYSTEM_DETAILS_FAILURE,
        payload: errorMsg,
    }
}

export const systemDetailsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const systemDetailsFetchNowControl = (payload)=>{
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const systemDetailsFetchNowStatus = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const systemDetailsFetchNowReset = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_FETCH_NOW_RESET,
        payload: payload,
    }
}

export const systemDetailsProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const systemDetailsAdbTriggerTimerFlag = () => {
    return {
        type: ACTION.SYSTEM_DETAILS_ADB_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const systemDetailsAdbControl = (payload)=>{
    return {
        type: ACTION.SYSTEM_DETAILS_ADB_CONTROL,
        payload: payload,
    }
}

export const systemDetailsAdbStatus = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_ADB_STATUS,
        payload: payload,
    }
}

export const systemDetailsAdbReset = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_ADB_RESET,
        payload: payload,
    }
}

export const systemDetailsAdbProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.SYSTEM_DETAILS_ADB_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getSystemDetailsData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/systemDetails/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getSystemDetailsRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getSystemDetailsSuccess(res));
        })
        .catch(err => {
            dispatch(getSystemDetailsFailure(err.message));
        });;
    };
};

export const postFetchNowSystemDetailsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowSystemDetailsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(systemDetailsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(systemDetailsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getSystemDetailsFailure(err.message));
        });
    }; 
};

export const postAdbSystemDetailsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/adbEnable`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`AdbSystemDetailsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(systemDetailsAdbControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(systemDetailsAdbStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getSystemDetailsFailure(err.message));
        });
    }; 
};