import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    timerFlag:false,
    epgControlResponse:[],
    epgControlResponseStatus:[],
    epgControlResponseTime:null,
    epgFailureError:[],

    tifMonitoringFetchNowControl:[],
    tifMonitoringFetchNowControlStatus:[],
    tifMonitoringFetchNowTime:null,
    tifMonitoringFetchNowTimerFlag:false,
   // calendarData:[]
   webhookFlag:false,
}

const TifMonitoringReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_ANDROID_TIFMONITORING_REQUEST: return getTifMonitoringRequest(state, action);
        case ACTION.GET_ANDROID_TIFMONITORING_SUCCESS: return getTifMonitoringSuccess(state, action);
        case ACTION.GET_ANDROID_TIFMONITORING_FAILURE: return getTifMonitoringFailure(state, action);

        case ACTION.POST_ANDROID_TIFMONITORING_CONTROL: return postTifMonitoringControl(state, action);
        case ACTION.GET_ANDROID_TIFMONITORING_CONTROL_STATUS: return getTifMonitoringCotrolStatus(state, action);
        case ACTION.REBUILD_EPG_CACHE_TIMER_FLAG: return rebuildEPGCacheTimerFlag(state, action);

        case ACTION.RESET_REBUILD_EPG_CACHE_BUTTON: return resetRebuildEPGCacheButton(state, action);

        case ACTION.TIF_MONITORING_FETCH_NOW_CONTROL: return tifMonitoringFetchNowControl(state, action);
        case ACTION.TIF_MONITORING_FETCH_NOW_STATUS: return tifMonitoringFetchNowCotrolStatus(state, action);
        case ACTION.TIF_MONITORING_FETCH_NOW_CONTROL_TIMER_FLAG: return tifMonitoringFetchNowTimerFlag(state, action);

        case ACTION.TIF_MONITORING_FETCH_NOW_RESET: return tifMonitoringFetchNowReset(state, action);
        case ACTION.TIFMONITORING_REBUILD_EPG_CACHE_FAILURE: return postRebuildEPGCacheFailure(state, action);

        case ACTION.TIF_MONITORING_PROCESSED_WEBHOOK_TRIGGERED: return tifMonitoringProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const tifMonitoringProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const postRebuildEPGCacheFailure = (state, action) => {
    return UpdateObject(state, {
        epgFailureError:action.payload
    });
}

const resetRebuildEPGCacheButton = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        timerFlag:false,
        epgControlResponse:[],
        epgControlResponseStatus:[],
        epgControlResponseTime:null
    });
}

const tifMonitoringFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNowControl:[],
        tifMonitoringFetchNowControlStatus:[],
        tifMonitoringFetchNowTime:null,
        tifMonitoringFetchNowTimerFlag:false,
    });
}

const getTifMonitoringRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getTifMonitoringSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const postTifMonitoringControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        epgControlResponse: action.payload,
        epgControlResponseTime:new Date().getTime()
    });
}
const getTifMonitoringCotrolStatus = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        epgControlResponseStatus: action.payload,
    });
}
const rebuildEPGCacheTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        timerFlag: !state.timerFlag,
    });
}
const getTifMonitoringFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const tifMonitoringFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNowControl: action.payload,
        // tifMonitoringFetchNowTime:new Date().getTime()
    });
}
const tifMonitoringFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowTifMonitoringStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNowControlStatus: action.payload,
    });
}
const tifMonitoringFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringFetchNowTimerFlag: !state.tifMonitoringFetchNowTimerFlag,
    });
}

export default TifMonitoringReducer;