import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    networkSpeedFetchNowControl:[],
    networkSpeedFetchNowControlStatus:[],
    networkSpeedFetchNowTime:null,
    networkSpeedFetchNowTimerFlag:false,
   // calendarData:[]
   webhookFlag:false,
}

const NetworkSpeedReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_NETWORK_SPEED_REQUEST: return getNetworkSpeedRequest(state, action);
        case ACTION.GET_NETWORK_SPEED_SUCCESS: return getNetworkSpeedSuccess(state, action);
        case ACTION.GET_NETWORK_SPEED_FAILURE: return getNetworkSpeedFailure(state, action);
        
        case ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL: return networkSpeedFetchNowControl(state, action);
        case ACTION.NETWORK_SPEED_FETCH_NOW_STATUS: return networkSpeedFetchNowCotrolStatus(state, action);
        case ACTION.NETWORK_SPEED_FETCH_NOW_CONTROL_TIMER_FLAG: return networkSpeedFetchNowTimerFlag(state, action);

        case ACTION.NETWORK_SPEED_FETCH_NOW_RESET: return networkSpeedFetchNowReset(state, action);

        case ACTION.NETWORK_SPEED_PROCESSED_WEBHOOK_TRIGGERED: return networkConditionsProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const networkConditionsProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getNetworkSpeedRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getNetworkSpeedSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getNetworkSpeedFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const networkSpeedFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNowControl:[],
        networkSpeedsFetchNowControlStatus:[],
        networkSpeedFetchNowTime:null,
        networkSpeedFetchNowTimerFlag:false,
    });
}

const networkSpeedFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const networkSpeedFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowNetworkSpeedStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNowControlStatus: action.payload,
    });
}
const networkSpeedFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedFetchNowTimerFlag: !state.networkSpeedFetchNowTimerFlag,
    });
}

export default NetworkSpeedReducer;