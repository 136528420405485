import moment from "moment";
import React , {useState , useEffect} from "react"
// import { callAPI } from "../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export const dateFormatterLastUpdated = (inputDate)=>{
  return moment(inputDate).format("DD/MM/YYYY, h:mm A")
}

export function formatNumber(num) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 100000) {
    return (num / 1000).toFixed(1) + 'K';
  } else if (num < 10000000) {
    return (num / 100000).toFixed(1) + 'L';
  } else if (num < 1000000000) {
    return (num / 10000000).toFixed(1) + 'Cr';
  } else {
    return num.toString();
  }
}

export const UpdateObject = (oldObject, updatedValue) => {
    return {
        ...oldObject,
        ...updatedValue,
    }
}

export const logout= ()=> {
    // Clear access token and ID token from local storage
    localStorage.clear();
    window.location.replace('/Login');
  }

export const initialPayload = {
    from: Date.parse(moment().startOf('day').subtract(6, "days")),
    to: Date.parse(moment()),
  }

  export const initialPayload24Hours = {
    from: Date.parse(moment().startOf("day")),
    to: Date.parse(moment()),
  }

  export const initialPayloadCDSN = {
    from: Date.parse(moment().startOf('day').subtract(6, "days")),
    to: Date.parse(moment()),
    SID: "",
    CDSN: ""
  }

 let userDetails;
 let availableRoles;
 let availablePermissions;
 
 export const isValidRole = (SUB_TYPE) => {
     userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : {};
     availableRoles = Object.keys(userDetails).length > 0 ? userDetails.availableRoles : [];
     availablePermissions = Object.keys(userDetails).length > 0 ? userDetails.permissions : [];
     
     if((availableRoles && availableRoles.includes('ROLE_ADMIN')) || (availablePermissions && availablePermissions.find(o => o.permissionCode === SUB_TYPE))){
         return true;
     }
     return false;
 }

 export const useDebounce=(value, delay)=> {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const isEmpty = (obj) => {
  if (
    obj === null ||
    obj === undefined ||
    Array.isArray(obj) ||
    typeof obj !== "object"
  ) {
    return true;
  }
  return Object.getOwnPropertyNames(obj).length === 0;
};

export const apiFailedErrorText = (error, onClickHandler)=>{
  if(error==="Network Error"){
      return <div class="stb-graph-header justify-content-center top-right-filter">
      <div className="font-weight-bold p-3 text-center">
      Something went wrong, Please refresh section again !
      </div>
      <div className={`refresh-global-icon `} 
        onClick={onClickHandler}>
        <FontAwesomeIcon icon={faSync} /> <span>Refresh</span>
      </div>
  </div>
  }
}

export function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

export function formatBytes(bytes, decimals = 1) {
  if (bytes === 0 || Number.isNaN(bytes) || bytes === undefined || typeof bytes !== "number") return "0 B";
  const k = 1000;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
  );
}