import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const SystemErrorsIndiaDashboardReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_REQUEST: return getSystemErrorsIndiaDashboardActionRequest(state, action);
        case ACTION.GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_SUCCESS: return getSystemErrorsIndiaDashboardActionSuccess(state, action);
        case ACTION.GET_SYSTEM_ERRORS_INDIA_DASHBOARD_ACTION_FAILURE: return getSystemErrorsIndiaDashboardActionFailure(state, action);
        
        default: return state;
    }
}

const getSystemErrorsIndiaDashboardActionRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getSystemErrorsIndiaDashboardActionSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getSystemErrorsIndiaDashboardActionFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data:[],
        errorMsg: action.payload,
    });
}

export default SystemErrorsIndiaDashboardReducer;