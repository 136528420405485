import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    dth:[],
    ott:[],
    binge:[],

    ttnDthLoader:false,
    ttnBingeLoader:false,
    ttnOttLoader:false,

    accedoLoading: false,
    accedoErrorMsg: '',
    data:[],

    entitlementFetchNowControl:[],
    entitlementFetchNowControlStatus:[],
    entitlementFetchNowTime:null,
    entitlementFetchNowTimerFlag:false,

    webhookFlag:false,
   // calendarData:[]
}

const EntitlementReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_ENTITLEMENT_REQUEST: return getEntitlementRequest(state, action);
        case ACTION.GET_ENTITLEMENT_SUCCESS_DTH: return getEntitlementSuccessDth(state, action);
        case ACTION.GET_ENTITLEMENT_SUCCESS_BINGE: return getEntitlementSuccessBinge(state, action);
        case ACTION.GET_ENTITLEMENT_SUCCESS_OTT: return getEntitlementSuccessOtt(state, action);
        case ACTION.GET_ENTITLEMENT_FAILURE: return getEntitlementFailure(state, action);

        //Accedo get api & fetch now

        case ACTION.GET_ENTITLEMENTS_ACCEDO_REQUEST: return getEntitlementAccedoRequest(state, action);
        case ACTION.GET_ENTITLEMENTS_ACCEDO_SUCCESS: return getEntitlementAccedoSuccess(state, action);
        case ACTION.GET_ENTITLEMENTS_ACCEDO_FAILURE: return getEntitlementAccedoFailure(state, action);
        
        case ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL: return entitlementsFetchNowControl(state, action);
        case ACTION.ENTITLEMENTS_FETCH_NOW_STATUS: return entitlementsFetchNowCotrolStatus(state, action);
        case ACTION.ENTITLEMENTS_FETCH_NOW_CONTROL_TIMER_FLAG: return entitlementsFetchNowTimerFlag(state, action);

        case ACTION.ENTITLEMENTS_FETCH_NOW_RESET: return entitlementsFetchNowReset(state, action);

        case ACTION.ENTITLEMENTS_WEBHOOK_TRIGGERED: return entitlementWebhookTriggeredReducer(state, action);
        
        default: return state;
    }
}

export const entitlementWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getEntitlementRequest = (state, action) => {
    return UpdateObject(state, {
        ttnDthLoader:true,
        ttnBingeLoader:true,
        ttnOttLoader:true,
    });
}
const getEntitlementSuccessDth = (state, action) => {
    return UpdateObject(state, {
        dth: action.payload,
        ttnDthLoader: false,
    });
}
const getEntitlementSuccessBinge = (state, action) => {
    return UpdateObject(state, {
        binge: action.payload,
        ttnBingeLoader: false,
    });
}
const getEntitlementSuccessOtt = (state, action) => {
    return UpdateObject(state, {
        ott: action.payload,
        ttnOttLoader: false,
    });
}
const getEntitlementFailure = (state, action) => {
    return UpdateObject(state, {
        ttnDthLoader:false,
        ttnBingeLoader:false,
        ttnOttLoader:false,
        errorMsg: action.payload,
    });
}

const getEntitlementAccedoRequest = (state, action) => {
    return UpdateObject(state, {
        accedoLoading: true,
    });
}
const getEntitlementAccedoSuccess = (state, action) => {
    return UpdateObject(state, {
        accedoLoading: false,
        data: action.payload,
    });
}
const getEntitlementAccedoFailure = (state, action) => {
    return UpdateObject(state, {
        accedoLoading: false,
        accedoErrorMsg: action.payload,
    });
}

const entitlementsFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        // loading: true,
        dth:[],
        ott:[],
        binge:[],

        // accedoLoading: true,
        accedoErrorMsg: '',
        data:[],
        
        entitlementFetchNowControl:[],
        entitlementFetchNowControlStatus:[],
        entitlementFetchNowTime:null,
        entitlementFetchNowTimerFlag:false,
    });
}

const entitlementsFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        ttnDthLoader:false,
        ttnBingeLoader:false,
        ttnOttLoader:false,
        entitlementFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const entitlementsFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowEntitlementsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        ttnDthLoader:false,
        ttnBingeLoader:false,
        ttnOttLoader:false,
        entitlementFetchNowControlStatus: action.payload,
    });
}
const entitlementsFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        ttnDthLoader:false,
        ttnBingeLoader:false,
        ttnOttLoader:false,
        entitlementFetchNowTimerFlag: !state.entitlementFetchNowTimerFlag,
    });
}

export default EntitlementReducer;