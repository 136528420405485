import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter  } from 'react-router-dom';
import './assets/base.css';
import './assets/layout/main/_stb.scss'
import Main from './Pages/Main';
import configureStore from './store';
import { Provider } from 'react-redux';
import './fonts/Roboto/Roboto-Regular.ttf';
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallBack from './Pages/Components/ErrorBoundary/ErrorBoundaryFallBack';

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter >
  {/* <React.StrictMode> */}
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallBack}>
    <Main />
    </ErrorBoundary>
  {/* </React.StrictMode> */}
  </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
