import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";
// import reducers from './reducers';
import thunk from "redux-thunk";

import Sample from "./reducers/Sample";
import ThemeOptions from "./reducers/ThemeOptions";
import LocationInfoReducer from "./reducers/LocationInfoReducer";
import AuthReducer from "./reducers/AuthReducer";
import UserListReducer from "./reducers/UserListReducer";
import StbCountReducer from "./reducers/StbCountReducer";
import CalenderReducer from "./reducers/CalendarDateRangeReducer";
import TotalLauncherReducer from "./reducers/TotalLauncherReducer";
import TotalFirmwareVersionReducer from "./reducers/TotalFirmwareVersionReducer";
import TotalAppVersionReducer from "./reducers/TotalAppVersionReducer";
import RolesAndPermissionReducer from "./reducers/RolesAndPermissionReducer";
import RolesReducer from "./reducers/RoleReducer";
import AverageNetworkSpeedReducer from "./reducers/AverageNetworkSpeedReducer";
import StbInfoBySidReducer from "./reducers/StbInfoBySidReducer";
import AverageBatteryStatusReducer from "./reducers/AverageBatteryStatusReducer";
import BatteryGraphCdsnReducer from "./reducers/BatteryGraphCdsnReducer";
import NetworkGraphCdsnReducer from "./reducers/NetworkGraphCdsnReducer";
import TotalDevicesAcrossIndiaReducer from "./reducers/TotalDevicesAcrossIndiaReducer";
import NetworkAvailabiltyGraphCdsnReducer from "./reducers/NetworkAvailabiltyGraphCdsnReducer";
import EntitlementReducer from "./reducers/EntitlementReducer";
import AndroidSettingsReducer from "./reducers/AndroidSettingsReducer";
import FetchInitiateReducer from "./reducers/FetchInitiateReducer";
import FetchControlStatusReducer from "./reducers/FetchControlStatusReducer";
import TifMonitoringReducer from "./reducers/TifMonitoringReducer";
import SatelliteScanReducer from "./reducers/SatelliteScanReducer";
import MyRecordsReducer from "./reducers/MyRecordsReducer";
import RcuKeyCodesReducer from "./reducers/RcuKeyCodesReducer";
import RcuKeyCodesStbRecuder from "./reducers/RcuKeyCodesStbReducer";
import ControlEventsReducer from "./reducers/ControlEventsReducer";
import SystemErrorsIndiaLevelReducer from "./reducers/SystemErrorsIndiaLevelReducer";
import SystemErrorsIndiaLevelDashboardReducer from "./reducers/SystemErrorsIndiaLevelDashboardReducer";
import SystemErrorsStbLevelReducer from "./reducers/SystemErrorsStbLevelReducer";
import TVResoultionsReducer from "./reducers/TVResoultionsReducer";
import BlockedApplicationsReducer from "./reducers/BlockedApplicationsReducer";
import RemoteWizardGlobalLevelReducer from "./reducers/RemoteWizardGlobalLevelReducer";
import WizardSIDLevelReducers from "./reducers/WizardSIDLevelReducers";
import TotalSyncDevicesReducer from "./reducers/TotalSyncDevicesReducer";
import BluetoothDevicesReducer from "./reducers/BluetoothDevicesReducer";
import BatteryStatusReducer from "./reducers/BatteryStatusReducer";
import SystemDetailsReducer from "./reducers/SystemDetailsReducer";
import AppsInstalledReducer from "./reducers/AppsInstalledReducer";
import NetworkConditionsReducer from "./reducers/NetworkConditionsReducer";
import NetworkSpeedReducer from "./reducers/NetworkSpeedReducer";
import SatelliteSignalReducer from "./reducers/SatelliteSignalReducer";
import DurationReducer from "./reducers/DurationReducer";
import AppBootCountReducer from "./reducers/AppBootCountReducer";
import ExceptionReducer from "./reducers/ExceptionReducer";
import DeviceStorageReducer from "./reducers/DeviceStorageReducer";
import SystemUsageReducer from "./reducers/SystemUsageReducer";
import exceptionRedirectionReducer from "./reducers/exceptionRedirectionReducer";
 import getNoSignalCountGraphReducer from "./reducers/NoSignalCountGraphReducer";
import TotalAgentVersionReducer from "./reducers/TotalAgentVersionReducer";

export default function configureStore() {
  return createStore(
    combineReducers({
      Sample,
      ThemeOptions: ThemeOptions,
      LocationInfoReducer,
      authReducer: AuthReducer,
      userListReducer: UserListReducer,
      StbCountReducer,
      calenderReducer: CalenderReducer,
      TotalLauncherReducer,
      TotalFirmwareVersionReducer,
      TotalAppVersionReducer,
      permissionReducer: RolesAndPermissionReducer,
      RolesReducer: RolesReducer,
      averageNetworkSpeedReducer: AverageNetworkSpeedReducer,
      averageBatteryStatusReducer: AverageBatteryStatusReducer,
      StbInfoBySidReducer,
      batteryGraphCdsnReducer: BatteryGraphCdsnReducer,
      networkGraphCdsnReducer: NetworkGraphCdsnReducer,
      totalDevicesAcrossIndiaReducer: TotalDevicesAcrossIndiaReducer,
      NetworkAvailabiltyGraphCdsnReducer: NetworkAvailabiltyGraphCdsnReducer,
      EntitlementReducer: EntitlementReducer,
      androidSettingsReducer: AndroidSettingsReducer,
      FetchInitiateReducer: FetchInitiateReducer,
      FetchControlStatusReducer: FetchControlStatusReducer,
      TifMonitoringReducer: TifMonitoringReducer,
      satelliteScanReducer: SatelliteScanReducer,
      myrecordsReducer: MyRecordsReducer,
      rcuKeyCodesReducer: RcuKeyCodesReducer,
      rcuKeyCodesStbRecuder: RcuKeyCodesStbRecuder,
      ControlEventsReducer: ControlEventsReducer,
      systemErrorsIndiaLevelReducer:SystemErrorsIndiaLevelReducer,
      systemErrorsIndiaLevelDashboardReducer:SystemErrorsIndiaLevelDashboardReducer,
      systemErrorsStbLevelReducer:SystemErrorsStbLevelReducer,
      tvResolutionsReducer: TVResoultionsReducer,
      BlockedAppsReducer:BlockedApplicationsReducer,
      remoteWizardGlobalLevelReducer:RemoteWizardGlobalLevelReducer,
      wizardSIDLevelReducers:WizardSIDLevelReducers,
      totalSyncDevicesReducer:TotalSyncDevicesReducer,
      bluetoothDevicesReducer:BluetoothDevicesReducer,
      batteryStatusReducer:BatteryStatusReducer,
      systemDetailsReducer:SystemDetailsReducer,
      appsInstalledReducer:AppsInstalledReducer,
      networkConditionsReducer:NetworkConditionsReducer,
      networkSpeedReducer:NetworkSpeedReducer,
      satelliteSignalReducer:SatelliteSignalReducer,
      durationReducer:DurationReducer,
      appBootCountReducer:AppBootCountReducer,
      exceptionReducer:ExceptionReducer,
      systemUsageReducer:SystemUsageReducer,
      deviceStorageReducer:DeviceStorageReducer,
      exceptionRedirectionReducer:exceptionRedirectionReducer,
      getNoSignalCountGraphReducer:getNoSignalCountGraphReducer,
      TotalAgentVersionReducer:TotalAgentVersionReducer,
    }),
    applyMiddleware(thunk)
  );
}
