import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    stbErrorIndiaLevelGraphloading:false,
    stbErrorIndiaLevelGraphData:[],
    stbErrorIndiaLevelGraphErrorMsg:[],
   // calendarData:[]
}

const SystemErrorsIndiaLevelReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_REQUEST: return getSystemErrorsIndiaLevelActionRequest(state, action);
        case ACTION.GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_SUCCESS: return getSystemErrorsIndiaLevelActionSuccess(state, action);
        case ACTION.GET_SYSTEM_ERRORS_INDIA_LEVEL_ACTION_FAILURE: return getSystemErrorsIndiaLevelActionFailure(state, action);
        
        case ACTION.GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_REQUEST: return getSystemErrorsIndiaLevelGraphActionRequest(state, action);
        case ACTION.GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_SUCCESS: return getSystemErrorsIndiaLevelGraphActionSuccess(state, action);
        case ACTION.GET_SYSTEM_ERRORS_INDIA_LEVEL_GRAPH_ACTION_FAILURE: return getSystemErrorsIndiaLevelGraphActionFailure(state, action);

        default: return state;
    }
}

const getSystemErrorsIndiaLevelActionRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getSystemErrorsIndiaLevelActionSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getSystemErrorsIndiaLevelActionFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const getSystemErrorsIndiaLevelGraphActionRequest = (state, action) => {
    return UpdateObject(state, {
        stbErrorIndiaLevelGraphloading: true,
        stbErrorIndiaLevelGraphErrorMsg:""
    });
}
const getSystemErrorsIndiaLevelGraphActionSuccess = (state, action) => {
    return UpdateObject(state, {
        stbErrorIndiaLevelGraphloading: false,
        stbErrorIndiaLevelGraphData: action.payload,
        stbErrorIndiaLevelGraphErrorMsg:""
    });
}
const getSystemErrorsIndiaLevelGraphActionFailure = (state, action) => {
    return UpdateObject(state, {
        stbErrorIndiaLevelGraphloading: false,
        stbErrorIndiaLevelGraphData:[],
        stbErrorIndiaLevelGraphErrorMsg: action.payload,
    });
}

export default SystemErrorsIndiaLevelReducer;