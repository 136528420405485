import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const SystemErrorsStbLevelReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_REQUEST: return getSystemErrorsStbLevelActionRequest(state, action);
        case ACTION.GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_SUCCESS: return getSystemErrorsStbLevelActionSuccess(state, action);
        case ACTION.GET_SYSTEM_ERRORS_STB_LEVEL_ACTION_FAILURE: return getSystemErrorsStbLevelActionFailure(state, action);
        
        default: return state;
    }
}

const getSystemErrorsStbLevelActionRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getSystemErrorsStbLevelActionSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getSystemErrorsStbLevelActionFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default SystemErrorsStbLevelReducer;