import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    locationInfo:[],
   // calendarData:[]
}

const LocationInfoReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_LOCATION_INFO_REQUEST: return getLocationInfoRequest(state, action);
        case ACTION.GET_LOCATION_INFO_SUCCESS: return getLocationInfoSuccess(state, action);
        case ACTION.GET_LOCATION_INFO_FAILURE: return getLocationInfoFailure(state, action);
        
        default: return state;
    }
}

const getLocationInfoRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getLocationInfoSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        locationInfo: action.payload,
    });
}
const getLocationInfoFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default LocationInfoReducer;