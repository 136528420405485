import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    satelliteSignalFetchNowControl:[],
    satelliteSignalFetchNowControlStatus:[],
    satelliteSignalFetchNowTime:null,
    satelliteSignalFetchNowTimerFlag:false,
   // calendarData:[]
   webhookFlag:false,
}

const SatelliteSignalReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_SATELLITE_SIGNAL_REQUEST: return getSatelliteSignalRequest(state, action);
        case ACTION.GET_SATELLITE_SIGNAL_SUCCESS: return getSatelliteSignalSuccess(state, action);
        case ACTION.GET_SATELLITE_SIGNAL_FAILURE: return getSatelliteSignalFailure(state, action);
        
        case ACTION.SATELLITE_SIGNAL_FETCH_NOW_CONTROL: return satelliteSignalFetchNowControl(state, action);
        case ACTION.SATELLITE_SIGNAL_FETCH_NOW_STATUS: return satelliteSignalFetchNowCotrolStatus(state, action);
        case ACTION.SATELLITE_SIGNAL_FETCH_NOW_CONTROL_TIMER_FLAG: return satelliteSignalFetchNowTimerFlag(state, action);

        case ACTION.SATELLITE_SIGNAL_FETCH_NOW_RESET: return satelliteSignalFetchNowReset(state, action);

        case ACTION.SATELLITE_SIGNAL_PROCESSED_WEBHOOK_TRIGGERED: return satelliteSignalProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const satelliteSignalProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getSatelliteSignalRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getSatelliteSignalSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getSatelliteSignalFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const satelliteSignalFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satelliteSignalFetchNowControl:[],
        satelliteSignalFetchNowControlStatus:[],
        satelliteSignalFetchNowTime:null,
        satelliteSignalFetchNowTimerFlag:false,
    });
}

const satelliteSignalFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satelliteSignalFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const satelliteSignalFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowSatelliteSignalStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        satelliteSignalFetchNowControlStatus: action.payload,
    });
}
const satelliteSignalFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satelliteSignalFetchNowTimerFlag: !state.satelliteSignalFetchNowTimerFlag,
    });
}

export default SatelliteSignalReducer;