import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getCpuUsageRequest = () => {
    return {
        type: ACTION.GET_CPU_USAGE_REQUEST
    }
}

const getCpuUsageSuccess = (payload) => {
    return {
        type: ACTION.GET_CPU_USAGE_SUCCESS,
        payload: payload,
    }
}

const getCpuUsageFailure = (errorMsg) => {
    return {
        type: ACTION.GET_CPU_USAGE_FAILURE,
        payload: errorMsg,
    }
}

const getMemoryUsageRequest = () => {
    return {
        type: ACTION.GET_MEMORY_USAGE_REQUEST
    }
}

const getMemoryUsageSuccess = (payload) => {
    return {
        type: ACTION.GET_MEMORY_USAGE_SUCCESS,
        payload: payload,
    }
}

const getMemoryUsageFailure = (errorMsg) => {
    return {
        type: ACTION.GET_MEMORY_USAGE_FAILURE,
        payload: errorMsg,
    }
}

export const cpuUsageFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.CPU_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const cpuUsageFetchNowControl = (payload)=>{
    return {
        type: ACTION.CPU_USAGE_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const cpuUsageFetchNowStatus = (payload) => {
    return {
        type: ACTION.CPU_USAGE_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const memoryUsageFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.MEMORY_USAGE_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const memoryUsageFetchNowControl = (payload)=>{
    return {
        type: ACTION.MEMORY_USAGE_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const memoryUsageFetchNowStatus = (payload) => {
    return {
        type: ACTION.MEMORY_USAGE_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const cpuUsageProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.CPU_USAGE_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const memoryUsageProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.MEMORY_USAGE_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getMemoryUsageData = (payload) => {
    const apiPath = "/KPI/STB/memoryUsage";
    return async function(dispatch) {
        dispatch(getMemoryUsageRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST",payload).then(res => {
            dispatch(getMemoryUsageSuccess(res));
        })
        .catch(err => {
            dispatch(getMemoryUsageFailure(err.message));
        });;
    };
};

export const getCpuUsageData = (payload) => {
    const apiPath = "/KPI/STB/cpuUsage";
    return async function(dispatch) {
        dispatch(getCpuUsageRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST",payload).then(res => {
            dispatch(getCpuUsageSuccess(res));
        })
        .catch(err => {
            dispatch(getCpuUsageFailure(err.message));
        });;
    };
};

export const postFetchNowMemoryUsageControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowMemoryUsageSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(memoryUsageFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(memoryUsageFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getMemoryUsageFailure(err.message));
        });
    }; 
};

export const postFetchNowCpuUsageControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowCpuUsageSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(cpuUsageFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(cpuUsageFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getCpuUsageFailure(err.message));
        });
    }; 
};