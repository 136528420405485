import React, { Fragment, useEffect, useState } from "react";
import { logout } from "../../../utils/utils";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
  Dropdown,
} from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { toast, Bounce } from "react-toastify";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import {
  faCalendarAlt,
  faAngleDown,
  faUserAlt,
  faAngleRight,
  faBell,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./UserBox.scss";
import { callAPI } from "../../../axios";

const UserBox = () => {

  useEffect(() => {
    let timer = null;
  
    const healthCheck = () => {
      callAPI("/health-check", "GET")
        .then()
        .catch((error) => {
          console.log(error);
          if(error?.response?.status===403){
            clearInterval(timer);
          };
        });
    };
  
    if (localStorage.getItem("jwt_token")) {
      timer = setInterval(() => {
       
          healthCheck();
      }, 5000);
    }
  }, []);
  
  // constructor(props) {
  //     super(props);

  //     this.state = {
  //         active: false,
  //         userId : JSON.parse(localStorage.getItem('user_id'))
  //     };
  // }
  const history = useHistory();
  let userId = JSON.parse(localStorage.getItem("user_id"))
  const [userDropdownMenu, setUserDropdownMenu] = useState(false);
  

  // notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
  //     transition: Bounce,
  //     closeButton: true,
  //     autoClose: 5000,
  //     position: 'bottom-center',
  //     type: 'success'
  // });
  const account = () =>  {
    history.push(
     {
     pathname:"/UserManagement/MyAccount",
     state:{sid:userId.sid}
  },
  toggleUserDropdownMenu()
  )
}

  const toggleUserDropdownMenu = () => {
    setUserDropdownMenu(!userDropdownMenu);
  };
  const collapseDropDown = () => {
    setUserDropdownMenu(false);
    history.push('/UserManagement/change-password')
  };
  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left d-flex ">
              {/* <div className="account-circulation mr-2"> */}
              {/* <Tooltip className="" title="Notifications">
                <NotificationsIcon className="notification account-circulation "/>
              </Tooltip> */}
              {/* </div> */}
              <Dropdown
                isOpen={userDropdownMenu}
                toggle={() => toggleUserDropdownMenu()}
              >
                <DropdownToggle color="link" className="mt-0 p-0">
                  <Tooltip title="My Profile">
                    {userDropdownMenu==false?<PersonIcon className="account-circulation"  />:<PersonIcon className="account-circulation" style={{color:"#6B00DD"}} />}
                  </Tooltip>
                </DropdownToggle>
                <DropdownMenu
                  end
                  className="rm-pointers dropdown-menu-lg p-0 profile-dropdown"
                >
                  <ul className="list-unstyled mb-0">
                    <li className="userbox-list cursor-pointer" onClick={account}>
                        <span>My Account</span>
                    </li>
                    <li className="border-top userbox-list cursor-pointer" onClick={collapseDropDown}>
                      {/* <Link to="/UserManagement/change-password"> */}
                        <span>Change Password</span>
                      {/* </Link> */}
                    </li>
                    <li className="border-top userbox-list cursor-pointer" onClick={logout}>
                        <span>Logout</span>
                    </li>
                  </ul>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    Alina Mclourd
                                </div>
                                <div className="widget-subheading">
                                    VP People Manager
                                </div>
                            </div> */}

            {/* <div className="widget-content-right header-user-info ml-3">
                                <Button className="btn-shadow p-1" size="sm" onClick={this.notify2} color="info"
                                        id="Tooltip-1">
                                    <FontAwesomeIcon className="mr-2 ml-2" icon={faCalendarAlt}/>
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={'Tooltip-1'}>
                                    Click for Toastify Notifications!
                                </UncontrolledTooltip>
                            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
