import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
    timerFlag:false,
    postDataResponse:[],
    postDataResponseStatus:[],
    postDataResponseTime:null,
    errorSatelliteSettingSave:[]
   // calendarData:[]
}

const SatelliteScanReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_SATELLITE_SCAN_REQUEST: return getSatelliteScanRequest(state, action);
        case ACTION.GET_SATELLITE_SCAN_SUCCESS: return getSatelliteScanSuccess(state, action);
        case ACTION.GET_SATELLITE_SCAN_FAILURE: return getSatelliteScanFailure(state, action);

        case ACTION.SATELLITE_SCAN_SAVE_SETTINGS_RESET: return satelliteScanSaveSettingsReset(state, action);

        case ACTION.POST_SATELLITE_SCAN_REQUEST: return postSatelliteScanRequest(state, action);
        case ACTION.POST_SATELLITE_SCAN_SUCCESS: return postSatelliteScanSuccess(state, action);
        case ACTION.POST_SATELLITE_SCAN_SAVE_FAILURE: return postSatelliteScanFailure(state, action);

        case ACTION.POST_SATELLITE_SCAN_SUCCESS_CONTROL_STATUS: return postSatelliteScanSuccessCotrolStatus(state, action);
        case ACTION.POST_SATELLITE_SCAN_TIMER_FLAG: return postSatelliteScanTimerFlag(state, action);
        
        default: return state;
    }
}

const getSatelliteScanRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getSatelliteScanSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const satelliteScanSaveSettingsReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        timerFlag:false,
        postDataResponse:[],
        postDataResponseStatus:[],
        postDataResponseTime:null
    });
}
const getSatelliteScanFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const postSatelliteScanRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const postSatelliteScanSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        postDataResponse: action.payload,
        postDataResponseTime:new Date().getTime()
    });
}
const postSatelliteScanSuccessCotrolStatus = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        postDataResponseStatus: action.payload,
    });
}
const postSatelliteScanTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        timerFlag: !state.timerFlag,
    });
}
const postSatelliteScanFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorSatelliteSettingSave: action.payload,
    });
}

export default SatelliteScanReducer;