import React, {Fragment} from 'react';
import logotext from "../../../src/assets/utils/images/tata-play-middle.png";
import logotv from "../../../src/assets/utils/images/tataplay-tv-image.png";
import logobottom from "../../../src/assets/utils/images/tataplay-bottom.png";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { MdOutlineRefresh } from "react-icons/md";
import { useState, useEffect } from "react";
import { BsEyeSlashFill } from "react-icons/bs";
import { BsEyeSlash } from "react-icons/bs";
import Axios from "axios";
import { useLocation,useHistory } from "react-router-dom";
import RctModel from '../Components/Modal/Examples/Modal';
import './NewPassword.scss';
    function CreateNewPassword() {
      const history = useHistory()
      const search = useLocation().search;
      const key = new URLSearchParams(search).get("key");
      const token = new URLSearchParams(search).get("token");
        const [confirmPasswordError, setConfirmPasswordError] = useState("");
        const [captchaImage, setCaptchaImage] = useState();
        const [captcha, setCaptcha]= useState("");
        const [confirmToggle, setConfirmToggle]=useState(true);
        const [toggle, setToggleEye]=useState(true);
        const [validateFlag, setValidateFlag] = useState(false); 
        const [newPassword, setNewPassword]= useState("");
        const [confirmPassword, setConfirmPassword]= useState("");
        const [validationError, setValidationError]=useState("");
        const [successresPonse, setSuccessResPonse] = useState("");
        const [showPopup, setShowPopup] = useState(false);
        const [errorRes, setErrorRes] = useState("");
        const [apiErrorResponse, setApiErrorResponse] = useState("");
        const [formValue, setformValue] = useState({
         password: "",
         confirmPassword: "",
         captchaText: "",
       });
    //Error Validation
       const validateError = () =>{
        formValue.password !==""
        ? setNewPassword("")
        : setNewPassword("Please Enter New Password");
      formValue.confirmPassword !==""
        ? setConfirmPassword("")
        : setConfirmPassword("Please Enter Confirm Password");
        formValue.captchaText !==""
        ? setCaptcha("")
        : setCaptcha("Please Enter Captcha Code");
    }
    useEffect(()=>{
        if(validateFlag){
          validateError()
        }
      },[validateFlag,formValue])
    //For validating Password Formate
    const validatePassword = () => {
        if (/^([a-zA-Z0-9-&@#$!])*$/.test(formValue.password) && formValue.password.length>=4) {
          setValidationError("");
        } else if (formValue.password == "") {
          setValidationError("");
        } else {
          setValidationError("Enter minimum 4 characters password in correct format(Alphanumeric and @,#,$-! allowed)");
        }
      };
      useEffect(() => {
        validatePassword();
      }, [formValue.password]);

    //onChange event handling
    const handleChange = (e) => {
        setformValue({
          ...formValue,
          [e.target.id]: e.target.value,
        });
      };
      //For Resetting Password
      const onResetPassword = (e) => {
        e.preventDefault();
        setValidateFlag(true)
        // setClickOnValidate(true);
        
         if (formValue.password ===""||
         formValue.confirmPassword ==="" || formValue.captchaText==="") {
           return;
         }
         if (formValue.password !== formValue.confirmPassword) {
            setConfirmPasswordError("Password and confirm password not matched");
           return ;
         }
        //  setShowPopup(true);   
         const requestHeaders = {
          headers: {
            Authorization: `Bearer ${token}`,
            
          },
          withCredentials: true
        };
        const data = {
          key: key,
          password: formValue.password,
          captchaText:formValue.captchaText
        };
        //
         Axios.put(
          `${process.env.REACT_APP_BASE_URL}/user/create-password`,
          data,
          requestHeaders,
        )
          .then(function(response) {
            setShowPopup(true);
            setSuccessResPonse(response.data?.message);
          })
          .catch(function(error) {
            console.log(error)
            // setShowPopup(true);
            setErrorRes("")
            setApiErrorResponse(error.response?.data?.errorMessage);
          });
       };

    //For Getting Captcha
      const getCapcha = () => {
        Axios.get(`${process.env.REACT_APP_BASE_URL}/captcha`, {
          responseType: "blob", withCredentials: true
        })
          .then((response) => {
            const urlData = window.URL.createObjectURL(response.data);
            setCaptchaImage(urlData);
          })
          .then((error) => {
            // console.log(error);
            setErrorRes(error.response?.data?.errorMessage);
          });
      };
     
      useEffect(() => {
        getCapcha();
      }, []);
      //For Togling eye
      const newConfirmPasswordEye = ()=>{
        setConfirmToggle(!confirmToggle)
      }
      const newPasswordEye = ()=>{
        setToggleEye(!toggle)
    }
    const navigatetoSignin = () =>{
      setShowPopup(false)
      history.push("/login")
    }
    return (
        <Fragment>
    <div className="login-container">
    <div className="left-panel text-center">
          <div >
          <img className="left-panel-tv-logo" src={logotv} alt="Logo" />
          </div>
          <div className="left-panel-tata">
          <img   src={logotext} alt="Logo" />
          </div>
          <div className="left-panel-shape" >
          <img  src={logobottom} alt="Logo" />
          </div>
        </div>
        {/* <img className="login-logo" src={logo} alt="Logo" />   */}
        <div className='right-panel d-flex align-items-center'> 
        <ReactCSSTransitionGroup
                component="div"
                className='form-wraper'
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                  <h1 className='card-title mb-1'>
                  Reset Account Password
                  </h1>
                  <p className="text-center mb-3">
                  Your new password must be different from <br/> previous used passwords.
                    </p>
                  {apiErrorResponse && <div className="mb-3 required">
                      <span className="d-block">{apiErrorResponse}</span>
                    </div>}
                  {errorRes && errorRes != "" ? (
                    <div className="mb-0 required">
                      <span className="d-block">{errorRes}</span>
                    </div>
                  ) : ""}
                        <Form>
                            <Row form style={{marginTop:"70"}} >
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="password">New Password</Label>
                                        {!toggle?<BsEyeSlashFill className="on-off-eye cursor-pointer" onClick={newPasswordEye}/>:<BsEyeSlash onClick={newPasswordEye} className="on-off-eye cursor-pointer"/>}
                                        <Input 
                                         value={formValue.password}
                                         onChange={handleChange}
                                         id="password"
                                         type={toggle ? 'password':'text'}
                                         placeholder="Enter password"/>
                                          {validationError?<p className="validationerror text-left">{validationError}</p>:newPassword?<p className="validationerror text-left">{newPassword}</p>:<p className="validationerror text-left">{confirmPasswordError}</p>}
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label for="confirmPassword">Confirm New Password</Label>
                                        {!confirmToggle?<BsEyeSlashFill className="on-off-eye cursor-pointer" onClick={newConfirmPasswordEye}/>:<BsEyeSlash onClick={newConfirmPasswordEye} className="on-off-eye cursor-pointer"/>}
                                        <Input
                                         value={formValue.confirmPassword}
                                         type={confirmToggle ? 'password':'text'}
                                         onChange={handleChange}
                                         id="confirmPassword"
                                         placeholder="Enter Confirm password"/>
                                         <p className="validationerror text-left" >{confirmPassword}</p>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className='mt-2' for="captchaText">Captcha Code</Label>
                                        <Input 
                                         type="text" 
                                         name="captchaText" 
                                         id="captchaText"
                                         onChange={handleChange}
                                         value={formValue.captchaText}
                                         placeholder="Enter Captcha Code"/>
                                         <p className="validationerror text-left" >{captcha}</p>
                                    </FormGroup>
                                </Col>
                                {/* <Label for="captchaImage"  className='ml-3'>Captcha Image<span className="validationerror">*</span></Label> */}
                                <Col md={12}>
                                    <FormGroup className='mb-1'>
                                         <div className="align-items-center d-flex">
                                         <div><img src={captchaImage} className='captcha-image' /></div>
                                         <div className="ml-3 text-white cursor-pointer" onClick={getCapcha}><MdOutlineRefresh className="cursor-pointer" style={{fontSize:25}} title='Refresh Captcha'/>Refresh</div>
                                         </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button className="Sign-in mb-3" onClick={onResetPassword} >Create New Password</Button>
                            <RctModel
                                isOpen={showPopup}
                                footer={{
                                secondaryButton: {
                                action:navigatetoSignin,
                                name: 'Login',
                                addClass:"btn create-password-button login-button"
                                }
                                }}
                                >
                                  <p>{successresPonse}</p>
                                  <p className="tag-style">Please login with your new password.</p>
                                   <span className='d-block'><img src="/Images/create-icon.png" style={{width:100,marginTop:"30px",marginBottom:"15px"}} /></span>
                            </RctModel>


                        </Form>  
            </ReactCSSTransitionGroup> 
                </div>
    </div>
</Fragment>
    )
}
export default CreateNewPassword;