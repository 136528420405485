import React from "react";
import "./ErrorBoundaryFallBack.scss";

function ErrorBoundaryFallBack() {
  return (
    <div className="boundary-element">
      <div className="boundary-text">
        <p>
          Something went wrong! Go back, or try clicking the refresh button to
          reload the application.
        </p>
        <p>If the issue persist Contact Admin Team.</p>
      </div>
      {/* <button
        className={`my-3 mr-4 rounded-pill createuser refresh-cache`}
        onClick={resetErrorBoundary}
      >
        Refresh Page
      </button> */}
    </div>
  );
}

export default ErrorBoundaryFallBack;
