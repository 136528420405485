import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getNetworkConditionsRequest = () => {
    return {
        type: ACTION.GET_NETWORK_CONDITIONS_REQUEST
    }
}

const getNetworkConditionsSuccess = (apiData) => {
    return {
        type: ACTION.GET_NETWORK_CONDITIONS_SUCCESS,
        payload: apiData,
    }
}

const getNetworkConditionsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_NETWORK_CONDITIONS_FAILURE,
        payload: errorMsg,
    }
}

export const networkConditionsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const networkConditionsFetchNowControl = (payload)=>{
    return {
        type: ACTION.NETWORK_CONDITIONS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const networkConditionsFetchNowStatus = (payload) => {
    return {
        type: ACTION.NETWORK_CONDITIONS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const networkConditionsProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.NETWORK_CONDITIONS_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getNetworkConditionsData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/networkConditions/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getNetworkConditionsRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getNetworkConditionsSuccess(res));
        })
        .catch(err => {
            dispatch(getNetworkConditionsFailure(err.message));
        });;
    };
};

export const postFetchNowNetworkConditionsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowNetworkConditionsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(networkConditionsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(networkConditionsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getNetworkConditionsFailure(err.message));
        });
    }; 
};