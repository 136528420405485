import * as ACTION from './actionTypes';

export const wizardControlRequestReceivedStatus = (payload) => {
    return {
        type: ACTION.WIZARD_CONTROL_REQUEST_RECEIVED,
        payload: payload,
    }
}

export const wizardControlRequestProcessedStatus = (payload) => {
    return {
        type: ACTION.WIZARD_CONTROL_REQUEST_PROCESSED,
        payload: payload,
    }
}