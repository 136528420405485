import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const TotalLauncherReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_TOTAL_LAUNCHER_CRASHES_REQUEST: return getTotalLauncherCrashesRequest(state, action);
        case ACTION.GET_TOTAL_LAUNCHER_CRASHES_SUCCESS: return getTotalLauncherCrashesSuccess(state, action);
        case ACTION.GET_TOTAL_LAUNCHER_CRASHES_FAILURE: return getTotalLauncherCrashesFailure(state, action);
        
        default: return state;
    }
}

const getTotalLauncherCrashesRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getTotalLauncherCrashesSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getTotalLauncherCrashesFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: [],
        errorMsg: action.payload,
    });
}

export default TotalLauncherReducer;