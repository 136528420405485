import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getMyRecordsRequest = () => {
    return {
        type: ACTION.GET_MY_RECORDS_REQUEST
    }
}
const getMyRecordsSuccess = (apiData) => {
    return {
        type: ACTION.GET_MY_RECORDS_SUCCESS,
        payload: apiData,
    }
}
const getMyRecordsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_MY_RECORDS_FAILURE,
        payload: errorMsg,
    }
}

export const myRecordsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.MY_RECORDS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const myRecordsFetchNowControl = (payload)=>{
    return {
        type: ACTION.MY_RECORDS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const myRecordsFetchNowStatus = (payload) => {
    return {
        type: ACTION.MY_RECORDS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const myRecordsFetchNowReset = (state, action) => {
    return {
        type: ACTION.MY_RECORDS_FETCH_NOW_RESET
    }
}

export const myRecordsProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.MY_RECORDS_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getMyRecordsData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/cdvrRecordings/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getMyRecordsRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getMyRecordsSuccess(res));
        })
        .catch(err => {
            dispatch(getMyRecordsFailure(err.message));
        });;
    };
};

export const postFetchNowMyRecordsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowMyRecordsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(myRecordsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(myRecordsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(myRecordsFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getMyRecordsFailure(err.message));
        });
    }; 
};