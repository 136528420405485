import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
    entitlementStatus:[],
    appStatus:[],
    networkConditionStatus:[],
    networkSpeedStatus:[],
    batteryStatus:[],
    satelliteSignalStatus:[],
    bluetoothSignalStatus:[],
    andoridSettingsStatus:[],
    systemDetailsStatus:[],
    myRecordsStatus:[],
    tifMonitoringStatus:[]
}

const FetchControlStatusReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_FETCH_NOW_STATUS_REQUEST: return getFetchNowContolStatusRequest(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_SUCCESS: return getFetchNowContolStatusSuccess(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_FAILURE: return getFetchNowContolStatusFailure(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_ENTITLEMENTS: return getFetchNowContolStatusEntitlements(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_APPS: return getFetchNowContolStatusApps(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_NETWORK_CONDITION: return getFetchNowContolStatusNetworkCondition(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_NETWORK_SPEED: return getFetchNowContolStatusNetworkSpeed(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_BATTERY: return getFetchNowContolStatusBattery(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_SATELLITE_SIGNAL: return getFetchNowContolStatusSatelliteSignal(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_BLUETOOTH: return getFetchNowContolStatusBluetooth(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_ANDORID_SETTINGS: return getFetchNowContolStatusAndoridSettings(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_SYSTEM_DETAILS: return getFetchNowContolStatusSysyemDetais(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_MY_RECORDS: return getFetchNowContolStatusMyRecords(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_TIF_MONITORING: return getFetchNowContolStatusTifMonitoring(state, action);
        case ACTION.GET_FETCH_NOW_STATUS_RESET: return getFetchNowContolStatusReset(state, action);
        default: return state;
    }
}

const getFetchNowContolStatusRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}

const getFetchNowContolStatusReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: '',
        data:[],
        entitlementStatus:[],
        appStatus:[],
        networkConditionStatus:[],
        networkSpeedStatus:[],
        batteryStatus:[],
        satelliteSignalStatus:[],
        bluetoothSignalStatus:[],
        andoridSettingsStatus:[],
        systemDetailsStatus:[],
        myRecordsStatus:[],
        tifMonitoringStatus:[]
    });
}
const getFetchNowContolStatusSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getFetchNowContolStatusEntitlements = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        entitlementStatus: action.payload,
    });
}
const getFetchNowContolStatusApps = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        appStatus: action.payload,
    });
}
const getFetchNowContolStatusNetworkCondition = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkConditionStatus: action.payload,
    });
}
const getFetchNowContolStatusNetworkSpeed = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        networkSpeedStatus: action.payload,
    });
}
const getFetchNowContolStatusBattery = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryStatus: action.payload,
    });
}
const getFetchNowContolStatusSatelliteSignal = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        satelliteSignalStatus: action.payload,
    });
}
const getFetchNowContolStatusBluetooth = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        bluetoothSignalStatus: action.payload,
    });
}
const getFetchNowContolStatusAndoridSettings = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        andoridSettingsStatus: action.payload,
    });
}
const getFetchNowContolStatusSysyemDetais = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        systemDetailsStatus: action.payload,
    });
}

const getFetchNowContolStatusMyRecords = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        myRecordsStatus: action.payload,
    });
}

const getFetchNowContolStatusTifMonitoring = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tifMonitoringStatus: action.payload,
    });
}

const getFetchNowContolStatusFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default FetchControlStatusReducer;