import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getAppBootTimeRequest = () => {
    return {
        type: ACTION.GET_APP_BOOT_TIME_REQUEST
    }
}

const getAppBootTimeSuccess = (payload) => {
    return {
        type: ACTION.GET_APP_BOOT_TIME_SUCCESS,
        payload: payload,
    }
}

const getAppBootTimeFailure = (errorMsg) => {
    return {
        type: ACTION.GET_APP_BOOT_TIME_FAILURE,
        payload: errorMsg,
    }
}

const getViewingTimeRequest = () => {
    return {
        type: ACTION.GET_VIEWING_TIME_REQUEST
    }
}

const getViewingTimeSuccess = (payload) => {
    return {
        type: ACTION.GET_VIEWING_TIME_SUCCESS,
        payload: payload,
    }
}

const getViewingTimeFailure = (errorMsg) => {
    return {
        type: ACTION.GET_VIEWING_TIME_FAILURE,
        payload: errorMsg,
    }
}

const getZapTimeRequest = () => {
    return {
        type: ACTION.GET_ZAP_TIME_REQUEST
    }
}

const getZapTimeSuccess = (payload) => {
    return {
        type: ACTION.GET_ZAP_TIME_SUCCESS,
        payload: payload,
    }
}

const getZapTimeFailure = (errorMsg) => {
    return {
        type: ACTION.GET_ZAP_TIME_FAILURE,
        payload: errorMsg,
    }
}

export const appBootTimeFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.APP_BOOT_TIME_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const appBootTimeFetchNowControl = (payload)=>{
    return {
        type: ACTION.APP_BOOT_TIME_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const appBootTimeFetchNowStatus = (payload) => {
    return {
        type: ACTION.APP_BOOT_TIME_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const zapTimeFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.ZAP_TIME_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const zapTimeFetchNowControl = (payload)=>{
    return {
        type: ACTION.ZAP_TIME_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const zapTimeFetchNowStatus = (payload) => {
    return {
        type: ACTION.ZAP_TIME_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const viewingTimeFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.VIEWING_TIME_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const viewingTimeFetchNowControl = (payload)=>{
    return {
        type: ACTION.VIEWING_TIME_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const viewingTimeFetchNowStatus = (payload) => {
    return {
        type: ACTION.VIEWING_TIME_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const zapTimeProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.ZAP_TIME_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const viewingTimeProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.VIEWING_TIME_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getAppBootTimeData = (payload) => {
    const apiPath = "/KPI/STB/networkAvailability";
    return async function(dispatch) {
        dispatch(getAppBootTimeRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST",payload).then(res => {
            dispatch(getAppBootTimeSuccess(res));
        })
        .catch(err => {
            dispatch(getAppBootTimeFailure(err.message));
        });;
    };
};

export const getViewingTimeData = (payload) => {
    const apiPath = "/KPI/STB/totalViewingTime";
    return async function(dispatch) {
        dispatch(getViewingTimeRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST",payload).then(res => {
            dispatch(getViewingTimeSuccess(res));
        })
        .catch(err => {
            dispatch(getViewingTimeFailure(err.message));
        });;
    };
};

export const getZapTimeData = (payload) => {
    const apiPath = "/KPI/STB/averageZapTime";
    return async function(dispatch) {
        dispatch(getZapTimeRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST",payload).then(res => {
            dispatch(getZapTimeSuccess(res));
        })
        .catch(err => {
            dispatch(getZapTimeFailure(err.message));
        });;
    };
};

export const postFetchNowAppBootTimeControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowAppBootTimeSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(appBootTimeFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(appBootTimeFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getAppBootTimeFailure(err.message));
        });
    }; 
};

export const postFetchNowViewingTimeControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowViewingTimeSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(viewingTimeFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(viewingTimeFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getViewingTimeFailure(err.message));
        });
    }; 
};

export const postFetchNowZapTimeControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowZapTimeSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(zapTimeFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(zapTimeFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getZapTimeFailure(err.message));
        });
    }; 
};