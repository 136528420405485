import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const TotalFirmwareVersionReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_TOTAL_FIRMWARE_VERSION_REQUEST: return getTotalFirmwareVersionRequest(state, action);
        case ACTION.GET_TOTAL_FIRMWARE_VERSION_SUCCESS: return getTotalFirmwareVersionSuccess(state, action);
        case ACTION.GET_TOTAL_FIRMWARE_VERSION_FAILURE: return getTotalFirmwareVersionFailure(state, action);
        
        default: return state;
    }
}

const getTotalFirmwareVersionRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getTotalFirmwareVersionSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getTotalFirmwareVersionFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: [],
        errorMsg: action.payload,
    });
}

export default TotalFirmwareVersionReducer;