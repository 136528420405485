import * as ACTION from './actionTypes';
import {callAPI} from "../../axios"


const getMasterPermissionsRequest = () => {

    return {

        type: ACTION.GET_MASTER_PERMISSIONS_FAILURE

    }

}



const getMasterPermissionsSuccess = (userToken) => {

    return {

        type: ACTION.GET_MASTER_PERMISSIONS_SUCCESS,

        payload: userToken,

    }

}



const getMasterPermissionsFailure = (errorMsg) => {

    return {

        type: ACTION.GET_MASTER_PERMISSIONS_FAILURE,

        payload: errorMsg,

    }

}



export const getMasterPermissions = () => {

    const apiPath = `/master/permission`;

    return async function(dispatch) {

        dispatch(getMasterPermissionsRequest());

        callAPI(apiPath,"GET").then(res => {

            dispatch(getMasterPermissionsSuccess(res));
            // console.log("getPermissions",res)

        })

        .catch(err => {

            dispatch(getMasterPermissionsFailure(err.message));

        });;

    };

}