import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    tvResolutionsData:[],
   // calendarData:[]
}

const TVResoultionsReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_TV_RESOULTIONS_REQUEST: return getTVResoultionsRequest(state, action);
        case ACTION.GET_TV_RESOULTIONS_SUCCESS: return getTVResoultionsSuccess(state, action);
        case ACTION.GET_TV_RESOULTIONS_FAILURE: return getTVResoultionsFailure(state, action);
        
        default: return state;
    }
}

const getTVResoultionsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getTVResoultionsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        tvResolutionsData: action.payload,
    });
}
const getTVResoultionsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default TVResoultionsReducer;