import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getAppsRequest = () => {
    return {
        type: ACTION.GET_APPS_REQUEST
    }
}

const getAppsSuccess = (apiData) => {
    return {
        type: ACTION.GET_APPS_SUCCESS,
        payload: apiData,
    }
}

const getAppsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_APPS_FAILURE,
        payload: errorMsg,
    }
}

export const appsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.APPS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const appsFetchNowControl = (payload)=>{
    return {
        type: ACTION.APPS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const appsFetchNowStatus = (payload) => {
    return {
        type: ACTION.APPS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const appsProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.APPS_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getAppsData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/appsInstalled/${sid}/${cdsn}`;
    const appVersionApiPath = `/globalConfig/latestAppVersion`
    return async function(dispatch) {
        dispatch(getAppsRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${appVersionApiPath}`,"GET").then(appVersionResponse => {
                const updatedResponse = res.value?.map((appDetail) => {
                    const foundApp = appVersionResponse?.find((browsableApps) => appDetail.appPackage === browsableApps.packageName && appDetail.browsable===true && browsableApps.versionCode>appDetail.versionCode);
                    if (foundApp) {
                        return { updateAvailable: true, updateVersionCode:foundApp?.versionCode, ...appDetail};
                    } else {
                        return { updateAvailable: false, ...appDetail};
                    }
                });
                dispatch(getAppsSuccess({...res,value:updatedResponse}));
            })
            .catch(err => {
                dispatch(getAppsFailure(err.message));
            });
            
        })
        .catch(err => {
            dispatch(getAppsFailure(err.message));
        });;
    };
};

export const postFetchNowAppsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowAppsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(appsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(appsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(bluetoothDevicesFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getAppsFailure(err.message));
        });
    }; 
};