import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import React, { Suspense, Fragment, useState, useEffect } from "react";
import routes from "../../routes";
import { RouteLoader } from "../RouteLoader";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "../../Pages/Components/Modal/Examples/Modal";
import Login from "../../Pages/Login";
import ForgotPassword from "../../Pages/ForgotPassword/ForgotPassword";
import CreateNewPassword from "../../Pages/CreateNewPassword/CreateNewPassword";
import Signup from "../../Pages/Signup/Signup";
import { logout } from "../../utils/utils";
import SockJsClient from "react-stomp";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { postTifMonitoringActionsControlStatus } from "../../store/actions/TifMonitoringAction";
import {
  RebootStbControlStatus,
  postFactoryResetEventsControlStatus,
  postAppKillEventsControlStatus,
  postAppClearDataEventsControlStatus,
  postAppClearCacheEventsControlStatus,
  postCaptureScreenShotEventControlStatus,
  postResetParentalPinEventControlStatus,
  postAppGenerateLogsEventsControlStatus,
  postSignalStrengthQualityEventControlStatus,
  postPlayContentEventControlStatus,
  postUninstallAppsEventControlStatus,
  postKillAppsEventControlStatus
} from "../../store/actions/ControlEventsActions";
import { zapTimeFetchNowStatus, viewingTimeFetchNowStatus } from "../../store/actions/DurationActions";
import { cpuUsageFetchNowStatus, memoryUsageFetchNowStatus } from "../../store/actions/SystemUsageAction";
import { deviceStorageFetchNowStatus } from "../../store/actions/DeviceStorageAction";
import { postSatelliteScanSuccessControlStatus } from "../../store/actions/SatelliteScanAction";
import {
  globalControlStatus
} from "../../store/actions/StbInfoBySidAction";
import { satelliteSignalFetchNowStatus } from "../../store/actions/SatelliteSignalAction";
import { entitlementsFetchNowStatus } from "../../store/actions/EntitlementAction";
import { networkSpeedFetchNowStatus } from "../../store/actions/NetworkSpeedAction";
import { networkConditionsFetchNowStatus } from "../../store/actions/NetworkConditionsAction";
import { appsFetchNowStatus } from "../../store/actions/AppsInstalledAction";
import { systemDetailsFetchNowStatus } from "../../store/actions/SystemDetailsAction";
import { batteryStatusFetchNowStatus } from "../../store/actions/BatteryStatusAction";
import { tifMonitoringFetchNowStatus } from "../../store/actions/TifMonitoringAction";
import { bluetoothDevicesFetchNowStatus } from "../../store/actions/BluetoothDeviceAction";
import { androidSettingsFetchNowStatus } from "../../store/actions/AndroidSettingsAction";
import { myRecordsFetchNowStatus } from "../../store/actions/MyRecordsAction";
import { systemDetailsAdbStatus } from "../../store/actions/SystemDetailsAction";
import { wizardControlRequestReceivedStatus, wizardControlRequestProcessedStatus } from "../../store/actions/WizardSIDLevelActions";
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"
import PageNotFound from "../PageNotFound/PageNotFound";

const AppMain = () => {
  const dispatch = useDispatch();
  const location = useLocation(); 
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorFlag429, setErrorFlag429] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  // const [webSocketPopup, setWebSocketPopup] = useState(false);
  // const [webSocketMessage, setWebSocketMessage] = useState(false);
  const isLoggedIn = localStorage.getItem("jwt_token");
  let localStorageUserId = JSON.parse(localStorage.getItem("user_id"));

  
  const notify = (webSocketMessage) => {
      if(webSocketMessage.status==="processed" && webSocketMessage.action==="resetPin"){
        callAPI("/sms","POST",{
          "mobileNumber": localStorage.getItem("RegisteredNumber"),
          "sid": webSocketMessage.SID,
          "cdsn": webSocketMessage.CDSN
        }).then((response) => {
          toast(response.message, {
            closeButton: true,
            autoClose: 6000,
            position: 'top-right',
            type: 'success'
          });
        })
        .catch((error) => {
          console.log(error.response?.data?.errorMessage)
        });
      }
      if(webSocketMessage.status==="processed"){
        toast(<div>
          <h5 className="d-flex justify-content-center text-success">Processed</h5>
          <p>{`${webSocketMessage.wizardInfo?"Wizard":""} Control Action ${webSocketMessage.action=="beacon"?"entitlement":webSocketMessage.action} for SID- ${webSocketMessage.SID} & CDSN- ${webSocketMessage.CDSN} requested by ${webSocketMessage.userName}`}</p>
          </div>, 
        {
          closeButton: true,
          autoClose: 6000,
          position: 'top-right',
          type: 'success'
        });
      }
      else if(webSocketMessage.status==="received"){
        toast(<div>
          <h5 className="d-flex justify-content-center text-info">Received</h5>
          <p>{`${webSocketMessage.wizardInfo?"Wizard":""} Control Action ${webSocketMessage.action=="beacon"?"entitlement":webSocketMessage.action} for SID- ${webSocketMessage.SID} & CDSN- ${webSocketMessage.CDSN} requested by ${webSocketMessage.userName}`}</p>
          </div>, 
        {
          closeButton: true,
          autoClose: 6000,
          position: 'top-right',
          type: 'info'
        });
      }
      else if(webSocketMessage.status==="failed_reception") {
        toast(<div>
          <h5 className="d-flex justify-content-center text-danger">Failed</h5>
          <p>{`${webSocketMessage.wizardInfo?"Wizard":""} Control Action ${webSocketMessage.action=="beacon"?"Entitlement":webSocketMessage?.action} for SID- ${webSocketMessage.SID} & CDSN- ${webSocketMessage.CDSN} requested by ${webSocketMessage.userName}. As box is not connected to Internet! please try again`}</p>
        </div>, {
          closeButton: true,
          autoClose: 6000,
          position: 'top-right',
          type: 'error'
        });
      }
      else {
        toast(<div>
          <h5 className="d-flex justify-content-center text-danger">Failed</h5>
          <p>{`${webSocketMessage.wizardInfo?"Wizard":""} Control Action ${webSocketMessage.action=="beacon"?"Entitlement":webSocketMessage?.action} for SID- ${webSocketMessage.SID} & CDSN- ${webSocketMessage.CDSN} requested by ${webSocketMessage.userName}. Please try again`}</p>
        </div>,
         {
          closeButton: true,
          autoClose: 6000,
          position: 'top-right',
          type: 'error'
        });
    }
  }
  
  window.addEventListener("401_Error", (e) => {
    setErrorFlag(true);
    setApiMessage(e.detail);
  });
  window.addEventListener("429_error", (e) => {
    setErrorFlag429(true);
    setApiMessage(e.detail);
  });
  window.addEventListener("500_error", (e) => {
    setErrorFlag429(true);
    setApiMessage(e.detail);
  });
  // window.addEventListener("403_Error", (e)=>{
  //     setErrorFlag(true)
  //     setApiMessage(e.detail)
  //     })

  useEffect(() => {
    if(isLoggedIn){
    // if (localStorage.getItem("accedo_url") !== "https://d2jq06bpblikah.cloudfront.net/v1") {
    //   localStorage.setItem("accedo_url", "https://d2jq06bpblikah.cloudfront.net/v1");
    // }
    // if(JSON.parse(localStorage.getItem("AppLogoResponse"))?.version!=="2023.24.4-1.17.11 PM"){
    //   callAccedoAPI(`${localStorage.getItem("accedo_url")}/appLogo`, "GET").then(
    //     (res) =>localStorage.setItem("AppLogoResponse",JSON.stringify(res))
    //   ).catch(err =>console.log(err.message));
    // }
    if(JSON.parse(localStorage.getItem("AppConfigControlResponse"))?.body?.ConfigData?.version!=="2023.18.5-3.27.06 PM"){
      callAccedoAPI(`${localStorage.getItem("accedo_url")}/config?type=control`,"GET")
        .then((response) => localStorage.setItem("AppConfigControlResponse",JSON.stringify(response)))
        .catch((error) =>console.log(error));
    }
    if(JSON.parse(localStorage.getItem("AppConfigPeriodicResponse"))?.body?.ConfigData?.version!=="2023.18.5-3.27.06 PM"){
      callAccedoAPI(`${localStorage.getItem("accedo_url")}/config?type=periodic`,"GET")
        .then((response) => localStorage.setItem("AppConfigPeriodicResponse",JSON.stringify(response)))
        .catch((error) =>console.log(error));
    }
  }
  },[]);
  
  const onSocketResponse = (data) => {
    if(isLoggedIn){
      if (data != undefined) {
        if (data.controlRequestID && data.action && data.status) {
          if((data.userName).toLowerCase() ===(localStorageUserId.userName).toLowerCase()){
            // console.log(data)
            const pageUrl= `/wizardStatus/${data.SID}/${data.CDSN}/${data?.wizardInfo?.wizardId}`
            if(data.status==="received" && data.wizardInfo){
              if(location.pathname.includes(pageUrl)){
                notify(data)
                dispatch(wizardControlRequestReceivedStatus(data))
              }
              else if(!location.pathname.includes("wizardStatus")){
                notify(data)
              }
            }else if ((data.status==="processed" || data.status==="failed") && data.wizardInfo){
              if(location.pathname.includes(pageUrl)){
                notify(data)
                dispatch(wizardControlRequestProcessedStatus(data))
              }
              else if(!location.pathname.includes("wizardStatus")){
                notify(data)
              }
            }
              setTimeout(()=>{
                if (data?.action === "rebuildEPGCache") {
                  dispatch(postTifMonitoringActionsControlStatus(data));
                } else if (data?.action === "satelliteSignalScan") {
                  dispatch(postSatelliteScanSuccessControlStatus(data));
                } else if (data?.action === "beacon") {
                  dispatch(entitlementsFetchNowStatus(data));
                } else if (data?.action === "appsInstalled") {
                  dispatch(appsFetchNowStatus(data));
                } else if (data?.action === "batteryStatus") {
                  dispatch(batteryStatusFetchNowStatus(data));
                } else if (data?.action === "networkSpeed") {
                  dispatch(networkSpeedFetchNowStatus(data));
                } else if (data?.action === "networkConditions") {
                  dispatch(networkConditionsFetchNowStatus(data));
                } else if (data?.action === "bluetoothDevices") {
                  dispatch(bluetoothDevicesFetchNowStatus(data));
                } else if (data?.action === "satelliteSignal") {
                  dispatch(satelliteSignalFetchNowStatus(data));
                } else if (data?.action === "systemDetails") {
                  dispatch(systemDetailsFetchNowStatus(data));
                } else if (data?.action === "tifStatus") {
                  dispatch(tifMonitoringFetchNowStatus(data));
                } else if (data?.action === "androidSettings") {
                  dispatch(androidSettingsFetchNowStatus(data));
                } else if (data?.action === "cdvrRecording") {
                  dispatch(myRecordsFetchNowStatus(data));
                } else if (data?.action === "rebootSTB") {
                  dispatch(RebootStbControlStatus(data));
                } else if (data?.action === "factoryReset") {
                  dispatch(postFactoryResetEventsControlStatus(data));
                } else if (data?.action === "killLauncherApp") {
                  dispatch(postAppKillEventsControlStatus(data));
                } else if (data?.action === "clearLauncherAppData") {
                  dispatch(postAppClearDataEventsControlStatus(data));
                } else if (data?.action === "clearLauncherAppCache") {
                  dispatch(postAppClearCacheEventsControlStatus(data));
                } else if (data?.action === "captureScreenShot") {
                  dispatch(postCaptureScreenShotEventControlStatus(data));
                } else if (data?.action === "resetPin") {
                  dispatch(postResetParentalPinEventControlStatus(data));
                } else if (data?.action === "deviceLogCollection") {
                  dispatch(postAppGenerateLogsEventsControlStatus(data));
                } else if (data?.action === "signalStrengthMonitoring") {
                  dispatch(postSignalStrengthQualityEventControlStatus(data));
                } else if (data?.action === "playContent") {
                  dispatch(postPlayContentEventControlStatus(data));
                } else if (data?.action === "viewingTime") {
                  dispatch(viewingTimeFetchNowStatus(data));
                } else if (data?.action === "zapTime") {
                  dispatch(zapTimeFetchNowStatus(data));
                }else if (data?.action === "appUninstall") {
                  dispatch(postUninstallAppsEventControlStatus(data));
                }else if (data?.action === "appKill") {
                  dispatch(postKillAppsEventControlStatus(data));
                }else if (data?.action === "deviceStorage") {
                  dispatch(deviceStorageFetchNowStatus(data));
                }else if (data?.action === "cpuUsage") {
                  dispatch(cpuUsageFetchNowStatus(data));
                }else if (data?.action === "memoryUsage") {
                  dispatch(memoryUsageFetchNowStatus(data));
                }else if (data?.action === "adbEnable") {
                  dispatch(systemDetailsAdbStatus(data));
                }
              // if(data.status==="failed"){
                dispatch(globalControlStatus(data));
              // }
              if(data.status && data.wizardInfo===null){
                if(!location.pathname.includes("wizardStatus")){
                  notify(data)
                }
              }
            },1000)
          }
          // setWebSocketPopup(true);
          // dispatch(globalControlStatus(data));
          // setWebSocketMessage(data);
        }
      }
    }
  };

  // const onRoleChange = (data) =>{
  //   if(data?.userSid==localStorageUserId?.sid){
  //     const selectionFired = new CustomEvent("401_Error", {
  //       "detail": "User Role/Privileges changed! Please login again" });
  //     window.dispatchEvent(selectionFired);
  //   }
  //   else if (data?.roleSid==localStorageUserId?.roles[0].id){
  //     const selectionFired = new CustomEvent("401_Error", {
  //       "detail": "User Role/Privileges changed! Please login again" });
  //     window.dispatchEvent(selectionFired);
  //   }
  // }

  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/"
          render={() =>
            localStorage.getItem("jwt_token") ? <Redirect to="/dashboards/home" /> : <Redirect to="/login" />
          }
        />

        <Route path="/login" component={Login} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/createpassword" component={CreateNewPassword} />
        <Route path="/signup" component={Signup} />

        {localStorage.getItem("jwt_token") ? (
          <Suspense fallback={<RouteLoader />}>
            <Switch>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} component={route.component} />
              ))}
              <Route path="*" component={PageNotFound}/>
            </Switch>
          </Suspense>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
      <ToastContainer className='w-25'/>
      {localStorage.getItem("jwt_token") ? (
        <SockJsClient
          url={`${process.env.REACT_APP_BASE_URL}/stb-ws`}
          topics={[`/topic/fetch-now-msg`]}
          headers={{ Authorization: `Bearer ${localStorage.getItem("jwt_token")}` }}
          autoReconnect={true}
          onMessage={onSocketResponse}
        />
      ) : (
        ""
      )}
      {/* {localStorage.getItem("jwt_token") ? (
        <SockJsClient
          url={`${process.env.REACT_APP_BASE_URL}/stb-ws`}
          topics={[`/topic/privilege-change`]}
          headers={{ Authorization: `Bearer ${localStorage.getItem("jwt_token")}` }}
          autoReconnect={true}
          onMessage={onRoleChange}
        />
      ) : (
        ""
      )} */}
      <Modal
        isOpen={errorFlag}
        footer={{
          secondaryButton: {
            action: () => logout(),
            name: "OK",
            addClass: "btn btn-success px-4",
          },
        }}
      >
        <span className="d-block">
          <img alt="rejected" src="/Images/Rejected.png" style={{ width: 60 }} />
        </span>
        <br />
        <p>{apiMessage}</p>
      </Modal>
      <Modal
        isOpen={errorFlag429}
        footer={{
          secondaryButton: {
            action: () => setErrorFlag429(false),
            name: "OK",
            addClass: "btn btn-success px-4",
          },
        }}
      >
        <span className="d-block">
          <img alt="rejected" src="/Images/Rejected.png" style={{ width: 60 }} />
        </span>
        <br />
        <p>{apiMessage}</p>
      </Modal>
      {/* <Modal
        isOpen={webSocketPopup}
        footer={{
          secondaryButton: {
            action: () => setWebSocketPopup(false),
            name: "OK",
            addClass: "btn btn-success px-4",
          },
        }}
      >
        {webSocketMessage.status === "processed" ? (
          <div>
            <span className="d-block">
              <img alt="approve-qc" src="../Images/approve-qc.png" style={{ width: 60 }} />
            </span>
            <br />
            <p>{`Control Action ${webSocketMessage.action} for SID- ${webSocketMessage.SID} & CDSN- ${webSocketMessage.CDSN} requested by ${webSocketMessage.userName} completed succesfully`}</p>
          </div>
        ) : (
          <div>
            <span className="d-block">
              <img alt="rejected" src="../Images/Rejected.png" style={{ width: 60 }} />
            </span>
            <br />
            <p>{`Control Action ${webSocketMessage.action} for SID- ${webSocketMessage.SID} & CDSN- ${webSocketMessage.CDSN} requested by ${webSocketMessage.userName} failed ! please try again`}</p>
          </div>
        )}
      </Modal> */}
    </Fragment>
  );
};

export default AppMain;
