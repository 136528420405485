import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],

    batteryStatusFetchNowControl:[],
    batteryStatusFetchNowControlStatus:[],
    batteryStatusFetchNowTime:null,
    batteryStatusFetchNowTimerFlag:false,
   // calendarData:[]
   webhookFlag:false,
}

const BatteryStatusReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_BATTERY_STATUS_REQUEST: return getBatteryStatusRequest(state, action);
        case ACTION.GET_BATTERY_STATUS_SUCCESS: return getBatteryStatusSuccess(state, action);
        case ACTION.GET_BATTERY_STATUS_FAILURE: return getBatteryStatusFailure(state, action);
        
        case ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL: return batteryStatusFetchNowControl(state, action);
        case ACTION.BATTERY_STATUS_FETCH_NOW_STATUS: return batteryStatusFetchNowCotrolStatus(state, action);
        case ACTION.BATTERY_STATUS_FETCH_NOW_CONTROL_TIMER_FLAG: return batteryStatusFetchNowTimerFlag(state, action);

        case ACTION.BATTERY_STATUS_FETCH_NOW_RESET: return batteryStatusFetchNowReset(state, action);

        case ACTION.BATTERY_STATUS_PROCESSED_WEBHOOK_TRIGGERED: return batteryStatusProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const batteryStatusProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getBatteryStatusRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getBatteryStatusSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getBatteryStatusFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

const batteryStatusFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryStatusFetchNowControl:[],
        batteryStatusFetchNowControlStatus:[],
        batteryStatusFetchNowTime:null,
        batteryStatusFetchNowTimerFlag:false,
    });
}

const batteryStatusFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryStatusFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const batteryStatusFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowBatteryStatusStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        loading: false,
        batteryStatusFetchNowControlStatus: action.payload,
    });
}
const batteryStatusFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        batteryStatusFetchNowTimerFlag: !state.batteryStatusFetchNowTimerFlag,
    });
}

export default BatteryStatusReducer;