import  { lazy} from 'react';
// import ISPDetails from '../Pages/ISPDetails';
// import TotalLauncherCrash from '../Pages/TotalLauncherCrash';
//  import NoSignalCountData from '../Pages/NoSignalCount';

const Dashboards = lazy(() => import('../Pages/Dashboards'));
const Elements = lazy(() => import('../Pages/Elements'));
const Components = lazy(() => import('../Pages/Components'));
const Charts = lazy(() => import('../Pages/Charts'));
const UserManagement = lazy(() => import('../Pages/UserManagement'));
const SIDCDSNLevelsDetails = lazy(() => import('../Pages/SIDCDSNLevels'));
const EventsCaptured = lazy(() => import('../Pages/EventsCaptured'));
const TotalDevicesAcrossIndia = lazy(() => import('../Pages/TotalDevicesAcrossIndia'));
const BlockedApplications = lazy(() => import('../Pages/BlockedApplications'));
const RemoteWizard = lazy(() => import('../Pages/RemoteWizard'));
const WizardExecutionStatusPage = lazy(() => import('../Pages/WizardExecutionStatus'));
const GlobalEvents = lazy(() => import('../Pages/GlobalEvents'));
const AppVersion = lazy(() => import('../Pages/AppVersion'));
const EntitlementMismatch = lazy(() => import('../Pages/SyncFailed'));
const TotalLauncherCrash = lazy(() => import('../Pages/TotalLauncherCrash'));
const NoSignalCountData = lazy(() => import('../Pages/NoSignalCount'));
const ISPDetails = lazy(() => import('../Pages/ISPDetails'));


const routes = [

    { path: "/components", name: "Components", component: Components },
    { path: "/dashboards", name: "Dashboards", component: Dashboards },
    { path: "/elements", name: "Elements", component: Elements },
    { path: "/charts", name: "Charts", component: Charts },
    { path: "/UserManagement", name: "UserList", component: UserManagement },
    { path: "/SIDCDSNLevels", name: "SIDCDSN", component: SIDCDSNLevelsDetails },
    { path: "/EventsCaptured", name: "EventsCaptured", component: EventsCaptured },
    { path: "/total-devices-across-india", name: "TotalDevicesAcrossIndia", component: TotalDevicesAcrossIndia },
    { path: "/blocked-applications", name: "BlockedApplications", component: BlockedApplications },
    { path: "/wizard", name: "RemoteWizard", component: RemoteWizard },
    { path: "/global-events", name: "GlobalEvents", component: GlobalEvents },
    { path: "/wizardStatus/:sid/:cdsn/:id/:name", name: "RemoteWizardStatus", component: WizardExecutionStatusPage },
    { path: "/appVersion", name: "AppVersion", component: AppVersion },
    { path: "/entitlementMismatch", name: "Entitlement Mismatch", component: EntitlementMismatch },
    { path: "/total-launcher-crash", name: "Total Launcher Crash", component: TotalLauncherCrash },
    { path: "/no-signal-count", name: "No Signal Count", component: NoSignalCountData },
    { path: "/ISPs-Details", name: "ISP's Details", component: ISPDetails },
  ];
  
  export default routes;
  