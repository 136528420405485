import * as ACTION from "../actions/actionTypes";
import { UpdateObject } from "../../utils/utils";

const initialState = {
  loading: false,
  errorMsg: "",
  data: [],

  rebootStbTimerFlag: false,
  rebootStbEventsResponse: [],
  rebootStbEventsResponseStatus: [],
  rebootStbEventsError:[],

  factoryResetTimerFlag: false,
  FactoryResetEventsResponse: [],
  FactoryResetResponseStatus: [],
  factoryResetError:[],

  appKillTimerFlag: false,
  AppKillEventsResponse: [],
  AppKillResponseStatus: [],
  appKillError:[],

  appDataTimerFlag: false,
  ClearDataEventsResponse: [],
  ClearDataResponseStatus: [],
  clearDataError:[],

  appCacheTimerFlag: false,
  ClearCacheEventsResponse: [],
  ClearCacheResponseStatus: [],
  clearCacheError:[],

  captureScreenShotTimerFlag: false,
  captureScreenShotEventsResponse: [],
  captureScreenShotResponseStatus: [],
  captureScreenShotError:[],

  resetParentalPinTimerFlag: false,
  resetParentalPinEventsResponse: [],
  resetParentalPinResponseStatus: [],
  resetParentalPinError:[],

  generateLogsTimerFlag: false,
  GenerateLogsEventsResponse: [],
  GenerateLogsResponseStatus: [],
  generateLogsError:[],

  signalStrengthQualityTimerFlag: false,
  signalStrengthQualityEventResponse: [],
  signalStrengthQualityResponseStatus: [],
  signalStrengthQualityError:[],

  playContentTimerFlag: false,
  playContentEventResponse: [],
  playContentResponseStatus: [],
  playContentError:[],

  uninstallAppsTimerFlag: false,
  uninstallAppsEventResponse: [],
  uninstallAppsResponseStatus: [],
  uninstallAppsError:[],

  killAppsTimerFlag: false,
  killAppsEventResponse: [],
  killAppsResponseStatus: [],
  killAppsError:[],
};

const ControlEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_CONTROL_ACTION_REQUEST: return getControlActionRequest(state, action);
    case ACTION.GET_CONTROL_ACTION_SUCCESS: return getControlActionSuccess(state, action);
    case ACTION.GET_CONTROL_ACTION_FAILURE: return getControlActionFailure(state, action);

    case ACTION.POST_CONTROL_EVENT_CONTROL: return postRebootStbControl(state, action);
    case ACTION.GET_POST_CONTROL_EVENT_CONTROL_STATUS: return RebootStbControlStatus(state, action);
    case ACTION.CONTROL_EVENT_TIMER_FLAG: return rebootStbTimerFlag(state, action);
    case ACTION.RESET_CONTROL_EVENT_BUTTON: return resetControlEventButton(state, action);
    //Factory Reset
    case ACTION.POST_FACTORY_RESET_CONTROL: return postFactoryResetControlEvent(state, action);
    case ACTION.GET_POST_FACTORY_RESET_CONTROL_STATUS: return postFactoryResetEventsControlStatus(state, action);
    case ACTION.FACTORY_RESET_TIMER_FLAG: return factoryResetTimerFlag(state, action);

    case ACTION.RESET_FACTORY_RESET_BUTTON: return resetControlEventButton(state, action);
    //Laucher App Kill
    case ACTION.POST_APP_KILL_CONTROL: return postAppKillControlEvent(state, action);
    case ACTION.GET_POST_APP_KILL_CONTROL_STATUS: return postAppKillEventsControlStatus(state, action);
    case ACTION.APP_KILL_TIMER_FLAG: return AppKillTimerFlag(state, action);

    case ACTION.RESET_APP_KILL_BUTTON: return resetControlEventButton(state, action);
    //Laucher App Data
    case ACTION.POST_APP_DATA_CONTROL: return postAppClearDataControlEvent(state, action);
    case ACTION.GET_POST_APP_DATA_CONTROL_STATUS: return postAppClearDataEventsControlStatus(state, action);
    case ACTION.APP_DATA_TIMER_FLAG: return AppClearDataTimerFlag(state, action);

    case ACTION.RESET_APP_DATA_BUTTON: return resetControlEventButton(state, action);
    //Laucher App Cache
    case ACTION.POST_APP_CACHE_CONTROL: return postAppClearCacheControlEvent(state, action);
    case ACTION.GET_POST_APP_CACHE_CONTROL_STATUS: return postAppClearCacheEventsControlStatus(state, action);
    case ACTION.APP_CACHE_TIMER_FLAG: return AppClearCacheTimerFlag(state, action);

    case ACTION.RESET_APP_CACHE_BUTTON: return resetControlEventButton(state, action);

    //Capture Screenshot
    case ACTION.POST_CAPTURE_SCREEN_SHOT_CONTROL: return postCaptureScreenShotControlEvent(state, action);
    case ACTION.POST_CAPTURE_SCREEN_SHOT_CONTROL_STATUS: return postCaptureScreenShotEventControlStatus(state, action);
    case ACTION.CAPTURE_SCREEN_SHOT_TIMER_FLAG: return captureScreenShotTimerFlag(state, action);
    
    //Reset Parental Pin
    case ACTION.POST_RESET_PARENTAL_PIN_CONTROL: return postResetParentalPinControlEvent(state, action);
    case ACTION.POST_RESET_PARENTAL_PIN_CONTROL_STATUS: return postResetParentalPinEventControlStatus(state, action);
    case ACTION.RESET_PARENTAL_PIN_TIMER_FLAG: return resetParentalPinTimerFlag(state, action);   

    //Generate Logs
    case ACTION.POST_GENERATE_LOGS_CONTROL: return postAppGenerateLogsControlEvent(state, action);
    case ACTION.GET_POST_APP_GENERATE_LOGS_STATUS: return postAppGenerateLogsEventsControlStatus(state, action);
    case ACTION.GENERATE_LOGS_TIMER_FLAG: return GenerateLogsTimerFlag(state, action);

    case ACTION.RESET_GENERATE_LOGS_BUTTON: return resetControlEventButton(state, action);

    //Signal Strength Quality 
    case ACTION.POST_SIGNAL_STRENGTH_QUALITY_CONTROL: return postSignalStrengthQualityControlEvent(state, action);
    case ACTION.GET_SIGNAL_STRENGTH_QUALITY_STATUS: return postSignalStrengthQualityEventControlStatus(state, action);
    case ACTION.SIGNAL_STRENGTH_QUALITY_TIMER_FLAG: return signalStrengthQualityTimerFlag(state, action);

    //Play content
    case ACTION.POST_PLAY_CONTENT_CONTROL: return postPlayContentControlEvent(state, action);
    case ACTION.GET_PLAY_CONTENT_STATUS: return postPlayContentEventControlStatus(state, action);
    case ACTION.PLAY_CONTENT_TIMER_FLAG: return playContentTimerFlag(state, action);
    case ACTION.POST_PLAY_CONTENT_FAILURE: return postPlayContentButtonFailure(state, action);

    //Uninstall Apps
    case ACTION.POST_UNINSTALL_APPS_CONTROL: return postUninstallAppsControlEvent(state, action);
    case ACTION.GET_UNINSTALL_APPS_STATUS: return postUninstallAppsEventControlStatus(state, action);
    case ACTION.UNINSTALL_APPS_TIMER_FLAG: return uninstallAppsTimerFlag(state, action);
    case ACTION.POST_UNINSTALL_APPS_FAILURE: return postUninstallAppsButtonFailure(state, action);

    //Kill Apps
    case ACTION.POST_KILL_APPS_CONTROL: return postKillAppsControlEvent(state, action);
    case ACTION.GET_KILL_APPS_STATUS: return postKillAppsEventControlStatus(state, action);
    case ACTION.KILL_APPS_TIMER_FLAG: return killAppsTimerFlag(state, action);
    case ACTION.POST_KILL_APPS_FAILURE: return postKillAppsButtonFailure(state, action);

    case ACTION.POST_REBOOT_STB_FAILURE: return postRebootStbButtonFailure(state, action);
    case ACTION.POST_FACTORY_RESET_STB_FAILURE: return postFactoryResetButtonFailure(state, action);
    case ACTION.POST_APP_KILL_STB_FAILURE: return postAppKillButtonFailure(state, action);
    case ACTION.POST_APP_CLEAR_DATA_FAILURE: return postAppClearDataButtonFailure(state, action);
    case ACTION.POST_APP_CLEAR_CACHE_FAILURE: return postAppClearCacheButtonFailure(state, action);
    case ACTION.POST_CAPTURE_SCREEN_SHOT_FAILURE: return postCaptureScreenShotButtonFailure(state, action);
    case ACTION.POST_RESET_PARENTAL_PIN_FAILURE: return postResetParentalPinButtonFailure(state, action);
    case ACTION.POST_APP_GENERATE_LOGS_FAILURE: return postAppGenerateLogsButtonFailure(state, action);

    case ACTION.POST_SIGNAL_STRENGTH_QUALITY_FAILURE: return postSignalStrengthQualityButtonFailure(state, action);

    default:
      return state;
  }
};

//Reebot Stb
const resetControlEventButton = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    rebootStbTimerFlag: false,
    rebootStbEventsResponse: [],
    rebootStbEventsResponseStatus: [],
    // RebootStbEventsResponseTime: null,
    rebootStbEventsError:[],

    factoryResetTimerFlag: false,
    FactoryResetEventsResponse: [],
    FactoryResetResponseStatus: [],
    // FactoryResetResponseTime: null,
    factoryResetError:[],

    appKillTimerFlag: false,
    AppKillEventsResponse: [],
    AppKillResponseStatus: [],
    // AppKillResponseTime: null,
    appKillError:[],

    appDataTimerFlag: false,
    ClearDataEventsResponse: [],
    ClearDataResponseStatus: [],
    // ClearDataResponseTime: null,
    clearDataError:[],

    appCacheTimerFlag: false,
    ClearCacheEventsResponse: [],
    ClearCacheResponseStatus: [],
    // ClearCacheResponseTime: null,
    clearCacheError:[],

    captureScreenShotTimerFlag: false,
    captureScreenShotEventsResponse: [],
    captureScreenShotResponseStatus: [],
    // ClearCacheResponseTime: null,
    captureScreenShotError:[],

    resetParentalPinTimerFlag: false,
    resetParentalPinEventsResponse: [],
    resetParentalPinResponseStatus: [],
    // ClearCacheResponseTime: null,
    resetParentalPinError:[],

    generateLogsTimerFlag: false,
    GenerateLogsEventsResponse: [],
    GenerateLogsResponseStatus: [],
    // GenerateLogsResponseTime: null,
    generateLogsError:[],

    signalStrengthQualityTimerFlag: false,
    signalStrengthQualityEventResponse: [],
    signalStrengthQualityResponseStatus: [],
    signalStrengthQualityError:[],

    playContentTimerFlag: false,
    playContentEventResponse: [],
    playContentResponseStatus: [],
    playContentError:[],

    uninstallAppsTimerFlag: false,
    uninstallAppsEventResponse: [],
    uninstallAppsResponseStatus: [],
    uninstallAppsError:[],

    killAppsTimerFlag: false,
    killAppsEventResponse: [],
    killAppsResponseStatus: [],
    killAppsError:[],
  });
};

//Control History Actions
const getControlActionRequest = (state,action) => {
  return UpdateObject(state, {
      loading: true,
  });
}
const getControlActionSuccess = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      data: action.payload,
  });
}
const getControlActionFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      errorMsg: action.payload,
  });
}

///Reboot Stb
const postRebootStbControl = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    rebootStbEventsResponse: action.payload,
    // RebootStbEventsResponseTime: new Date().getTime(),
  });
};
const RebootStbControlStatus = (state, action) => {
  localStorage.setItem(`RebootStbStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    rebootStbEventsResponseStatus: action.payload,
  });
};
const rebootStbTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    rebootStbTimerFlag: !state.rebootStbTimerFlag,
  });
};
const postRebootStbButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      rebootStbEventsError: action.payload,
  });
}

///Factory Reset
const postFactoryResetControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    FactoryResetEventsResponse: action.payload,
    // FactoryResetResponseTime: new Date().getTime(),
  });
};
const postFactoryResetEventsControlStatus = (state, action) => {
  localStorage.setItem(`FactoryResetStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    FactoryResetResponseStatus: action.payload,
  });
};
const factoryResetTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    factoryResetTimerFlag: !state.factoryResetTimerFlag,
  });
};
const postFactoryResetButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      factoryResetError: action.payload,
  });
}

///Launcher App Kill
const postAppKillControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    AppKillEventsResponse: action.payload,
    // AppKillResponseTime: new Date().getTime(),
  });
};
const postAppKillEventsControlStatus = (state, action) => {
  localStorage.setItem(`AppKillStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    AppKillResponseStatus: action.payload,
  });
};
const AppKillTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    appKillTimerFlag: !state.appKillTimerFlag,
  });
};
const postAppKillButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      appKillError: action.payload,
  });
}

///Launcher App Data
const postAppClearDataControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    ClearDataEventsResponse: action.payload,
    // ClearDataResponseTime: new Date().getTime(),
  });
};
const postAppClearDataEventsControlStatus = (state, action) => {
  localStorage.setItem(`AppClearDataStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    ClearDataResponseStatus: action.payload,
  });
};
const AppClearDataTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    appDataTimerFlag: !state.appDataTimerFlag,
  });
};
const postAppClearDataButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      clearDataError: action.payload,
  });
}

///Launcher App Cache
const postAppClearCacheControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    ClearCacheEventsResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postAppClearCacheEventsControlStatus = (state, action) => {
  localStorage.setItem(`AppClearCacheStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    ClearCacheResponseStatus: action.payload,
  });
};
const AppClearCacheTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    appCacheTimerFlag: !state.appCacheTimerFlag,
  });
};
const postAppClearCacheButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      clearCacheError: action.payload,
  });
}

/// Capture Screenshot
const postCaptureScreenShotControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    captureScreenShotEventsResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postCaptureScreenShotEventControlStatus = (state, action) => {
  localStorage.setItem(`CaptureScreenShotStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    captureScreenShotResponseStatus: action.payload,
  });
};
const captureScreenShotTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    captureScreenShotTimerFlag: !state.captureScreenShotTimerFlag,
  });
};
const postCaptureScreenShotButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      captureScreenShotError: action.payload,
  });
}

/// Reset Parental Pin
const postResetParentalPinControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    resetParentalPinEventsResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postResetParentalPinEventControlStatus = (state, action) => {
  localStorage.setItem(`ResetParentalPinStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    resetParentalPinResponseStatus: action.payload,
  });
};
const resetParentalPinTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    resetParentalPinTimerFlag: !state.resetParentalPinTimerFlag,
  });
};
const postResetParentalPinButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      resetParentalPinError: action.payload,
  });
}

///Generate Logs
const postAppGenerateLogsControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    GenerateLogsEventsResponse: action.payload,
    // GenerateLogsResponseTime: new Date().getTime(),
  });
};
const postAppGenerateLogsEventsControlStatus = (state, action) => {
  localStorage.setItem(`GenerateLogsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    GenerateLogsResponseStatus: action.payload,
  });
};
const GenerateLogsTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    generateLogsTimerFlag: !state.generateLogsTimerFlag,
  });
};
const postAppGenerateLogsButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      generateLogsError: action.payload,
  });
}

/// Signal Strength Quality
const postSignalStrengthQualityControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    signalStrengthQualityEventResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postSignalStrengthQualityEventControlStatus = (state, action) => {
  localStorage.setItem(`SignalStrengthQualityStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    signalStrengthQualityResponseStatus: action.payload,
  });
};
const signalStrengthQualityTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    signalStrengthQualityTimerFlag: !state.signalStrengthQualityTimerFlag,
  });
};
const postSignalStrengthQualityButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      signalStrengthQualityError: action.payload,
  });
}

/// Play content
const postPlayContentControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    playContentEventResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postPlayContentEventControlStatus = (state, action) => {
  localStorage.setItem(`PlayContentStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    playContentResponseStatus: action.payload,
  });
};
const playContentTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    playContentTimerFlag: !state.playContentTimerFlag,
  });
};
const postPlayContentButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      playContentError: action.payload,
  });
}

/// Uninstall Apps
const postUninstallAppsControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    uninstallAppsEventResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postUninstallAppsEventControlStatus = (state, action) => {
  localStorage.setItem(`UninstallAppsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    uninstallAppsResponseStatus: action.payload,
  });
};
const uninstallAppsTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    uninstallAppsTimerFlag: !state.uninstallAppsTimerFlag,
  });
};
const postUninstallAppsButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      uninstallAppsError: action.payload,
  });
}

/// Kill Apps
const postKillAppsControlEvent = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    killAppsEventResponse: action.payload,
    // ClearCacheResponseTime: new Date().getTime(),
  });
};
const postKillAppsEventControlStatus = (state, action) => {
  localStorage.setItem(`KillAppsStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
  return UpdateObject(state, {
    loading: false,
    killAppsResponseStatus: action.payload,
  });
};
const killAppsTimerFlag = (state, action) => {
  return UpdateObject(state, {
    loading: false,
    killAppsTimerFlag: !state.killAppsTimerFlag,
  });
};
const postKillAppsButtonFailure = (state, action) => {
  return UpdateObject(state, {
      loading: false,
      killAppsError: action.payload,
  });
}

export default ControlEventsReducer;


