import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    // appBootTimeloading: false,
    viewingTimeloading: false,
    zapTimeloading: false,

    errorMsg: '',
    
    // appBootTimeData:[],
    viewingTimeData:[],
    zapTimeData:[],
   // calendarData:[]

    // appBootTimeFetchNowControl:[],
    // appBootTimeFetchNowControlStatus:[],
    // appBootTimeFetchNowTime:null,
    // appBootTimeFetchNowTimerFlag:false,

    viewingTimeFetchNowControl:[],
    viewingTimeFetchNowControlStatus:[],
    viewingTimeFetchNowTime:null,
    viewingTimeFetchNowTimerFlag:false,

    viewingTimeWebhookFlag:false,

    zapTimeFetchNowControl:[],
    zapTimeFetchNowControlStatus:[],
    zapTimeFetchNowTime:null,
    zapTimeFetchNowTimerFlag:false,

    zapTimeWebhookFlag:false,
}

const DurationReducer = (state = initialState, action) => {
    switch(action.type) {
       
        // case ACTION.GET_APP_BOOT_TIME_REQUEST: return getAppBootTimeRequest(state, action);
        // case ACTION.GET_APP_BOOT_TIME_SUCCESS: return getAppBootTimeSuccess(state, action);
        // case ACTION.GET_APP_BOOT_TIME_FAILURE: return getAppBootTimeFailure(state, action);
        
        case ACTION.GET_VIEWING_TIME_REQUEST: return getViewingTimeRequest(state, action);
        case ACTION.GET_VIEWING_TIME_SUCCESS: return getViewingTimeSuccess(state, action);
        case ACTION.GET_VIEWING_TIME_FAILURE: return getViewingTimeFailure(state, action);

        case ACTION.GET_ZAP_TIME_REQUEST: return getZapTimeRequest(state, action);
        case ACTION.GET_ZAP_TIME_SUCCESS: return getZapTimeSuccess(state, action);
        case ACTION.GET_ZAP_TIME_FAILURE: return getZapTimeFailure(state, action);

        // case ACTION.APP_BOOT_TIME_FETCH_NOW_CONTROL: return appBootTimeFetchNowControl(state, action);
        // case ACTION.APP_BOOT_TIME_FETCH_NOW_STATUS: return appBootTimeFetchNowCotrolStatus(state, action);
        // case ACTION.APP_BOOT_TIME_FETCH_NOW_CONTROL_TIMER_FLAG: return appBootTimeFetchNowTimerFlag(state, action);
        // case ACTION.APP_BOOT_TIME_FETCH_NOW_RESET: return appBootTimeFetchNowReset(state, action);

        case ACTION.VIEWING_TIME_FETCH_NOW_CONTROL: return viewingTimeFetchNowControl(state, action);
        case ACTION.VIEWING_TIME_FETCH_NOW_STATUS: return viewingTimeFetchNowCotrolStatus(state, action);
        case ACTION.VIEWING_TIME_FETCH_NOW_CONTROL_TIMER_FLAG: return viewingTimeFetchNowTimerFlag(state, action);
        case ACTION.VIEWING_TIME_FETCH_NOW_RESET: return viewingTimeFetchNowReset(state, action);

        case ACTION.VIEWING_TIME_PROCESSED_WEBHOOK_TRIGGERED: return viewingTimeProcessedWebhookTriggeredReducer(state, action);

        case ACTION.ZAP_TIME_FETCH_NOW_CONTROL: return zapTimeFetchNowControl(state, action);
        case ACTION.ZAP_TIME_FETCH_NOW_STATUS: return zapTimeFetchNowCotrolStatus(state, action);
        case ACTION.ZAP_TIME_FETCH_NOW_CONTROL_TIMER_FLAG: return zapTimeFetchNowTimerFlag(state, action);
        case ACTION.ZAP_TIME_FETCH_NOW_RESET: return zapTimeFetchNowReset(state, action);

        case ACTION.ZAP_TIME_PROCESSED_WEBHOOK_TRIGGERED: return zapTimeProcessedWebhookTriggeredReducer(state, action);

        default: return state;
    }
}

// const getAppBootTimeRequest = (state, action) => {
//     return UpdateObject(state, {
//         appBootTimeloading: true,
//     });
// }
// const getAppBootTimeSuccess = (state, action) => {
//     return UpdateObject(state, {
//         appBootTimeloading: false,
//         appBootTimeData: action.payload,
//     });
// }
// const getAppBootTimeFailure = (state, action) => {
//     return UpdateObject(state, {
//         appBootTimeloading: false,
//         errorMsg: action.payload,
//     });
// }

const viewingTimeProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        viewingTimeWebhookFlag:!state.viewingTimeWebhookFlag
    });
}

const zapTimeProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        zapTimeWebhookFlag:!state.zapTimeWebhookFlag
    });
}

const getViewingTimeRequest = (state, action) => {
    return UpdateObject(state, {
        viewingTimeloading: true,
    });
}
const getViewingTimeSuccess = (state, action) => {
    return UpdateObject(state, {
        viewingTimeloading: false,
        viewingTimeData: action.payload,
    });
}
const getViewingTimeFailure = (state, action) => {
    return UpdateObject(state, {
        viewingTimeloading: false,
        errorMsg: action.payload,
    });
}

const getZapTimeRequest = (state, action) => {
    return UpdateObject(state, {
        zapTimeloading: true,
    });
}
const getZapTimeSuccess = (state, action) => {
    return UpdateObject(state, {
        zapTimeloading: false,
        zapTimeData: action.payload,
    });
}
const getZapTimeFailure = (state, action) => {
    return UpdateObject(state, {
        zapTimeloading: false,
        errorMsg: action.payload,
    });
}

// const appBootTimeFetchNowReset = (state, action) => {
//     return UpdateObject(state, {
//         appBootTimeloading: false,
//         appBootTimeFetchNowControl:[],
//         appBootTimeFetchNowControlStatus:[],
//         appBootTimeFetchNowTime:null,
//         appBootTimeFetchNowTimerFlag:false,
//     });
// }

// const appBootTimeFetchNowControl = (state, action) => {
//     return UpdateObject(state, {
//         appBootTimeloading: false,
//         appBootTimeFetchNowControl: action.payload,
//         // androidSettingsFetchNowTime:new Date().getTime()
//     });
// }
// const appBootTimeFetchNowCotrolStatus = (state, action) => {
//     localStorage.setItem(`FetchNowAppBootTimeStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
//     return UpdateObject(state, {
//         appBootTimeloading: false,
//         appBootTimeFetchNowControlStatus: action.payload,
//     });
// }
// const appBootTimeFetchNowTimerFlag = (state, action) => {
//     return UpdateObject(state, {
//         appBootTimeloading: false,
//         appBootTimeFetchNowTimerFlag: !state.appBootTimeFetchNowTimerFlag,
//     });
// }

const viewingTimeFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        viewingTimeloading: false,
        viewingTimeFetchNowControl:[],
        viewingTimeFetchNowControlStatus:[],
        viewingTimeFetchNowTime:null,
        viewingTimeFetchNowTimerFlag:false,
    });
}

const viewingTimeFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        viewingTimeloading: false,
        viewingTimeFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const viewingTimeFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowViewingTimeStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        viewingTimeloading: false,
        viewingTimeFetchNowControlStatus: action.payload,
    });
}
const viewingTimeFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        viewingTimeloading: false,
        viewingTimeFetchNowTimerFlag: !state.viewingTimeFetchNowTimerFlag,
    });
}

const zapTimeFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        zapTimeloading: false,
        zapTimeFetchNowControl:[],
        zapTimeFetchNowControlStatus:[],
        zapTimeFetchNowTime:null,
        zapTimeFetchNowTimerFlag:false,
    });
}

const zapTimeFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        zapTimeloading: false,
        zapTimeFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const zapTimeFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowZapTimeStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        zapTimeloading: false,
        zapTimeFetchNowControlStatus: action.payload,
    });
}
const zapTimeFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        zapTimeloading: false,
        zapTimeFetchNowTimerFlag: !state.zapTimeFetchNowTimerFlag,
    });
}

export default DurationReducer;