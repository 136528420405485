import React, {Fragment} from 'react';
import logotext from "../../../src/assets/utils/images/tata-play-middle.png";
import logotv from "../../../src/assets/utils/images/tataplay-tv-image.png";
import logobottom from "../../../src/assets/utils/images/tataplay-bottom.png";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Link } from "react-router-dom"; 
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { MdOutlineRefresh } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import './ForgotStyle.scss';
import RctModel from '../Components/Modal/Examples/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
    function ForgotPassword() {
      const history = useHistory();
    const [errorEmail, setErrorEmail] = useState("");
    const [captchaImage, setCaptchaImage] = useState();
    const [errorResponse, setErrorResponse] = useState("");
    const [apiSuccessMessage, setApiSuccessMessage]=useState("");
    const [clickOnValidate, setClickOnValidate] = useState(false);
    const [errorRes, setErrorRes] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [formValue, setformValue] = useState({
        userName: "",
        captchaText: "",
      });

      //Email validation
      const validEmail = () => {
        const validRegex = "^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$";
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValue.userName)) {
          setErrorEmail("");
        } else if (formValue.userName == "") {
          setErrorEmail("");
        } else {
          setErrorEmail("Enter Email in correct format");
        }
      };
      useEffect(() => {
        validEmail();
      }, [formValue.userName]);
    //onChange for email
      const handleChange = (e) => {
        setformValue({
          ...formValue,
          [e.target.id]: e.target.value,
        });
      };
      const navigatetoSignin = () =>{
        setShowPopup(false)
        history.push("/login")
      }
  
    //For Resetting password
    const onResetPassword = (e) => {
        e.preventDefault();
        setClickOnValidate(true);
       // let Cookie = "JSESSIONID=C9F81C4049E2AF97615704CABBAB34AA"
        if (
          formValue.userName != "" &&
          formValue.captchaText != ""
          //   errorEmail == ""
        ) {
            // console.log(formValue)
          Axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/forgot-password?captchaText=${formValue.captchaText}&email=${formValue.userName}`
            ,{withCredentials: true})
            .then((response) => {
              setApiSuccessMessage(response.data?.message,)
              // console.log(response)
              setShowPopup(true);
              // history.push({
              //   pathname: "/CheckyourEmail",
              //   // state: {
              //   //   userEmail: formValue.userName,
              //   //   responseMsg: response.data?.message,
              //   // },
              // });
            })
            .catch((error) => {
              setErrorRes(error.response?.data?.errorMessage);
              setShowPopup(false);
            });
              
        }
      };
          //For Getting Captcha
          const getCapcha = () => {
            Axios.get(`${process.env.REACT_APP_BASE_URL}/captcha`, {
              responseType: "blob", withCredentials: true
            })
              .then((response) => {
                const urlData = window.URL.createObjectURL(response.data);
                setCaptchaImage(urlData);
              })
              .then((error) => {
                setErrorResponse(error.response?.data?.errorMessage);
              });
          };
          useEffect(() => {
            getCapcha();
          }, []);
    return (
    <Fragment>
    <div className="login-container">  

        <div className="left-panel text-center">
          <div >
          <img className="left-panel-tv-logo" src={logotv} alt="Logo" />
          </div>
          <div className="left-panel-tata">
          <img   src={logotext} alt="Logo" />
          </div>
          <div className="left-panel-shape" >
          <img  src={logobottom} alt="Logo" />
          </div>
        </div>
        {/* <img className="login-logo" src={logo} alt="Logo" />   */}
    
        <div className='right-panel d-flex align-items-center'>
        <ReactCSSTransitionGroup
                component="div"
                className='form-wraper'
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}> 
                        {/* <CardTitle>Forgot Password?</CardTitle>
                        <p>No Worries, We'll Send reset instructions.</p> */}
                  <h1 className='card-title'>
                    Forgot <span>Password ?</span>
                  </h1>
                  {errorResponse}
                  {errorRes && errorRes != "" ? (
                    <div className="mb-0 validationerror">
                      <span className="d-block">{errorRes}</span>
                    </div>
                  ) : (
                    <p className="text-center mt-2">
                      Enter your email and we'll send you a link <br/>to get back into your account.
                    </p>
                  )}
               
                        <Form>
                            <Row form>
                                <Col md={12} className='mt-3' >
                                    <FormGroup className="form-group has-search position-relative">
                                        <Label for="exampleEmail11">Email</Label>
                                        {/* <img src="../Images/atIcon.png" className="form-control-feedback" /> */}
                                        <FontAwesomeIcon icon={faAt} className="form-control-feedback form-at" /> 
                                        <Input 
                                         type="email" 
                                         value={formValue.userName}
                                         name="email" 
                                         id="userName"
                                         placeholder="Enter your email"
                                         onChange={handleChange}/>
                                           <p className="validationerror text-left">
                                           {errorEmail||clickOnValidate && formValue.userName == ""&&"Please Enter Email"}
                                           </p>
                                    </FormGroup>
                                </Col>
                                 <Col md={12}>
                                    <FormGroup>
                                        <Label for="examplePassword11">Captcha Code</Label>
                                        <Input 
                                         type="text"
                                         id="captchaText"
                                         name="captcha"
                                         onChange={handleChange}
                                         value={formValue.captchaText}
                                         inputProps={{ maxLength: 8 }}  
                                         placeholder="Enter Captcha Code"/>
                                        <p className="validationerror text-left">
                                        {clickOnValidate &&
                                        !formValue.captchaText &&
                                        "Please Enter Captcha Code"}
                                        </p>
                                    </FormGroup>
                                </Col>
                                {/* <Label for="examplePassword11"  className='mb-1 ml-3'>Captcha Image<span class="validationerror">*</span></Label> */}
                                <Col md={12}>
                                    <FormGroup className='mb-1'>
                                         <div className="align-items-center d-flex mt-2">
                                         <div><img src={captchaImage} className='captcha-image' /></div>
                                         <div className="ml-3 text-white cursor-pointer" onClick={getCapcha}><MdOutlineRefresh style={{fontSize:25}} title='Refresh Captcha'/>Refresh</div>
                                         </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Link to="/dashboards/home"> 
                            <Button className="Sign-in mt-4" onClick={onResetPassword} >Reset Password Link</Button>
                            </Link>
                           <div className="mt-3 d-flex justify-content-center align-items-baseline mb-2"><span className='align-items-center cursor-pointer d-flex' onClick={() => history.push(`/login`)}><span className='left-arrow-icon mr-1 cursor-pointer' ><ArrowBackIcon/></span><span ><p>Back to Login</p></span></span></div>
                           
                            {/* <Link to="/createNewPassword"><p>crete New password</p></Link> */}
                            {apiSuccessMessage&&<RctModel
                              isOpen={showPopup}
                              footer={{
                              secondaryButton: {
                              action:navigatetoSignin,
                              name: 'Login',
                              addClass:"btn btn-success px-4 login-button"
                              }
                              }}
                              >
                                <span className='d-block'><img src="/Images/succes-icon.png" style={{width:60}} /><p className="mt-2"></p></span><br/>
                             <p>{apiSuccessMessage}</p>
                          </RctModel>}
                        </Form>  
            </ReactCSSTransitionGroup>
            </div>
    </div>
</Fragment>
    )
}
export default ForgotPassword;
