import React, { Fragment } from "react";
import logotext from "../../../src/assets/utils/images/tata-play-middle.png";
import logotv from "../../../src/assets/utils/images/tataplay-tv-image.png";
import logobottom from "../../../src/assets/utils/images/tataplay-bottom.png";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { getAuthActions, resetFailureMessage } from "../../store/actions";
import Axios from "axios";
import "./index.scss"
import { faAt, faKey, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { callAccedoAPI } from "../../axios/accedoApi";
import { callAPI } from "../../axios";

const Login = (props) => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [flag, setFlag] = useState(false);
  const [isLinkDisabled, setIsLinkDisabled] = useState(true);
  const [clickOnValidate, setClickOnValidate] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [usernameTouched, setUserNameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [mfaCodeTouched, setMfaCodeTouched] = useState(false);
  const [mfaResponse, setMfaResponse] = useState('');
  const [mfaErrorResponse, setMfaErrorResponse] = useState('');
  const { loading, jwt, error, authuserlist } = useSelector((state) => state.authReducer)
  const [formValue, setformValue] = useState({
    username: "",
    password: "",
    mfaCode:"",
    // check:false
  });

  const [privileges,setPrivileges] = useState([
    {key:"MENU_DASHBOARD",value:"/dashboards/home"},
    {key:"TOTAL_DEVICES_ACROSS_INDIA",value:"/total-devices-across-india/details"},
    {key:"MENU_SID_CDSN_LEVELS_DETAILS",value:"/SIDCDSNLevels/Details"},
    {key:"MENU_EVENT_CAPTURED",value:"/EventsCaptured/totalEvents-GridView"},
    {key:"MENU_USERS",value:"/UserManagement/userlist"},
    {key:"MENU_USER_CREATE",value:"/UserManagement/create-user"},
    {key:"MENU_ROLES",value:"/UserManagement/roles"},
    {key:"MENU_ROLES_AND_PERMISSIONS",value:"/UserManagement/roles-permissions"},
    {key:"MENU_USER_PRIVILEGES",value:"/UserManagement/user-privileges"},
    {key:"MENU_STB_FAILURE_EVENTS",value:"/Dashboards/SystemErrorsIndiaLevel"},
    {key:"MENU_DEVIATION_PERCENTAGE",value:"/Dashboards/SystemDefinedThreshold"},
    {key:"MENU_BLOCKED_APPLICATIONS",value:"/blocked-applications/details"},
    {key:"MENU_REMOTE_WIZARD",value:"/wizard/wizard-list"},
    {key:"MENU_GLOBAL_CONTROL_EVENT",value:"/global-events/global-events-list"},
  ])

  const validEmail = () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValue.username)) {
      setErrorEmail("");
    } else if (formValue.username === "") {
      setErrorEmail("");
    } else {
      setErrorEmail("Enter Email in correct format");
    }
  };

  const onUserLoged = (e) => {
    e.preventDefault();
    setClickOnValidate(true);
    if (
        formValue.username === "" ||
        formValue.password === "" ||
        errorEmail !=="" ||
        formValue.mfaCode == ""
      ) {
         return
      }
      setMfaResponse("");
      setMfaErrorResponse("");
      props.getAuthActions(formValue);
 }

 useEffect(() => {
    setMfaErrorResponse("");
    setMfaResponse("");
     if (jwt) {
      callAPI(`${process.env.REACT_APP_BASE_URL}/accedo-detail`, "GET").then(
        (response)=>{
          if(JSON.parse(localStorage.getItem("AppLogoResponse"))?.version!=="2023.24.4-1.17.11 PM"){
            callAccedoAPI(`${response.accedoApiUrl}/appLogo`, "GET").then(
              (res) =>localStorage.setItem("AppLogoResponse",JSON.stringify(res))
            ).catch(err =>console.log(err.message));
          }
          if(JSON.parse(localStorage.getItem("AppConfigControlResponse"))?.body?.ConfigData?.version!=="2023.18.5-3.27.06 PM"){
            callAccedoAPI(`${response.accedoApiUrl}/config?type=control`,"GET")
              .then((response) => localStorage.setItem("AppConfigControlResponse",JSON.stringify(response)))
              .catch((error) =>console.log(error));
          }
          if(JSON.parse(localStorage.getItem("AppConfigPeriodicResponse"))?.body?.ConfigData?.version!=="2023.18.5-3.27.06 PM"){
            callAccedoAPI(`${response.accedoApiUrl}/config?type=periodic`,"GET")
              .then((response) => localStorage.setItem("AppConfigPeriodicResponse",JSON.stringify(response)))
              .catch((error) =>console.log(error));
          }
        }
      ).catch(error=>console.log(error))
      let tempPermission=privileges?.filter((privilege)=>authuserlist?.availablePermissions.includes(privilege.key))
      if(tempPermission.length>0){
        history.replace(tempPermission[0].value);
      }else{
        history.replace('/dashboards/home');
      }
     }
     if(error){
        console.log(error)
     }
    }, [jwt, error])

  const onGetMFACode = () => {
    setIsLinkDisabled(false);
    setFlag(true);
    setCounter(30);
    const timeout = setTimeout(() => {
      setIsLinkDisabled(true);
    }, 30000);
    dispatch(resetFailureMessage())
    Axios.post(
       `${process.env.REACT_APP_BASE_URL}/user/mfa`, {username:formValue.username})
       .then((response) => {
        setMfaErrorResponse("");
         setMfaResponse(response.data?.message);
       })
       .catch((error) => {
        setMfaResponse("");
        setIsLinkDisabled(true)
        setMfaErrorResponse(error.response?.data?.errorMessage);
       });

    return () => {
      clearTimeout(timeout);
    };
  };

  useEffect(() => {
    if (flag) {
      if (counter > 0) {
        const timeout = setTimeout(() => {
          setCounter(counter - 1);
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [counter]);

  useEffect(() => {
    validEmail();
  }, [formValue.username]);

  const handleChange = (e) => {
    setformValue({
      ...formValue,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <Fragment>
      <div className="login-container">
        <div className="left-panel text-center">
          <div >
          <img className="left-panel-tv-logo" src={logotv} alt="Logo" />
          </div>
          <div className="left-panel-tata">
          <img   src={logotext} alt="Logo" />
          </div>
          <div className="left-panel-shape" >
          <img  src={logobottom} alt="Logo" />
          </div>
        </div>
        <div className="right-panel d-flex align-items-center">
          <ReactCSSTransitionGroup
            component="div"
            className="form-wraper"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
          >
            <CardTitle>Welcome to STB Diagnostic!</CardTitle>
            <p className="text-center">Please Login to your account.</p>
            {error && <p className="text-center validationerror font-weight-bold">{error}</p>}
            {((mfaResponse && mfaResponse!=='') ||(mfaErrorResponse && mfaErrorResponse !==''))  && <p className={`${(mfaResponse && mfaResponse!=="") ? 'text-success':'validationerror'} text-center`}>{mfaResponse || mfaErrorResponse}</p>}
            <Form autocomplete="off">
              <Row>
                <Col md={12}>
                  <FormGroup className="form-group has-search position-relative">
                  <FontAwesomeIcon icon={faAt} className="form-control-feedback" /> 
                    <Label for="username">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="username"
                      value={formValue.username}
                      placeholder="Enter your email"
                      onChange={handleChange}
                      onBlur={() => setUserNameTouched(true) }
                      autocomplete="somerandomtext"
                    />
                   <p className="validationerror">
                      {errorEmail  == ""
                        ? ((clickOnValidate &&
                          !formValue.username) || (!(formValue.username.trim() !== '') && usernameTouched)) &&
                          "Email required in correct format "
                        : "Required in correct format "}
                    </p>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className="form-group has-search position-relative">
                  {/* <img src="../Images/atIcon.png" className="form-control-feedback" /> */}
                  <FontAwesomeIcon icon={faKey} className="form-control-feedback" /> 
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={formValue.password}
                      placeholder="Enter your password"
                      onChange={handleChange}
                      onBlur={() => setPasswordTouched(true) }
                    />
                    <p className="validationerror">
                      {((clickOnValidate &&
                        !formValue.password )|| (!(formValue.password.trim() !== '') && passwordTouched)) &&
                        "Please Enter Password"}
                    </p>
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className="form-group has-search position-relative">
                  <FontAwesomeIcon icon={faShieldAlt} className="form-control-feedback" /> 
                    <Label for="mfaCode">MFA Code</Label>
                    <Input
                      type="text"
                      name="mfaCode"
                      id="mfaCode"
                      value={formValue.mfaCode}
                      onChange={handleChange}
                      placeholder="Enter MFA Code"
                      onBlur={() => setMfaCodeTouched(true) }
                    />
                    <p className="validationerror">
                      {((clickOnValidate &&
                        !formValue.mfaCode )|| (!(formValue.mfaCode.trim() !== '') && mfaCodeTouched)) &&
                        "Please Enter MFA Code"}
                    </p>
                  </FormGroup>
                </Col>
       
                <Col md={12} className="text-right mt-4">
                  <span
                    role="button"
                    className={`${
                      (formValue.username && isLinkDisabled && errorEmail=="") ? "cursor-pointer active-mfa font-weight-bold" : "actionDisable inactive"
                    } px-4 py-2`}
                    onClick={onGetMFACode}
                  >
                    {isLinkDisabled
                      ? "Send MFA Code"
                      : `Resend MFA Code In ${counter} sec`}
                  </span>
                </Col>
              </Row>
              <Row className="Frg-pwd">
                <Col md={6}>
                  <p ><span className="cursor-pointer" onClick={() => history.push(`/forgotPassword`)}>Trouble logging in?</span></p>
                </Col>
              </Row>
                <Button className="Sign-in" onClick={onUserLoged}>Login</Button>
                {/* <Link className="Sign-in" to="/Dashboards/home">Login</Link> */}
            </Form>
            <div className="text-center my-md-4 my-3">
              <Link to="/signup" className="create-account">If you don't have an account? <b className="create-account-bold">Create</b></Link>
            </div>
          </ReactCSSTransitionGroup>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authuserlist: state.authReducer.authuserlist,
    Jwt_Token: state.authReducer.jwt,
  };
};

const mapDispatcherToProps = (dispatch) => {
  return {
    getAuthActions: (data) => dispatch(getAuthActions(data))
  };
};

export default connect(mapStateToProps, mapDispatcherToProps)(Login);