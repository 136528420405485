import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const AverageBatteryStatusReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_AVERAGE_BATTERY_STATUS_REQUEST: return getAverageBatteryStatusRequest(state, action);
        case ACTION.GET_AVERAGE_BATTERY_STATUS_SUCCESS: return getAverageBatteryStatusSuccess(state, action);
        case ACTION.GET_AVERAGE_BATTERY_STATUS_FAILURE: return getAverageBatteryStatusFailure(state, action);
        
        default: return state;
    }
}

const getAverageBatteryStatusRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getAverageBatteryStatusSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
    });
}
const getAverageBatteryStatusFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default AverageBatteryStatusReducer;