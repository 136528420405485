import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    roleList:[]
}

const RoleReducer = (state = initialState, action) => {
    switch(action.type) {

        case ACTION.GET_ROLES_DETAILS_REQUEST: return getRoleRequest(state, action);
        case ACTION.GET_ROLES_DETAILS_SUCCESS: return getRoleSuccess(state, action);
        case ACTION.GET_ROLES_DETAILS_FAILURE: return getRoleFailure(state, action);
        
        default: return state;
    }
}

const getRoleRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}

const getRoleSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        roleList: action.payload,
    });
}

const getRoleFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default RoleReducer;