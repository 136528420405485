import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const TotalDevicesAcrossIndiaReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_TOTAL_DEVICES_ACROSS_INDIA_REQUEST: return getTotalDevicesAcrossIndiaRequest(state, action);
        case ACTION.GET_TOTAL_DEVICES_ACROSS_INDIA_SUCCESS: return getTotalDevicesAcrossIndiaSuccess(state, action);
        case ACTION.GET_TOTAL_DEVICES_ACROSS_INDIA_FAILURE: return getTotalDevicesAcrossIndiaFailure(state, action);
        
        default: return state;
    }
}

const getTotalDevicesAcrossIndiaRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getTotalDevicesAcrossIndiaSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getTotalDevicesAcrossIndiaFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: [],
        errorMsg: action.payload,
    });
}

export default TotalDevicesAcrossIndiaReducer;