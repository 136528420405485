import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getAndroidSettingsRequest = () => {
    return {
        type: ACTION.GET_ANDROID_SETTINGS_REQUEST
    }
}

const getAndroidSettingsSuccess = (apiData) => {
    return {
        type: ACTION.GET_ANDROID_SETTINGS_SUCCESS,
        payload: apiData,
    }
}

const getAndroidSettingsFailure = (errorMsg) => {
    return {
        type: ACTION.GET_ANDROID_SETTINGS_FAILURE,
        payload: errorMsg,
    }
}

export const androidSettingsFetchNowControlTimerFlag = () => {
    return {
        type: ACTION.ANDROID_SETTINGS_FETCH_NOW_CONTROL_TIMER_FLAG,
        // payload: payload,
    }
}

const androidSettingsFetchNowControl = (payload)=>{
    return {
        type: ACTION.ANDROID_SETTINGS_FETCH_NOW_CONTROL,
        payload: payload,
    }
}

export const androidSettingsFetchNowStatus = (payload) => {
    return {
        type: ACTION.ANDROID_SETTINGS_FETCH_NOW_STATUS,
        payload: payload,
    }
}

export const androidSettingsFetchNowReset = (state, action) => {
    return {
        type: ACTION.ANDROID_SETTINGS_FETCH_NOW_RESET
    }
}

export const androidSettingsProcessedWebhookTriggered = (payload) => {
    return {
        type: ACTION.ANDROID_SETTINGS_PROCESSED_WEBHOOK_TRIGGERED,
        payload: payload,
    }
}

export const getAndroidSettingsData = (sid,cdsn) => {
    const apiPath = `/KPI/STB/androidSettings/${sid}/${cdsn}`;
    return async function(dispatch) {
        dispatch(getAndroidSettingsRequest());
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getAndroidSettingsSuccess(res));
        })
        .catch(err => {
            dispatch(getAndroidSettingsFailure(err.message));
        });;
    };
};

export const postFetchNowAndroidSettingsControl = (sid,cdsn,payload, controlAction) => {
    const controlApiPath = `/control/${cdsn}/${controlAction}`;
    let statusApiPath = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${controlApiPath}`,"POST", payload).then(res => {
            localStorage.setItem(`FetchNowAndroidSettingsSid-${sid}Cdsn-${cdsn}InitiateTime`,new Date().getTime())
            dispatch(androidSettingsFetchNowControl(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${statusApiPath}${res.body.controlRequestID}`,"GET").then(response => {
                // console.log(response[0])
                dispatch(androidSettingsFetchNowStatus(response[0]));
            })
            // setTimeout(()=>{
            //     dispatch(androidSettingsFetchNowControlTimerFlag())
            //   },120000)
        })
        .catch(err => {
            dispatch(getAndroidSettingsFailure(err.message));
        });
    }; 
};

