import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    data:[],
   // calendarData:[]
}

const TotalAppVersionReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_TOTAL_APP_VERSION_REQUEST: return getTotalAppVersionRequest(state, action);
        case ACTION.GET_TOTAL_APP_VERSION_SUCCESS: return getTotalAppVersionSuccess(state, action);
        case ACTION.GET_TOTAL_APP_VERSION_FAILURE: return getTotalAppVersionFailure(state, action);
        
        default: return state;
    }
}

const getTotalAppVersionRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
        errorMsg:"",
    });
}
const getTotalAppVersionSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: action.payload,
        errorMsg:"",
    });
}
const getTotalAppVersionFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        data: [],
        errorMsg: action.payload,
    });
}

export default TotalAppVersionReducer;