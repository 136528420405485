import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    broadcasterlist:[],
    // calendarData:[]
}

const UserReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_BROADCASTERS_DETAILS_REQUEST: return getBroadcasterDetailsRequest(state, action);
        case ACTION.GET_BROADCASTERS_DETAILS_SUCCESS: return getBroadcasterDetailsSuccess(state, action);
        case ACTION.GET_BROADCASTERS_DETAILS_FAILURE: return getBroadcasterDetailsFailure(state, action);
        
        default: return state;
    }
}

const getBroadcasterDetailsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getBroadcasterDetailsSuccess = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        broadcasterlist: action.payload,
    });
}
const getBroadcasterDetailsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default UserReducer;