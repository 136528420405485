import { UpdateObject } from '../../utils/utils';
 
 const initialState = {
     exceptionRedirection:{},
 }
 
 const exceptionRedirectionReducer = (state = initialState, action) => {
     switch(action.type) {
        
         case 'SET_DATA': return getExceptionSuccess(state, action);
         
         default: return state;
     }
 }
 
 
 const getExceptionSuccess = (state, action) => {
     return UpdateObject(state, {
         exceptionRedirection: action.exceptionRedirection,
     });
 }
 
 
	 export default exceptionRedirectionReducer;