import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    loading: false,
    errorMsg: '',
    userList:[],
    // calendarData:[]
}

const UserListReducer = (state = initialState, action) => {
    switch(action.type) {
       
        case ACTION.GET_USERLIST_DETAILS_REQUEST: return getUserListDetailsRequest(state, action);
        case ACTION.GET_USERLIST_DETAILS_SUCCESS: return getUserListDetailsSuccess(state, action);
        case ACTION.GET_USERLIST_DETAILS_FAILURE: return getUserListDetailsFailure(state, action);
        
        default: return state;
    }
}

const getUserListDetailsRequest = (state, action) => {
    return UpdateObject(state, {
        loading: true,
    });
}
const getUserListDetailsSuccess = (state, action) => {
    // console.log(action.payload)
    return UpdateObject(state, {
        loading: false,
        userList: action.payload,
    });
}
const getUserListDetailsFailure = (state, action) => {
    return UpdateObject(state, {
        loading: false,
        errorMsg: action.payload,
    });
}

export default UserListReducer;