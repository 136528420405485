import * as ACTION from '../actions/actionTypes';
 import { UpdateObject } from '../../utils/utils';
 
 const initialState = {
     loading: false,
     errorMsg: '',
     data:[],
 }
 
 const getNoSignalCountGraphReducer = (state = initialState, action) => {
     switch(action.type) {
        
         case ACTION.GET_NO_SIGNAL_COUNT_REQUEST: return getNoSignalCountRequest(state, action);
         case ACTION.GET_NO_SIGNAL_COUNT_SUCCESS: return getNoSignalCountSuccess(state, action);
         case ACTION.GET_NO_SIGNAL_COUNT_FAILURE: return getNoSignalCountFailure(state, action);
         
         default: return state;
     }
 }
 
 const getNoSignalCountRequest = (state, action) => {
     return UpdateObject(state, {
         loading: true,
         errorMsg:"",
     });
 }
 const getNoSignalCountSuccess = (state, action) => {
     return UpdateObject(state, {
         loading: false,
         data: action.payload,
         errorMsg:"",
     });
 }
 const getNoSignalCountFailure = (state, action) => {
     return UpdateObject(state, {
         loading: false,
         data:[],
         errorMsg: action.payload,
     });
 }
 
	 export default getNoSignalCountGraphReducer;