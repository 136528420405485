import * as ACTION from './actionTypes';
import { callAccedoAPI } from "../../axios/accedoApi";
import {callAPI} from "../../axios/index"

const getSatelliteScanRequest = () => {
    return {
        type: ACTION.GET_SATELLITE_SCAN_REQUEST
    }
}

const getSatelliteScanSuccess = (apiData) => {
    return {
        type: ACTION.GET_SATELLITE_SCAN_SUCCESS,
        payload: apiData,
    }
}

const getSatelliteScanFailure = (errorMsg) => {
    return {
        type: ACTION.GET_SATELLITE_SCAN_FAILURE,
        payload: errorMsg,
    }
}

const postSatelliteScanRequest = () => {
    return {
        type: ACTION.POST_SATELLITE_SCAN_REQUEST
    }
}

const postSatelliteScanSuccess = (apiData) => {
    return {
        type: ACTION.POST_SATELLITE_SCAN_SUCCESS,
        payload: apiData,
    }
}

export const postSatelliteScanSuccessControlStatus = (apiData) => {
    return {
        type: ACTION.POST_SATELLITE_SCAN_SUCCESS_CONTROL_STATUS,
        payload: apiData,
    }
}

const postSatelliteScanTimerFlag = (userToken) => {
    return {
        type: ACTION.POST_SATELLITE_SCAN_TIMER_FLAG,
        payload: userToken,
    }
}

const postSatelliteScanFailure = (errorMsg) => {
    return {
        type: ACTION.POST_SATELLITE_SCAN_SAVE_FAILURE,
        payload: errorMsg,
    }
}

export const satelliteScanSaveSettingsReset = () => {
    return {
        type: ACTION.SATELLITE_SCAN_SAVE_SETTINGS_RESET,
    }
}

export const getSatelliteScanStatus = () => {
    const apiPath = `/KPI/satelliteScanData`;
    return async function(dispatch) {
        dispatch(getSatelliteScanRequest());
        // callAPI("/accedo-detail","GET").then(res=>{
        //     return callAPI(`${res.accedoApiUrl}${apiPath}`,"POST",payload)
        //  }).then(response=>dispatch(getAverageBatteryStatusSuccess(response.data)))
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"GET").then(res => {
            dispatch(getSatelliteScanSuccess(res));
        })
        .catch(err => {
            dispatch(getSatelliteScanFailure(err.message));
        });
    }; 
};

export const postSatelliteScanData = (cdsn,sid, postData) => {
    const apiPath = `/control/${cdsn}/satelliteSignalScan`;
    let apiPathStatus = `/control/status/${sid}?by=CRID&id=`
    return async function(dispatch) {
        dispatch(postSatelliteScanRequest());
        // callAPI("/accedo-detail","GET").then(res=>{
        //     return callAPI(`${res.accedoApiUrl}${apiPath}`,"POST",payload)
        //  }).then(response=>dispatch(getAverageBatteryStatusSuccess(response.data)))
        callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPath}`,"POST", postData).then(res => {
            dispatch(postSatelliteScanSuccess(res));
            callAPI("/control","POST",{
                controlRequestID: res.body.controlRequestID,
                action: res.body.action,
                sid: sid,
                cdsn: cdsn,
              })
            setTimeout(()=>{
                dispatch(postSatelliteScanTimerFlag())
              },120000)
            callAccedoAPI(`${localStorage.getItem("accedo_url")}${apiPathStatus}${res?.body?.controlRequestID}`,"GET").then(response => {
                dispatch(postSatelliteScanSuccessControlStatus(response[0]))
            })
        })
        .catch(err => {
            console.log(err.message)
            dispatch(postSatelliteScanFailure(new Date().getTime()));
        });
    }; 
};

