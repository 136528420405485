import * as ACTION from '../actions/actionTypes';
import { UpdateObject } from '../../utils/utils';

const initialState = {
    deviceStorageloading: false,

    errorMsg: '',
    deviceStorageData:[],

    deviceStorageFetchNowControl:[],
    deviceStorageFetchNowControlStatus:[],
    deviceStorageFetchNowTime:null,
    deviceStorageFetchNowTimerFlag:false,

    webhookFlag:false,
}

const DeviceStorageReducer = (state = initialState, action) => {
    switch(action.type) {
        
        case ACTION.GET_DEVICE_STORAGE_REQUEST: return getDeviceStorageRequest(state, action);
        case ACTION.GET_DEVICE_STORAGE_SUCCESS: return getDeviceStorageSuccess(state, action);
        case ACTION.GET_DEVICE_STORAGE_FAILURE: return getDeviceStorageFailure(state, action);

        case ACTION.DEVICE_STORAGE_FETCH_NOW_CONTROL: return deviceStorageFetchNowControl(state, action);
        case ACTION.DEVICE_STORAGE_FETCH_NOW_STATUS: return deviceStorageFetchNowCotrolStatus(state, action);
        case ACTION.DEVICE_STORAGE_FETCH_NOW_CONTROL_TIMER_FLAG: return deviceStorageFetchNowTimerFlag(state, action);
        case ACTION.DEVICE_STORAGE_FETCH_NOW_RESET: return deviceStorageFetchNowReset(state, action);

        case ACTION.DEVICE_STORAGE_PROCESSED_WEBHOOK_TRIGGERED: return deviceStorageProcessedWebhookTriggeredReducer(state, action);
        default: return state;
    }
}

const deviceStorageProcessedWebhookTriggeredReducer = (state, action)=>{
    return UpdateObject(state, {
        webhookFlag:!state.webhookFlag
    });
}

const getDeviceStorageRequest = (state, action) => {
    return UpdateObject(state, {
        deviceStorageloading: true,
    });
}
const getDeviceStorageSuccess = (state, action) => {
    return UpdateObject(state, {
        deviceStorageloading: false,
        deviceStorageData: action.payload,
    });
}
const getDeviceStorageFailure = (state, action) => {
    return UpdateObject(state, {
        deviceStorageloading: false,
        errorMsg: action.payload,
    });
}

const deviceStorageFetchNowReset = (state, action) => {
    return UpdateObject(state, {
        deviceStorageloading: false,
        deviceStorageFetchNowControl:[],
        deviceStorageFetchNowControlStatus:[],
        deviceStorageFetchNowTime:null,
        deviceStorageFetchNowTimerFlag:false,
    });
}

const deviceStorageFetchNowControl = (state, action) => {
    return UpdateObject(state, {
        deviceStorageloading: false,
        deviceStorageFetchNowControl: action.payload,
        // androidSettingsFetchNowTime:new Date().getTime()
    });
}
const deviceStorageFetchNowCotrolStatus = (state, action) => {
    localStorage.setItem(`FetchNowDeviceStorageStatusSid-${action.payload.SID}Cdsn-${action.payload.CDSN}`,action.payload.status)
    return UpdateObject(state, {
        deviceStorageloading: false,
        deviceStorageFetchNowControlStatus: action.payload,
    });
}
const deviceStorageFetchNowTimerFlag = (state, action) => {
    return UpdateObject(state, {
        deviceStorageloading: false,
        deviceStorageFetchNowTimerFlag: !state.deviceStorageFetchNowTimerFlag,
    });
}

export default DeviceStorageReducer;